class AppNotification {
  constructor(){
    this.permissionRequestedStatusList = ['noSupport', 'granted', 'denied']
    this.permissionResetedStatus = 'default';

    this.initStatus = this.notificationStatus();
  }

  getInitStatus(){
    return this.initStatus;
  }

  isPermissionReseted(){
    return this.initStatus === this.permissionResetedStatus;
  }

  canNotify(){
    return this.notificationStatus() === 'granted';
  }

  notificationStatus(){ //returns noSupport, granted, denied, default
    if(!(("Notification" in window)))
      return 'noSupport';
    else 
      return Notification.permission;
  }

  async requestPermission(){
    const currentStatus = this.notificationStatus();

    if(this.permissionRequestedStatusList.some(s => s === currentStatus)) //if permission already requested, no need to ask again
      return currentStatus;
    else {
      const permission = await Notification.requestPermission().then((p) => p);
      return permission;
    }
  }
}

export default AppNotification;