import logo from '@/assets/images/usuario.png'

export default {
    name: 'backButton',

    props: {
        src: {
            type: String,
            default: null,
        },
    },

    methods: {
        encodeImageFileAsURL() {
            var filesSelected = document.getElementById("file-upload").files;
            if (filesSelected.length > 0) {
                var fileToLoad = filesSelected[0];
            
                var fileReader = new FileReader();
                const self = this;

                fileReader.onload = function(fileLoadedEvent) {
                    const srcData = fileLoadedEvent.target.result;
                    self.$emit('onImageChange', srcData);
                }
                
                fileReader.readAsDataURL(fileToLoad);
            }

        }
    },

    computed: {
        imageProfile(){
            return this.src === '' ?  logo : this.src;
        }
    }
}
