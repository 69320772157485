import ImageCard from '@/ui/components/imageCard'
import MainButton from '@/ui/components/mainButton'
import headerStore from '@/store/header'
import placeEvents from '@/store/storePlaceEvents'
import filterSearch from '@/ui/components/filterSearch'
import filterStore from '@/store/filter'
import Bus from '@/plugins/bus'
import placeData from '@/store/place'
import { DateTime } from 'luxon'

export default {
    name: 'placeEvents',

    components: {
        ImageCard,
        MainButton,
        filterSearch,
    },

    data() {
        return {
            loading: false,
            allEventsShow: false,
            datesBetween: false,
            restoreData: false,
            existDateRange: false,
        }
    },
    methods: {
        upper(value) {
            return value.trim().replace(/^\w/, (c) => c.toUpperCase())
        },
        goToEvent(event) {
            this.$router.push({
                name: 'eventPlaceView',
                params: { eventID: event.id, slug: event.slug },
            })
        },
        getData() {
            if (this.$route.params.queryId) return this.$route.params.queryId
            else return this.eventsData[0].place._id
        },
        dateText(date) {
            const customDate = DateTime.fromISO(date, {
                zone: 'utc',
            })
            return this.upper(
                customDate.setLocale('es').toFormat("EEEE dd 'de' LLLL"),
            )
        },
        openFilter() {
            Bus.$emit('searchFilter')
        },
        prepareSort(a, b) {
            var fechaA = DateTime.fromISO(a.date)
            var fechaB = DateTime.fromISO(b.date)
            return fechaA.diff(fechaB).milliseconds
        },
    },
    computed: {
        eventsData() {
            let arrayEvents = placeEvents.state.eventsData
            return arrayEvents.sort(this.prepareSort)
        },
        favoriteEventsData() {
            let arrayEvents = placeEvents.state.favoriteEventsData
            return arrayEvents.sort(this.prepareSort)
        },

        placeName() {
            return this.upper(placeData.state.placeData.name)
        },
        noData() {
            if (this.eventsData === null || this.eventsData.length == 0) {
                return true
            }
            return placeEvents.state.noData
        },
        changueRestoreData() {
            return this.restoreData
        },
    },
    async created() {
        this.loading = true
        if (
            this.$route.params.queryId == undefined &&
            !placeEvents.state.eventsData
        ) {
            if (this.$router.referer) this.$router.go(-1)
            else this.$router.push('/')
        } else if (!placeEvents.state.eventsData) {
            try {
                headerStore.setHeaderText(this.placeName)
                await placeEvents.setFavoriteEventsData(this.getData())
                await placeEvents.setEventsData(this.getData())
            } catch (error) {}
        }
        this.loading = false
        Bus.$on('updateFilter', (data) => {
            if (data.changeDate) this.datesBetween = data.newDateRange
        })
        Bus.$on('removeDateFilter', (data) => {
            if (data.removeDateFilter && this.existDateRange)
                this.restoreData = true
            this.existDateRange = false
        })
    },
    watch: {
        async datesBetween() {
            this.existDateRange = true
            filterStore.state.dateState = this.datesBetween
            this.loading = true
            await placeEvents.setEventsData(this.getData(), this.datesBetween)
            await placeEvents.setFavoriteEventsData(
                this.getData(),
                this.datesBetween,
            )
            this.loading = false
        },
        async changueRestoreData() {
            this.restoreData = false
            try {
                this.loading = true
                await placeEvents.setEventsData(this.getData())
                await placeEvents.setFavoriteEventsData(this.getData())
            } catch (e) {
                console.log(e)
            }
            this.loading = false
        },
    },
}
