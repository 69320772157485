import ImageCard from '@/ui/components/imageCard'
import MainButton from '@/ui/components/mainButton'
import searchBarStore from '@/store/search'
import placeTypes from '@/domain/placeTypes/placesClass'

export default {
    name: 'searchPlaces',

    components: {
        ImageCard,
        MainButton,
    },
    props: {
        arrayNearby: null,
        placeNameView: {
            type: String,
            default: 'Soller',
        },
        title: {
            type: String,
        },

        subtitle: {
            type: String,
        },
        searchEvents: null,
    },
    data() {
        return {
            indexNearbyObj: 0,
        }
    },
    mounted() {
        if (!searchBarStore.state.notResults) this.createNearbyObj()
    },

    methods: {
        showHide(id) {
            for (let i in this.NearbyObj) {
                if (this.NearbyObj[i].id == id) {
                    this.NearbyObj[i].show = !this.NearbyObj[i].show
                    this.indexNearbyObj = i
                }
            }
            this.$forceUpdate()
        },
        goToNearby(nearbyPlace, isCategory = false) {
            let place = nearbyPlace.type.name.toLowerCase()
            if (place.includes('event')) {
                place = place === 'event' ? 'eventView' : 'eventPlaceView'
                this.$router.push({
                    name: place,
                    params: {
                        eventID: nearbyPlace.id,
                        slug: nearbyPlace.slug,
                        id: nearbyPlace.stablishment
                            ? nearbyPlace.stablishment.slug
                            : nearbyPlace.place.slug,
                    },
                })
            } else {
                place = nearbyPlace.type.view.toLowerCase()
                this.$router.push({
                    name: place,
                    params: { id: nearbyPlace.slug },
                })
            }

            if (isCategory && !this.indexNearbyObj == 0)
                this.NearbyObj[this.indexNearbyObj].show = false
        },
        createNearbyObj() {
            const nearbyPlaces = {}
            let id = 0
            let catName = ''
            try {
                this.nearby.forEach(function (place) {
                    place.categories.forEach(function (category) {
                        catName = category.name.toLowerCase()
                        if (!Object.keys(nearbyPlaces).includes(catName)) {
                            nearbyPlaces[catName] = []
                            nearbyPlaces[catName]['show'] = false
                            nearbyPlaces[catName]['id'] = id
                            nearbyPlaces[catName]['image'] = category.image
                            nearbyPlaces[catName]['haveChilds'] = true
                        }
                        nearbyPlaces[catName].push(place)
                        id = id + 1
                    })
                })
                searchBarStore.state.categories = nearbyPlaces
                searchBarStore.state.filterCateogries = nearbyPlaces
            } catch (error) {
                console.log(error)
            }
        },
    },

    filters: {
        upper: function (value) {
            return value.trim().replace(/^\w/, (c) => c.toUpperCase())
        },
    },

    computed: {
        numberSites() {
            return function (category) {
                return category.length.toString()
            }
        },
        nearby() {
            return this.arrayNearby
        },
        events() {
            return this.searchEvents.events
        },
        NearbyObj() {
            return searchBarStore.state.filterCateogries
        },
    },
    watch: {
        nearby() {
            this.createNearbyObj()
        },
    },
}
