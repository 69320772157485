import { render, staticRenderFns } from "./establishment.vue?vue&type=template&id=3424c501&scoped=true"
import script from "./establishment.js?vue&type=script&lang=js&external"
export * from "./establishment.js?vue&type=script&lang=js&external"
import style0 from "./establishment.scss?vue&type=style&index=0&id=3424c501&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3424c501",
  null
  
)

export default component.exports