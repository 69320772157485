import SearchHeader from './searchHead'
import ClientHeader from './clientHead'

export default {
    components: {
        SearchHeader,
        ClientHeader
    },

    data() {
        return {
            isMobile: false,
        }
    },

    created() {
        this.isMobile = this.$vuetify.breakpoint.mobile
    },

    computed: {
        metaInfo() {
            const meta = this.$route.meta || null;
            if(meta){
                return meta.header === undefined || JSON.stringify(meta.header) === '{}' ? null : meta.header
            }
            return null
        },

        hideHeader(){
            return this.metaInfo?.hideNavbar === true;
        },

        componentName(){
            if(this.metaInfo?.componentName){
                return this.metaInfo.componentName;
            } else {
                return 'ClientHeader'
            }
        }
    }
}
