class UserRepository {
    client = ''
    authAPIURL = 'http://localhost:8080/v1'
    async GetUserLogin(username, password) {
        const client = await Request.get(
            `${this.authAPIURL}/users/${username}:${password}`,
            {},
        )
        if (client.auth) {
            return client
        }
        return false
    }
    async GetUser(email) {
        const client = await Request.get(
            `${this.authAPIURL}/users/email/${email}`,
            {},
        )
        if (client.auth) {
            return client
        }
        return false
    }
}
const userRepository = new UserRepository()
export default userRepository
