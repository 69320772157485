import ImageCard from '@/ui/components/imageCard';
import MainButton from  '@/ui/components/mainButton';
import headerStore from '@/store/header';

export default {
    name: 'TourReservas',

    components: {
        ImageCard,
        MainButton,
    },

    data(){
        return {
            ReservasData: {
                name: 'Servicios y Reservas',
                services: [
                    {
                        name: 'EXCURSIONES',
                        image: 'https://picsum.photos/id/43/1280/831.jpg?hmac=glK-rQ0ppFClW-lvjk9FqEWKog07XkOxJf6Xg_cU9LI',
                    },
                    {
                        name: 'TOUR MARÍTIMOS',
                        image:'https://picsum.photos/id/158/4836/3224.jpg?hmac=Gu_3j3HxZgR74iw1sV0wcwlnSZSeCi7zDWLcjblOp_c',
                    },
                    {
                        name: 'PARQUES TEMÁTICOS',
                        image:'https://picsum.photos/id/351/3994/2443',
                    },
                    {
                        name: 'OCIO NOCTURNO',
                        image: 'https://picsum.photos/id/395/4080/2720',
                    },
                    {
                        name: 'EXPERIENCIAS GASTRONÓMICAS',
                        image:'https://picsum.photos/id/536/1693/1129.jpg?hmac=UlGblRz_GmkVZnWmy_O1DS5rWEouMvVrazIgLnzKbAA',
                    }
                ],

        
            },
        }
    },

    created(){
        headerStore.setHeaderText(this.ReservasData.name)
    }
}
