import MainButton from '@/ui/components/mainButton'
import Carousel from '@/ui/components/carousel'
import MapLeaflet from '@/ui/components/mapLeaflet'
import CategoryList from '@/ui/components/categoryList'
import placeData from '@/store/place'
import placeEvents from '@/store/storePlaceEvents'

export default {
    name: 'eventPlaceView',

    components: {
        MainButton,
        Carousel,
        MapLeaflet,
        CategoryList,
    },

    data() {
        return {
            loading: false,
            descriptionLength: 250,
            showFullDescription: false,
        }
    },
    async created() {
        try {
            this.loading = true
            if (!placeData.state.placeData)
                await placeData.setPlaceData(this.$route.params.id, true)
            await placeEvents.setEvenViewData(this.getId())
        } catch (error) {}
        this.loading = false
    },

    methods: {
        getId() {
            return this.$route.params.slug
        },
        fullText() {
            this.showFullDescription = !this.showFullDescription
        },
    },
    filters: {
        upper: function (value) {
            return value.trim().replace(/^\w/, (c) => c.toUpperCase())
        },
    },

    computed: {
        eventInfo() {
            return placeEvents.state.eventViewData
        },
        placeName() {
            return placeData.state.placeData.name
        },

        eventDescription() {
            if (!this.descriptionExceeds) return this.eventInfo.description

            if (this.showFullDescription) return this.eventInfo.description
            else
                return (
                    this.eventInfo.description.slice(
                        0,
                        this.descriptionLength,
                    ) + '...'
                )
        },

        descriptionExceeds() {
            return this.eventInfo.description.length > this.descriptionLength
        },

        descriptionButtonText() {
            return this.showFullDescription ? 'VER MENOS' : 'VER MÁS'
        },
        coordinates() {
            return new Array(this.eventInfo.place.lat, this.eventInfo.place.lon)
        },
        googleCoordinates() {
            return `https://www.google.com/maps/search/${this.eventInfo.place.lat},${this.eventInfo.place.lon}`
        },
    },
}
