import api from '@/services/Api'

class EventsPlaceData {
    async getEventViewData(idEvent) {
        try {
            const info = await api.get(`/place-events/slug/${idEvent}`)
            return info.data.data
        } catch (error) {
            if (error.response.status == '404') return false
            return null
        }
    }
    async getEventsData(id, datesBetween, favorite) {
        let info
        let url = `/place-events/place/${id}?active=true`

        try {
            if (datesBetween) {
                const range = `&startDate=${datesBetween[0]}&endDate=${datesBetween[1]}`
                url += range
            }
            if (favorite) {
                const favorite = `&featured=true`
                url += favorite
                info = await api.get(url)
                return info.data.data
            } else {
                info = await api.get(url)
                return info.data.data
            }
        } catch (error) {
            if (error.response.data.statusCode == '404') return false
            return null
        }
    }
}
const eventsPlaceData = new EventsPlaceData()
export default eventsPlaceData
