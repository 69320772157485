import axios from 'axios'
import router from '@/ui/router';
import sessionStore from '@/store/session';
import { keycloak } from '@/plugins/keycloak';

const url = process.env.VUE_APP_API_URL

const api = axios.create({
    baseURL: `${url}`,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(async (config) => {
    await keycloak.updateTokenIfExpired();
    const token = sessionStorage.getItem('vue-token')

    if(token){
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${ token }`
        }
    }
    return config;

    }, (error) => {
      return Promise.reject(error);
    }
);

api.interceptors.response.use((response) => {
        return response;
    }, async (error) => {
        const status = error.response ? error.response.status : null;

        if(status === 401 ) { 
            await sessionStore.setNewSession();
            const token = sessionStorage.getItem('vue-token')

            error.config.headers['Authorization'] = `Bearer ${ token }`;
            error.config.baseURL = undefined;
            return Axios.request(error.config);
        } 
        else if(status === 403 ) { 
            router.push({ name: 'login' });
            return;
        } else {
            return Promise.reject(error);
        }

    }
);

export default api;
