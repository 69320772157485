import Api from '@/services/Api'
import moment from 'moment/moment'

class PlanService {
    async getPlans() {
        const { error, data } = response;
        if(!error){
            const sortedData = this.sortData(data.schedule);
            return this.groupData(sortedData);
        } else {
            throw error;
        }
    }

    sortData(data){
        return data.sort((prev, next) => {
            const keyA = new Date(prev.date);
            const keyB = new Date(next.date);
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
        })
    }

    groupData(data) {
        const planDictionary = data.reduce((planDictionary, item) => {
            const date = moment.parseZone(item.date).format('MM/DD/YYYY');

            if (!planDictionary[date]) 
                planDictionary[date] = [];

            item.planHour = moment.parseZone(item.date).format('hh:mm A')
            planDictionary[date].push(item);
            return planDictionary;
          }, {});
          

          const grouppedData = Object.keys(planDictionary).map((key) => {
            return {
              date: moment().calendar(key, {
                    sameDay: '[Hoy]',
                    nextDay: '[Mañana]',
                    lastDay: '[Ayer]',
                    nextWeek: 'DD/MM/YYYY',
                    lastWeek: 'DD/MM/YYYY',
                    sameElse: 'DD/MM/YYYY'
                }),
              items: planDictionary[key]
            };
        });
        
        return grouppedData
    }
}

const planService = new PlanService()
export default planService

const response = {
    data:{
        schedule: [
        {
            title: 'OkRentaCar - SEAT Leon',
            type: 'SEAT Leon',
            date: '2022-11-03T15:30:00.36-07:00',
            id: 122312312,
            price: 10.02
        },
        {
            title: 'Masaje Tailandes',
            type: null,
            date: '2022-11-03T10:45:00.36-07:00',
            id: 122312312,
            price: 10.02
        },
        {
            title: 'Masaje Chino',
            type: null,
            date: '2022-11-09T15:30:00.36-07:00',
            id: 122312312,
            price: 10.02
        },
        {
            title: 'Masaje irlandes',
            type: null,
            date: '2022-11-04T15:30:00.36-07:00',
            id: 122312312,
            price: 10.02
        },
      
        ]
    },
    error: null
}



