import headerStore from '@/store/header'
import paymentStore from '@/store/payments'
import purchase from '@/store/purchase'
import paymentError from '@/ui/components/paymentError'

export default {
  components: { paymentError },

  data() {
    return {
      purchase: {
        amount: 0,
        type: '',
        items: [],
        options: {},
        idCard: '',
        customer: null,
      },

      paymentDOM: null, //stripe ui needed to destroy the ui when route changes
      elements: null, //stripe elements
      isLoading: true, // loading stripe ui
      
      payWithNewCard: true, //user can pay with a new or registered card

      paymentProcessing: false,
      paymentError: false,
      paymentStateMessage: '',
    }
  },

  async mounted() {
    this.setRouteParams(); // if a param left it goes back or home 
  
    if(this.purchase.idCard.length <= 0) { // validating payment chose, idCard cames when user choose a registererd card
      headerStore.setHeaderText('Agrega tu método de pago')
      this.mountStripe();
    } else {
      headerStore.setHeaderText('Procesando pago');

      this.payWithNewCard = false; 
      this.payWithRegisteredCard();
    }
  },
  
  methods: {
    setRouteParams(){ //validates data to continue with paymennt
      const cardId = this.$route?.params?.idCard;
    
      if (cardId === undefined) { //if undefined user trying to acccess direct to pay
        if (this.$router.referer) this.$router.go(-1)
        else this.$router.push('/');
      }

      this.purchase.type =this.$route.params.type;
      this.purchase.idCard = this.$route.params.idCard || '';
      this.purchase.customer = this.$route.params.customer?.providerId,
      this.purchase.amount = this.$route.params.amount;
      this.purchase.items = this.$route.params.referenceObj;
      this.purchase.options =this.$route.params.options || {};
    },

    mountStripe(){ //mounts stripe payment form
      try {
        setTimeout(() => {
          document.querySelector('#payment-form').addEventListener('submit', this.handlePayAction)
        }, 1000)
  
        this.elements = this.$stripe.elements({
          mode: 'payment',
          paymentMethodCreation: 'manual',
          captureMethod: 'manual',
          currency: 'eur',
          amount: this.purchase.amount * 100,
        });
  
        this.paymentDOM = this.elements.create('card')
        this.paymentDOM.mount('#payment-element')
      } catch (error) {
        this.paymentError = true;
      }
        
      this.isLoading = false;
    },

    async handlePayAction(e){ //when uers is paying with a new card
      e.preventDefault()
      this.paymentProcessing = true;

      try {
        const payMethod = await this.createPaymentMethod();

        if(payMethod){ // payMethod is null when an error happens in createPaymentMethod
          const intent = await paymentStore.initCardPaymentItent(
            this.purchase.customer,
            this.purchase.amount,
            payMethod.id,
            true,
            false
          ) 

          await this.completeOrder(intent);
        }
      } catch (error) {
        this.paymentError = true;
      }
  
      this.paymentProcessing = false;
    },

    async payWithRegisteredCard(){ //when user paying with an existent card
      this.isLoading = true;

      try {
        const intent = await paymentStore.initCardPaymentItent(
          this.purchase.customer,
          this.purchase.amount,
          this.purchase.idCard,
          true,
          false
        );

        await this.completeOrder(intent);
      } catch (error) {
        this.paymentError = true;
      }
      this.isLoading = false;
    },

    async createPaymentMethod(){
      const submitError = await this.elements.submit();
      
      if (submitError.error) {
        return null;
      }

      const { error, paymentMethod } = await this.$stripe.createPaymentMethod({ elements: this.elements })

      if (error?.type === 'card_error' || error?.type === 'validation_error') {
        this.paymentStateMessage = error.message
        return null;
      } else if(error) {
        this.paymentStateMessage = 'Ocurrió un error inesperado. Intenta de nuevo'
        return null;
      }

      return paymentMethod;
    },

    async confirmRequiresAction(intentId, clientSecret){ //return status or error
      const { error } = await this.$stripe.handleCardAction(clientSecret);

      if(error){
        return { error: true, message: 'No se pudo autenticar tu tarjeta. Intenta de nuevo por favor.'}
      }

      try {
        const { status, error, message } = await paymentStore.confirmAction(intentId);
        if(status === 'requires_capture')
          return { error: false, status: status };
        else if(error)
          return { error: true, message: message };
        else 
          return { error: true, message: 'No pudimos hacer el cargo a tu tarjeta' };
      } catch (error) {
        throw error;
      }
    },

    async createInvoice(intentData){ //return invoice
      const invoice = await paymentStore.paymentInvoice(
        intentData.payment.amount,
        this.purchase.type,
        intentData.payment.id,
        this.purchase.items,
        this.purchase.options,
        true
      )

      await this.createTickets(invoice);
      return invoice;
          
    },

    async createTickets(invoice){
      const references = invoice.reference;
      const invoiceId = invoice.id;

      const allReferences = references.map(reference => { // refrences are grouped by qty, this function ungroups them
        return reference.qty === 1 ? reference : Array.from( { length: reference.qty } , () => ({ ...reference, qty: 1 })) 
      }).flat();

      const referencesPromises = allReferences.map(reference => purchase.makeQr(reference.id, reference.serviceId, invoiceId));
      await Promise.all(referencesPromises);
    },

    async completeOrder(intent){
      if(intent.payment?.status === "requires_action" ){
        const { error, message } = await this.confirmRequiresAction(intent.payment.id, intent.payment.secret);
        if(error) {
          this.paymentStateMessage = message || 'Ocurrió un error';
          this.paymentProcessing = false;
          return;
        }
      }        

      const { paymentIntent } = await this.$stripe.retrievePaymentIntent(intent.payment.secret)

      if(paymentIntent.status === 'requires_capture') {
        const invoice = await this.createInvoice(intent);
        this.goToOrderConfirmation(invoice.id);
      } else {
        this.paymentStateMessage = 'No pudimos hacer el cobro';
      }
    },

    goToOrderConfirmation(invoiceID){
      this.$router.push({name: 'ScheduleTicket', query: { invoiceID }})
    }
  },

  beforeDestroy() {
    if(this.paymentDOM)
      this.paymentDOM.destroy()
  },
}
