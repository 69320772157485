import CreateTicket from './ticket/ticket.vue'

export default {
    components: { CreateTicket },
    data() {
        return {
            categorias: ['Ocio', 'Hoteles', 'Restaurantes', 'Night Clibs'],
            Name: '',
            nameRules: [(v) => !!v || 'Se requiere el nombre'],
            establisments: [],
            isMobile: false,
        }
    },

    created() {
        this.isMobile = this.$vuetify.breakpoint.mobile
    },
}
