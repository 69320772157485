import MainButton from '@/ui/components/mainButton'
import Carousel from '@/ui/components/carousel'
import MapLeaflet from '@/ui/components/mapLeaflet'
import FoodMenu from '@/ui/components/foodMenu'
import restaurantStore from '@/store/establishments'
import stablishmentHour from '@/ui/components/stablishmentHour'
import RestaurantCalendar  from '@/ui/components/restaurantCalendar'
import CalendarDaySelector from '@/ui/components/calendarDaySelector'

import { DateTime } from 'luxon'

export default {
    name: 'Restaurant',

    components: {
        MainButton,
        Carousel,
        MapLeaflet,
        FoodMenu,
        stablishmentHour,
        RestaurantCalendar,
        CalendarDaySelector
    },

    data() {
        return {
            loading: false,
            descriptionLength: 250,
            showFullDescription: false,
            active: false,
            diners: 1,
            openDays: new Array(),
            dates: new Array(),
           /*  hours: new Array(), */
            price: 4,
            selectedHour: false,
            selectedDay: false,
            hourToBook: false,
            InitialHour: 11,
            dateDay: false,

            appointment: null,

            showFullCalendar: false,
        }
    },
    async created() {
        this.loading = true
        try {
            await restaurantStore.setEstablishmentData(this.getId())
            await restaurantStore.setMenuData(
                restaurantStore.state.establishmentData._id,
            )
            
            this.appointment = await restaurantStore.getEstablishmentAppointment(restaurantStore.state.establishmentData._id);

            this.getOpenDays()
            this.getDays()

          /*   this.getHours() */
        } catch (error) {}
        this.loading = false
    },

    methods: {
        goToBook() {
            this.$router.push({
                name: 'bookRestaurant',
                params: {
                    id: this.getId(),
                    restName: this.restaurantInfo.name,
                    diners: this.diners,
                    price: this.price,
                    hourToBook: this.hourToBook,
                    dateDay: this.dateDay,
                    appointment: this.simpleAppointment,
                },
            })
        },
        amount(op) {
            if (op == '-') this.diners -= 1
            else if (op == '+') this.diners += 1
        },

        getId() {
            return this.$route.params.id
        },
        getDays() {
            let arrayDay = DateTime.now()
            let obj = {}
            for (let i = 0; i <= 6; i++) {
                if (arrayDay.day == DateTime.now().day) {
                    obj.showDay = 'HOY'
                    obj.showInfo = false
                } else if (
                    arrayDay.day == DateTime.now().plus({ days: 1 }).day
                ) {
                    obj.showDay = 'MAÑANA'
                    obj.showInfo = false
                } else {
                    obj.showDay = arrayDay.weekdayLong.toUpperCase()
                    obj.showInfo = true
                }
                obj.weekday = arrayDay.weekday
                obj.dateNumberDay = arrayDay.day
                obj.month = arrayDay.monthShort
                obj.monthLong = arrayDay.monthLong
                obj.monthNumber = arrayDay.month
                obj.year = arrayDay.year
                for (let j = 0; j < this.openDays.length; j++)
                {
                    if(this.openDays[j].value == obj.weekday ){
                        obj.scheduleHours = [this.openDays[j].part1]
                        if(this.openDays[j].part2.start.length > 0){
                            obj.scheduleHours.push(this.openDays[j].part2)
                        }
                        this.dates.push(obj)
                    }
                }
                obj = {}
                arrayDay = arrayDay.plus({ days: 1 })
            }
           
        },
        getOpenDays(){
            const open = []
           this.restaurantInfo.options.schedule.forEach(function(day) {
                if(day.active){
                    open.push(day)
                }
              });
      
            this.openDays = open  

        },
        /* getHours() {
            if (this.InitialHour < 8) this.InitialHour = 11
            let finalHour = 23
            for (let hour = finalHour; hour >= this.InitialHour; hour -= 3) {
                const interval = Interval.fromDateTimes(
                    { hours: hour - 3 },
                    { hours: hour },
                )
                this.hours.unshift(interval)
            }
        }, */
        selectHour(active, item) {
            this.hourToBook = item
            this.selectedHour = !active
            if (item['e'].hour < 12) this.price = 4
            else if (item['e'].hour < 18 && item['e'].hour > 11) this.price = 5
            else if (item['e'].hour > 18) this.price = 6.5
        },
        selectDay(active, item) {
            console.log(item);
            this.hours = []
            if (item.dateNumberDay != DateTime.now().day) this.InitialHour = 11
            else this.InitialHour = DateTime.now().hour
            this.selectedDay = !active
            this.dateDay = item
            this.showFullCalendar = false;

           /*  this.getHours() */
        },

        toggleCalendar(active) {
            this.selectedDay = false;
            this.showFullCalendar = !active;
        },
      
        setDay(date){
            const scheduleHours = this.getRestaurantHours(date.weekday);
            const fullDate = { ...date, scheduleHours }
            this.selectedDay = true;
            console.log(date);
            this.dateDay = fullDate;
        },

        unsetDay(){
            this.dateDay = null;
            this.selectedDay = false;
        },

        fullText() {
            this.showFullDescription = !this.showFullDescription
        },

        getRestaurantHours(weekDay) {
            const schedule = this.restaurantInfo?.options?.schedule || null
            const scheduleDayArray = [];

            if(!schedule)
                return null;

            const scheduleDay = schedule.find(s => s.value == weekDay);
            if(scheduleDay){
                if(scheduleDay.part1.start)
                    scheduleDayArray.push(scheduleDay.part1)
                if(scheduleDay.part2.start)
                    scheduleDayArray.push(scheduleDay.part2)
            }
            
            return scheduleDayArray;
        }
    },
    filters: {
        upper: function (value) {
            return value.trim().replace(/^\w/, (c) => c.toUpperCase())
        },
    },

    computed: {
        restaurantInfo() {
            return restaurantStore.state.establishmentData
        },
        restaurantDescription() {
            if (!this.descriptionExceeds) return this.restaurantInfo.description

            if (this.showFullDescription) return this.restaurantInfo.description
            else
                return (
                    this.restaurantInfo.description.slice(
                        0,
                        this.descriptionLength,
                    ) + '...'
                )
        },

        descriptionExceeds() {
            return (
                this.restaurantInfo.description.length > this.descriptionLength
            )
        },

        descriptionButtonText() {
            return this.showFullDescription ? 'VER MENOS' : 'VER MÁS'
        },
        coordinates() {
            return new Array(this.restaurantInfo.lat, this.restaurantInfo.lon)
        },
        googleCoordinates() {
            return `https://www.google.com/maps/search/${this.restaurantInfo.lat},${this.restaurantInfo.lon}`
        },
        menuData() {
            return restaurantStore.state.menuData
        },
        establishmentName() {
            return this.restaurantInfo.name
        },

        simpleAppointment(){
            if(this.appointment === null)
                return null;

            return { id: this.appointment.id, active: this.appointment.active, price: this.appointment.price };
        },

        openDaysArray(){
            const days = [];

            if(this.restaurantInfo?.options?.schedule){
                this.restaurantInfo.options.schedule.forEach(day => {
                    if(day.active){
                        days.push(day.value);
                    }
                })
            }

            return days;
        }
    },
}
