import Vue from 'vue'
import cardService from '@/services/creditCardService'

const state = Vue.observable({
    cardsData: false,
    resposeCreated: '',
    resposeDeleted: '',
    noData: true,
})

export default {
    get state() {
        return state
    },

    async setCards() {
        state.cardsData = false
        const response = await cardService.getCards()
        if (response.length == 0) {
            state.noData = true
        } else {
            state.noData = false
            state.cardsData = response
        }
    },
    async newCard(data, userID) {
        const response = await cardService.createCard(data, userID)
        state.resposeCreated = response
    },
    async deleteCard(data) {
        const response = await cardService.deleteCard(data)
        state.resposeDeleted = response
    },
}
