export default {
  data() {
      return {
        notifications: [{
          id: '65165',
          title:'Grover Balear',
          message: 'Impuesto sobre estancia Turistica',
          date: '12:27',            
          number:'1'
        },
        {
          id: '2',
          title:'Excursión Torrent de Pareis',
          message: 'El bus de las 10:00 se retrasa...',
          date: '12:27',  
          number:'ayer'
        },
        {
          id: '3',
          title:'Tour Náutico, calvià Sport Club',
          message: 'Recuerde traer agua y crema solar..',
          date: '12:27',                        
          number:'11/07/22'
        },
        {
          id: '4',
          title:'Grover Balear',
          message: 'Impuesto sobre estancia Turistica',
          date: '12:27',            
          number:'1'
        },
        {
          id: '5',
          title:'Excursión Torrent de Pareis',
          message: 'El bus de las 10:00 se retrasa...',
          date: '7:12',            
          number:'Ayer'
        },
        {
          id: '7',
          title:'Tour Náutico, calvià Sport Club',
          message: 'Recuerde traer agua y crema solar..',
          date: '7:12',            
          number:'11/7/22'
        },
        {
          id: '8',
          title:'Grover Balear',
          message: 'Impuesto sobre estancia Turistica',
          date: '12:27',            
          number:'1'
        },
        {
          id: '9',
          title:'Excursión Torrent de Pareis',
          message: 'El bus de las 10:00 se retrasa...',
          date: '7:12',            
          number:'Ayer'
        },
        {
          id: '10',
          title:'Tour Náutico, calvià Sport Club',
          message: 'Recuerde traer agua y crema solar..',
          date: '7:12',            
          number:'11/7/22'
        },
        {
          id: '11',
          title:'Grover Balear',
          message: 'Impuesto sobre estancia Turistica',
          date: '12:27',            
          number:'1'
        },
        {
          id: '12',
          title:'Excursión Torrent de Pareis',
          message: 'El bus de las 10:00 se retrasa...',
          date: '7:12',            
          number:'Ayer'
        },
        {
          id: '13',
          title:'Tour Náutico, calvià Sport Club',
          message: 'Recuerde traer agua y crema solar..',
          date: '7:12',            
          number:'11/7/22'
        },
        {
          id: '14',
          title:'Grover Balear',
          message: 'Impuesto sobre estancia Turistica',
          date: '12:27',            
          number:'1'
        },
        {
          id: '15',
          title:'Excursión Torrent de Pareis',
          message: 'El bus de las 10:00 se retrasa...',
          date: '7:12',            
          number:'Ayer'
        },
        {
          id: '16',
          title:'Tour Náutico, calvià Sport Club',
          message: 'Recuerde traer agua y crema solar..',
          date: '7:12',            
          number:'11/7/22'
        },
        {
          id: '17',
          title:'Grover Balear',
          message: 'Impuesto sobre estancia Turistica',
          date: '12:27',            
          number:'1'
        },
        {
          id: '18',
          title:'Excursión Torrent de Pareis',
          message: 'El bus de las 10:00 se retrasa...',
          date: '7:12',            
          number:'Ayer'
        },
        {
          id: '19',
          title:'Tour Náutico, calvià Sport Club',
          message: 'Recuerde traer agua y crema solar..',
          date: '7:12',            
          number:'11/7/22'
        },  
        ],
      }
  },
  methods: {
     
  },

  created() {
      
  },
}
