import Vue from 'vue'
import paymentService from '@/services/paymentService'

const state = Vue.observable({
})

export default {
    get state() {
        return state
    },

    async initCardPaymentItent(customer, amount, idCard, confirm = true, adquireOnly = false) {
        return await paymentService.initCardPaymentItentService(customer, amount, idCard, confirm, adquireOnly)
    },

    async initPaymentIntentForApointments(customer, amount, paymentMethodId) {
        return await paymentService.initPaymentIntentForApointments(customer, amount, paymentMethodId)
    },

    async paymentInvoice(amount, type, idPaymentIntent, referenceObj, options, noModifyReference = false) {
       return await paymentService.paymentInvoiceService(amount, type, idPaymentIntent, referenceObj, options, noModifyReference)
    },

    async getInvoice(invoiceId){
        return await paymentService.getInvoice(invoiceId);
    },

    async getIntent(intentId){
        return await paymentService.getIntent(intentId);
    },

    async updateInvoiceStatus(invoiceId, status){
        return await paymentService.updateInvoiceStatus(invoiceId, status);
    },

    async confirmAction(intentId) {
        return await paymentService.confirmAction(intentId)
    }
}
