export default {
    data() {
        return {
            // testClass: 'red--text',
            name: 'TodoItem',
            paymentmethods: [
                {
                    typeOfCard: 'fab fa-cc-visa',
                    number: 'xxxxxx4561',
                },
                {
                    typeOfCard: 'fab fa-cc-mastercard',
                    number: 'xxxxxx4563',
                },
                {
                    typeOfCard: 'fab fa-cc-visa',
                    number: 'xxxxxx4565',
                },
            ],
            isMobile: false,
        }
    },
    created() {
        this.isMobile = this.$vuetify.breakpoint.mobile
    },
}
