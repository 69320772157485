


export default {
    

    components: {
       
    },
    data: () => ({
        show: false,

        isMobile: false,

        dialog: false,       
      
    }),
    created() {
        this.isMobile = this.$vuetify.breakpoint.mobile
    },
}
