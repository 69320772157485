import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '../lang/locals/en';
import es from '../lang/locals/es';

Vue.use(VueI18n);

const messages = { en, es }

export function getLanguage() {
  const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  const locales = Object.keys(messages);

  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return 'en'
}

export const i18n = new VueI18n({
  locale: getLanguage(),
  fallbackLocale: 'en',
  silentFallbackWarn: true,
  messages,
})
