import userStore from '@/store/user'
import FormProfile from './form-profile/form-profile.vue'
import ProfileImage from '../../components/ProfileImage'

export default {
    components: {
        FormProfile,
        ProfileImage,
    },

    data() {
        return {
            user: {
                name: '',
                surname: '',
                email: '',
                phone: '',
                tax: '',
                city: '',
                region: '',
                address: '',
                acceptedTerms: false,
                ipTerms: false,
                dateTerms: false,
                privacyTerms: false,
                ipPrivacyTerm: false,
                picture: '',
                birthday: '',
                genre: '',
                datePrivacy: false,
            },
            updateUserImage: '',
            valid: false,
            userUpdatedMessage: false,

            hideNonEditValues: false,
            dialogText: '',
            sessionUser: false,
            key:0,
            errorInForms: false,
        }
    },
    async mounted() {
        //await this.$keycloak.loadUserProfile()
        await this.$keycloak.loadUserProfile()
        await userStore.setUser(this.$keycloak.profile.id)
    },
    computed: {
        userComputed() {
            this.key++
            return userStore.getUser() ? {...userStore.getUser()} : this.user
        },
        userImage(){
            if(this.updateUserImage.length > 0){
                return this.updateUserImage
            } else {
                return this.userComputed.picture
            }
        }
    },
    methods: {
        validate() {
            this.$refs.formSave.saveForm()
        },

        async saveUser(data) {
            const acceptedTerms = this.validateTerms(data);

            if(!this.errorInForms){
                if(acceptedTerms){
                    try {
                        data.userId = this.$keycloak.profile.id
                        await userStore.saveUser({ ...data, picture: this.userImage })
                        this.userUpdatedMessage = true
                        this.dialogText = 'Tus datos se han actualizado correctamente'
                    } catch (error) {
                        console.log(error);
                        this.userUpdatedMessage = true
                        this.dialogText = 'Error guardando el perfil de usuario'
                    }
                }
            } else {
                this.userUpdatedMessage = true
                this.dialogText = 'Corrige los errores en el formulario para poder guardar'
            }
            
        },

        changeImage(image) {
            this.updateUserImage = image
        },

        setStateError(error){
            this.errorInForms = error
        },

        validateTerms(data){
            if(data.acceptedTerms && data.privacyTerms){
                return true;
            } else {
                this.userUpdatedMessage = true
                this.dialogText = 'Debes aceptar los términos y condiciones'
                return false;
            }
        }
    },
}
