// import SearchComponent from '../search/search.vue'
import Cards from './cards/cards.vue'
import Sidebar from './sidebar/sidebar.vue'
import Bus from '../../../plugins/bus'
export default {
    components: {
        Cards,
        Sidebar,
    },
    max: 255,
    data: () => ({
        amenities: [1, 4],
        neighborhoods: [1],
        drawer: false,
        group: null,
    }),
    created() {
        Bus.$on('drawer', () => {
            this.drawer = !this.drawer
        })
    },
    watch: {
        group() {
            this.drawer = false
        },
    },
}
