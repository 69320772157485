import TermsAndConditionsModal from '../../termsAndConditions/termsAndConditions.vue'
export default {
    name: 'FormSignUp',
    components: { TermsAndConditionsModal },
    data() {
        return {
            nombre: '',
            localidad: '',
            email: '',
            contraseña: '',
        }
    },
    methods: {
        submit() {
            //  this.$v.$touch()
        },
    },
}
