import Vue from 'vue'

const state = Vue.observable({
    dateState: false,
})

export default {
    // make state a getter here to provide access to the private state variable
    // only allow getting so that it cannot be modified without calling a mutation method
    get state() {
        return state
    },

}
