import { render, staticRenderFns } from "./termsandConditions.vue?vue&type=template&id=e15264da&scoped=true"
import script from "./termsandConditions.js?vue&type=script&lang=js&external"
export * from "./termsandConditions.js?vue&type=script&lang=js&external"
import style0 from "./termsandConditions.css?vue&type=style&index=0&id=e15264da&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e15264da",
  null
  
)

export default component.exports