class PlaceTypes {
    static listOfTypes = {
      beach: {
        color: '#FFE082',
        type: 'mdi-umbrella-beach',
      },
      city: {
        color: '#0e53c0',
        type: 'mdi-city-variant',
      },
      discotech: {
        color: '#b70ad8',
        type: 'mdi-glass-cocktail',
      },
      restaurant: {
        color: '#5cd613',
        type: 'mdi-silverware-fork-knife',
      },
      selected: {
        color: 'red',
        type: 'mdi-ethereum',
      },
      town: {
        color: '#0e53c0',
        type: 'mdi-city-variant',
      },
      otherPlace: {
        color: '#9d7c21',
        type: 'mdi-store-search',
      },
      noType: {
        color: 'black',
        type: 'mdi-sync-alert',
      },
    };
  }
  
  export default PlaceTypes;