import Vue from 'vue'
import sessionStore from '@/store/session'
import { keycloak } from '@/plugins/keycloak';

import loginView from '../views/login/login.vue'
import signUpView from '../views/signUp/signUp.vue'
import recoverPasswordView from '../views/recoverPassword/recoverPassword.vue'
import loading from '../views/Loading/loading.vue'
import confirmCodeView from '../views/confirmCode/confirmCode.vue'
import PrincipalView from '../views/home'
//import Establishment from '../views/establishment/establishment.vue'
import Discotheque from '../views/discotheque'
import EstablishmentEvents from '../views/establishmentEvents/'
import PlaceEvents from '../views/placeEvents/'
import EventView from '../views/eventView/'
import EventPlaceView from '../views/eventPlaceView/'
import EventTickets from '../views/eventTickets/'
import CheckoutTickets from '../views/checkoutTickets/'
import creatEstablishment from '../views/creatEstablishment/creatEstablishment.vue'
import editEstablishment from '../views/editEstablishment/editEstablishment.vue'
import Event from '../views/event/event.vue'
import Hotels from '../views/Hotels/hotels.vue'
//import Ocio from '../views/ocio/ocio.vue'
//import Restaurants from '../views/restaurants/restaurants.vue'
import Restaurant from '../views/restaurant'
import BookRestaurant from '../views/bookRestaurant'
import NightClubs from '../views/nightClubs/nightClubs.vue'
//import Payment from '../views/payment/payment.vue'
import Payment from '../views/payment'
import HoldOnPayment from '../views/holdOnPayment'
import AppointmentConfirm from '../views/appointmentConfirm'
import Methods from '../views/methods/methods.vue'
import AddMethod from '../views/addmethod/addMethod.vue'
import PaymentCompleted from '../views/paymentCompleted/paymentcompleted.vue'
import Profile from '../views/profile/profile.vue'
import ProfilePurchase from '../views/profilePurchase/profilePurchase.vue'
import Help from '../views/help/help.vue'
import PaymentMethods from '../views/payment-methods/paymentmethods.vue'
import FrequentQuestions from '../views/frequentQuestions/frequentquestions.vue'
import TermsandConditions from '../views/termsandConditions2/termsandConditions.vue'
import PrivacyPolicy from '../views/privacyPolicy/privacyPolicy.vue'
import ReportAproblem from '../views/reportAproblem/reportAproblem.vue'
import EditProfile from '../views/editProfile/editProfile.vue'
import Events from '../views/events/events.vue'
import Error404 from '../views/error404/error404.vue'
import SearchBar from '../views/SearchBar'
import schedule from '../views/schedule'
import Favourites from '../views/favourites'
import ChatList from '../views/chatList'
import ChatDisplay from '../views/chatDisplay'
import Promotions from '../views/Promotions/promotions.vue'
import Notifications from '../views/notifications/notifications.vue'
import MessageNotifications from '../views/messageNotifications/messageNotifications.vue'
import Beach from '../views/beach'
import BeachServices from '../views/beachServices'
import Trends from '../views/trends'
import OtherPlace from '../views/otherPlace'
import OtherPlaceServices from '../views/otherPlaceServices'
import Town from '../views/town'
import TownServices from '../views/townServices'
import TourOperador from '../views/tourOperador'
import TourReservas from '../views/tourReservas'
import TourTrasnfer from '../views/tourTransfer'
import History from '../views/history'
import Wallet from '../views/wallet'
import AddCard from '../views/addCard'
import VueRouter from 'vue-router'
import CovidInfo from '../views/covidInfo/covid-info.vue'
import authModalStore from '@/store/authModal'
import Icons from '../views/icons'
import placeRecomendations from '../views/placeRecomendations'

Vue.use(VueRouter)
const routes = [
    {
        path: '/login',
        name: 'login',
        component: loginView,
        meta: {
            header: {
                hideNavbar: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/sign-up',
        name: 'signUp',
        component: signUpView,
        meta: {
            header: {
                hideNavbar: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/recover-password',
        name: 'recoverPassword',
        component: recoverPasswordView,
        meta: {
            header: {
                hideNavbar: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/loading',
        name: 'loading',
        component: loading,
        meta: {
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/confirm-code',
        name: 'confirmCode',
        component: confirmCodeView,
        meta: {
            header: {
                hideNavbar: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/',
        name: 'PrincipalView',
        component: PrincipalView,
        meta: {
            roles: ['guest', 'user-view'],
        },
    },
    /* {
        path: '/establishment/:id/',
        name: 'Establishment',
        component: Establishment,
        meta: {
            header: {
                hideNavbar: true,
                hideBottomButtons: true,
            },
            roles: ['guest', 'user-view']
        },
    }, */
    {
        path: '/establishment/restaurant/:id/',
        name: 'restaurant',
        component: Restaurant,
        meta: {
            header: {
                hideNavbar: true,
                hideBottomButtons: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/establishment/restaurant/:id/bookrestaurant',
        name: 'bookRestaurant',
        component: BookRestaurant,
        meta: {
            header: {
                backBtn: true,
                hideBottomButtons: true,
            },
            roles: ['admin', 'user-edit', 'user'],
            redirectUrl: '/editprofile',
        },
    },
    {
        path: '/establishment/discotheque/:id/',
        name: 'discotech',
        component: Discotheque,
        meta: {
            header: {
                hideNavbar: true,
                hideBottomButtons: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/establishment/discotheque/:id/events',
        name: 'establishmentEvents',
        component: EstablishmentEvents,
        meta: {
            header: {
                backBtn: true,
                hideBottomButtons: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/establishment/discotheque/:id/events/:slug',
        name: 'eventView',
        component: EventView,
        meta: {
            header: {
                hideNavbar: true,
                hideBottomButtons: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/establishment/discotheque/:id/events/:slug/tickets',
        name: 'eventTickets',
        component: EventTickets,
        meta: {
            header: {
                hideNavbar: true,
                hideBottomButtons: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/establishment/discotheque/:id/events/:slug/tickets/checkout',
        name: 'checkoutTickets',
        component: CheckoutTickets,
        meta: {
            header: {
                backBtn: true,
                hideNavbar: false,
                hideBottomButtons: true,
            },
            roles: ['admin', 'user-edit', 'user'],
            redirectUrl: '/editprofile',
        },
    },
    {
        path: '/event',
        name: 'Event',
        component: Event,
        meta: {
            header: {
                hideNavbar: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/hotels',
        name: 'Hotels',
        component: Hotels,
        meta: {
            roles: ['guest', 'user-view'],
        },
    },
    /* {
        path: '/restaurants',
        name: 'Restaurants',
        component: Restaurants,
        meta: {
            roles: ['guest', 'user-view']
        }
    }, */
    {
        path: '/search',
        name: 'search',
        component: SearchBar,
        meta: {
            header: {
                componentName: 'SearchHeader',
                headerFlat: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/nightclubs',
        name: 'NightClubs',
        component: NightClubs,
        meta: {
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/payment',
        name: 'Payment',
        component: Payment,
        meta: {
            header: {
                backBtn: true,
                hideBottomButtons: true,
                reloadBtn: true,
                menuBtn: true,
            },
            roles: ['admin', 'user-edit', 'user'],
            redirectUrl: '/editprofile',
        },
    },
    {
        path: '/adquire-payment',
        name: 'HoldOnPayment',
        component: HoldOnPayment,
        meta: {
            header: {
                backBtn: true,
                hideBottomButtons: true,
                reloadBtn: true,
                menuBtn: true,
            },
            roles: ['admin', 'user-edit', 'user'],
            redirectUrl: '/editprofile',
        },
    },
    {
        path: '/appointment-confirm/:id',
        name: 'appointmentConfirm',
        component: AppointmentConfirm,
        meta: {
            header: {
                hideBottomButtons: false,
                reloadBtn: false,
                menuBtn: true,
            },
            roles: ['admin', 'user-edit', 'user'],
            redirectUrl: '/editprofile',
        },
    },
    {
        path: '/schedule/ticket/:id?',
        name: 'ScheduleTicket',
        component: () => import('../views/scheduleTicket'),
        meta: {
            header: {
                backBtn: true,
                parentRoute: 'schedule',
                hideBottomButtons: true,
                reloadBtn: false,
                menuBtn: true,
            },
            roles: ['admin', 'user-edit', 'user'],
            redirectUrl: '/editprofile',
        },
    },
    {
        path: '/methods',
        name: 'Methods',
        component: Methods,
        meta: {
            header: {
                hideNavbar: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/addMethod',
        name: 'AddMethod',
        component: AddMethod,
        meta: {
            header: {
                hideNavbar: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/paymentcompleted',
        name: 'PaymentCompleted',
        component: PaymentCompleted,
        meta: {
            header: {
                hideNavbar: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {
            header: {
                hideNavbar: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/profilePurchase',
        name: 'ProfilePurchase',
        component: ProfilePurchase,
        meta: {
            header: {
                hideNavbar: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/help',
        name: 'Help',
        component: Help,
        meta: {
            header: {
                backBtn: true,
                headerText: 'Centro de Ayuda',
                menuBtn: true,
                notiBtn: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/paymentmethods',
        name: 'PaymentMethods',
        component: PaymentMethods,
        meta: {
            header: {
                hideNavbar: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/frequentquestions',
        name: 'FrequentQuestions',
        component: FrequentQuestions,
        meta: {
            header: {
                backBtn: true,
                headerText: 'Preguntas Frecuentes',
                menuBtn: true,
                notiBtn: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/termsandconditions',
        name: 'TermsandConditions',
        component: TermsandConditions,
        meta: {
            header: {
                hideNavbar: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/privacypolicy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        meta: {
            header: {
                hideNavbar: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/reportaproblem',
        name: 'ReportAproblem',
        component: ReportAproblem,
        meta: {
            header: {
                hideNavbar: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/editprofile',
        name: 'EditProfile',
        component: EditProfile,
        meta: {
            roles: ['guest', 'user-edit'],
        },
    },

    {
        path: '/notifications',
        name: 'Notifications',
        component: Notifications,
        meta: {
            header: {
                headerText: 'Notificaciones',
                menuBtn: true,
                notiBtn: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/messageNotifications',
        name: 'MessageNotifications',
        component: MessageNotifications,
        meta: {
            header: {
                backBtn: true,
                helpBtn: true,
                parentRoute: 'Notifications',
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/promotions',
        name: 'Promotions',
        component: Promotions,
        meta: {
            header: {
                headerText: 'Código y Promociones',
                backBtn: true,
                helpBtn: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/covidInfo',
        name: 'CovidInfo',
        component: CovidInfo,
        meta: {
            header: {
                headerText: 'Información COVID-19',
                backBtn: true,
                helpBtn: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/events',
        name: 'Events',
        component: Events,
        meta: {
            header: {
                hideNavbar: true,
            },
            roles: ['guest', 'user-view'],
        },
    },

    {
        path: '/establishment/create',
        name: 'CreatEstablishment',
        component: creatEstablishment,
        meta: {
            header: {
                hideNavbar: true,
            },
            roles: ['guest', 'user-view'],
        },
    },

    {
        path: '/establishment/edit',
        name: 'editEstablishment',
        component: editEstablishment,
        meta: {
            header: {
                hideNavbar: true,
            },
            roles: ['guest', 'user-view'],
        },
    },

    {
        path: '/schedule',
        name: 'schedule',
        component: schedule,

        meta: {
            header: {
                headerText: 'MIS PLANES',
                reloadBtn: true,
                menuBtn: true,
            },
            roles: ['admin', 'user-edit', 'user'],
            redirectUrl: '/editprofile',
        },
    },

    {
        path: '/favourites',
        name: 'favourites',
        component: Favourites,
        meta: {
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/chat',
        name: 'ChatList',
        component: ChatList,
        meta: {
            header: {
                headerText: 'Chat',
                menuBtn: true,
                notiBtn: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/chat/:id',
        name: 'ChatDisplay',
        component: ChatDisplay,
        meta: {
            header: {
                backBtn: true,
                callBtn: true,
                parentRoute: 'ChatList',
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/places/beach/:id',
        name: 'beach',
        component: Beach,
        meta: {
            header: {
                hideNavbar: true,
                hideBottomButtons: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/places/beach/:id/services',
        name: 'beachServices',
        component: BeachServices,
        meta: {
            header: {
                backBtn: true,
                hideBottomButtons: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/places/beach/:id/whats-up',
        name: 'whatsUp',
        params: true,
        component: Icons,
        meta: {
            header: {
                backBtn: true,
                hideNavbar: false,
                hideBottomButtons: true,
                headerText: '¿Qué hay?',
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/places/beach/:id/whats-up',
        name: 'placeRecomendations',
        params: true,
        component: placeRecomendations,
        meta: {
            header: {
                backBtn: true,
                hideNavbar: false,
                hideBottomButtons: true,
                headerText: 'Recomendaciones',
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/places/:id',
        name: 'otherplace',
        component: OtherPlace,
        meta: {
            header: {
                hideNavbar: true,
                hideBottomButtons: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/places/:id/services',
        name: 'otherPlaceServices',
        component: OtherPlaceServices,
        meta: {
            header: {
                backBtn: true,
                hideBottomButtons: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/places/town/:id',
        name: 'town',
        component: Town,
        meta: {
            header: {
                hideNavbar: true,
                hideBottomButtons: true,
            },
        },
        roles: ['guest', 'user-view'],
    },
    {
        path: '/places/town/:id/whats-up',
        name: 'whatsUp',
        params: true,
        component: Icons,
        meta: {
            header: {
                backBtn: true,
                hideNavbar: false,
                hideBottomButtons: true,
                headerText: '¿Qué hay?',
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/places/town/:id/whats-up',
        name: 'placeRecomendations',
        params: true,
        component: placeRecomendations,
        meta: {
            header: {
                backBtn: true,
                hideNavbar: false,
                hideBottomButtons: true,
                headerText: 'Recomendaciones',
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/places/town/:id/services',
        name: 'townServices',
        component: TownServices,
        meta: {
            header: {
                backBtn: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/places/:id/events',
        name: 'placeEvents',
        component: PlaceEvents,
        meta: {
            header: {
                backBtn: true,
                hideBottomButtons: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/places/:id/events/:slug',
        name: 'eventPlaceView',
        component: EventPlaceView,
        meta: {
            header: {
                hideNavbar: true,
                hideBottomButtons: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/touroperador/:id',
        name: 'tourOperador',
        component: TourOperador,
        meta: {
            header: {
                backBtn: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/touroperador/:id/reservas',
        name: 'tourReservas',
        component: TourReservas,
        meta: {
            header: {
                backBtn: true,
                hideBottomButtons: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/touroperador/:id/transfer',
        name: 'tourTransfer',
        component: TourTrasnfer,
        meta: {
            header: {
                backBtn: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/profile/:id/wallet/history',
        name: 'history',
        component: History,
        meta: {
            header: {
                backBtn: true,
                reloadBtn: true,
                menuBtn: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '/profile/:userID/wallet',
        name: 'wallet',
        component: Wallet,
        meta: {
            header: {
                backBtn: true,
                hideBottomButtons: true,
                reloadBtn: true,
                menuBtn: true,
            },
            roles: ['admin', 'user-edit', 'user'],
            redirectUrl: '/editprofile',
        },
    },
    {
        path: '/profile/:userID/addCard',
        name: 'addCard',
        component: AddCard,
        meta: {
            header: {
                backBtn: true,
                hideBottomButtons: true,
                reloadBtn: true,
                menuBtn: true,
            },
        },
        roles: ['guest', 'user-view'],
    },
    {
        path: '/trends',
        name: 'trends',
        component: Trends,
        meta: {
            header: {
                backBtn: true,
                hideBottomButtons: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
    {
        path: '*',
        name: 'Error404',
        component: Error404,
        meta: {
            header: {
                hideBottomButtons: true,
                backBtn: true,
            },
            roles: ['guest', 'user-view'],
        },
    },
]
const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
})
export default router

router.beforeEach(async (to, from, next) => {
    const routeRoles = to.meta.roles

    if (from.name !== null && !sessionStore.isSessionSet) {
        // when first visit setSession is called from main.js
        await sessionStore.setNewSession()
    }

    if (routeRoles) {
        await keycloak.updateTokenIfExpired();

        const hasRoles = sessionStore.hasRoles(Vue.$keycloak, routeRoles)
        if (!hasRoles) {
            authModalStore.showModal();
            next(to.meta.redirectUrl ? to.meta.redirectUrl : '/')
        }
    }

    if (sessionStore.state.error) {
        // next(false);
        next()
    } else {
        next()
    }
})
