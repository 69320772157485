import { render, staticRenderFns } from "./offers.vue?vue&type=template&id=642f6213&scoped=true"
import script from "./offers.js?vue&type=script&lang=js&external"
export * from "./offers.js?vue&type=script&lang=js&external"
import style0 from "./offers.scss?vue&type=style&index=0&id=642f6213&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "642f6213",
  null
  
)

export default component.exports