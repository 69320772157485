import api from '@/services/Api'

class CardsData {
  async getCards() {
    try {
      const info = await api.get(`/payments/cards`)
      return info.data.data.cards
    } catch (error) {
      if (error.response.data.statusCode == '404') return false
      return null
    }
  }
  /*   async createCard(data, userID) {
    try {
      cards.push(data)
         const payloadCard = {
                cardToken: data.card.id,
                bin: data.card.last4,
                brand: data.card.brand,
            }
            const info = await api.post(
                `/payments/${userID}/cards`,
                payloadCard,
            )
            return info.data.data
      return cards
    } catch (error) {
      if (error.response.data.statusCode == '404') return false
      return null
    }
  } */
  async deleteCard(data) {
    try {
      cards.splice(data, 1)
      return cards
      //const info = await api.get(`/payments/${userId}/cards/${idCard}`)
      //return info.data.data
    } catch (error) {
      if (error.response.data.statusCode == '404') return false
      return null
    }
  }
}
const cardsData = new CardsData()
export default cardsData

let cards = []
