import ImageCard from '@/ui/components/imageCard'
import MainButton from '@/ui/components/mainButton'
import headerStore from '@/store/header'
import discothequeStore from '@/store/establishments'
import discoEvents from '@/store/discoEvents'
import filterSearch from '@/ui/components/filterSearch'
import filterStore from '@/store/filter'
import Bus from '@/plugins/bus'
import { DateTime } from 'luxon'

export default {
    name: 'EstablishmentEvents',

    components: {
        ImageCard,
        MainButton,
        filterSearch,
    },

    data() {
        return {
            loading: false,
            allEventsShow: false,
            datesBetween: false,
            restoreData: false,
            existDateRange: false,
        }
    },
    methods: {
        upper(value) {
            return value.trim().replace(/^\w/, (c) => c.toUpperCase())
        },
        goToEvent(event) {
            this.$router.push({
                name: 'eventView',
                params: { eventID: event.id, slug: event.slug },
            })
        },
        getData() {
            return this.$route.params.eventInfo
        },
        dateText(date) {
            const customDate = DateTime.fromISO(date, {
                zone: 'utc',
            })
            return this.upper(
                customDate.setLocale('es').toFormat("EEEE dd 'de' LLLL"),
            )
        },
        openFilter() {
            Bus.$emit('searchFilter')
        },
        prepareSort(a, b) {
            var fechaA = DateTime.fromISO(a.date)
            var fechaB = DateTime.fromISO(b.date)
            return fechaA.diff(fechaB).milliseconds
        },
    },
    computed: {
        eventsData() {
            let arrayEvents = discoEvents.state.eventsData
            return arrayEvents.sort(this.prepareSort)
        },
        favoriteEventsData() {
            let arrayEvents = discoEvents.state.favoriteEventsData
            return arrayEvents.sort(this.prepareSort)
        },

        discoName() {
            return this.upper(discothequeStore.state.establishmentData.name)
        },
        noData() {
            if (this.eventsData === null || this.eventsData.length == 0) {
                return true
            }
            return discoEvents.state.noData
        },
        changueRestoreData() {
            return this.restoreData
        },
    },
    async created() {
        this.loading = true
        if (
            this.$route.params.eventInfo == undefined &&
            !discoEvents.state.eventsData
        ) {
            if (this.$router.referer) this.$router.go(-1)
            else this.$router.push('/')
        } else if (!discoEvents.state.eventsData) {
            try {
                headerStore.setHeaderText(this.discoName)
                await discoEvents.setFavoriteEventsData(this.getData())
                await discoEvents.setEventsData(this.getData())
            } catch (error) {}
        }
        this.loading = false
        Bus.$on('updateFilter', (data) => {
            if (data.changeDate) this.datesBetween = data.newDateRange
        })
        Bus.$on('removeDateFilter', (data) => {
            if (data.removeDateFilter && this.existDateRange)
                this.restoreData = true
            this.existDateRange = false
        })
    },
    watch: {
        async datesBetween() {
            this.existDateRange = true
            filterStore.state.dateState = this.datesBetween
            this.loading = true
            await discoEvents.setEventsData(this.getData(), this.datesBetween)
            await discoEvents.setFavoriteEventsData(
                this.getData(),
                this.datesBetween,
            )
            this.loading = false
        },
        async changueRestoreData() {
            this.restoreData = false
            try {
                this.loading = true
                await discoEvents.setEventsData(this.getData())
                await discoEvents.setFavoriteEventsData(this.getData())
            } catch (e) {
                console.log(e)
            }
            this.loading = false
        },
    },
}
