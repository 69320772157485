import api from '@/services/Api'

class PlaceData {
    async getPlaceData(id) {
        try {
            const info = await api.get(`/places/slug/${id}`)
            return info.data.data
        } catch (error) {
            if (error.response.data.statusCode == '404') return false
            return null
        }
    }
    async getNearbyPlaces(data) {
        try {
            const nearbyData = {
                distance: data[0],
                idPlace: data[1],
                lat: data[3],
                lon: data[4],
            }

            const nearbyPlaces = await api.post(
                '/places/nearbyPlaces',
                nearbyData,
            )
            return nearbyPlaces.data.data
        } catch (error) {
            if (data == undefined) return 'history'
            else if (error.response.data.statusCode == '400') return false
            return null
        }
    }
}
const placeData = new PlaceData()
export default placeData
