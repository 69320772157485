import userStore from '@/store/user'
import cardStore from '@/store/creditCard'

export default {
  data() {
    return {
      loadingCards: true,
      methodPaymentSelectedId: '',
      stripeUser: ''
    }
  },

  methods: {
    async loadUserStripeData(){

      try {
        await cardStore.setCards()
      } catch (error) {}
      
      this.loadingCards = false;

      this.stripeUser = await userStore.getStripeCustomer()
    },

    continueToPayment(type, amount, dataToBuy, options = null, confirm = true, adquireOnly = false){
      const idCard = this.methodPaymentSelectedId === 'newCard' ? '' : this.methodPaymentSelectedId

      this.$router.push({
        name: 'Payment',
        params: {
          customer: this.stripeUser.data.data.customer,
          amount,
          idCard,
          type,
          referenceObj: dataToBuy,
          options, 
          adquireOnly,
          confirm
        },
      })
    },
    continueToHoldOnPayment(type, amount, dataToBuy, options = null){
      const idCard = this.methodPaymentSelectedId === 'newCard' ? '' : this.methodPaymentSelectedId

      this.$router.push({
        name: 'HoldOnPayment',
        params: {
          customer: this.stripeUser.data.data.customer,
          amount,
          idCard,
          type,
          referenceObj: dataToBuy,
          options, 
          adquireOnly: true,
          confirm: false
        },
      })
    }
  },

  computed: {
    
    userCards() {
        if (cardStore.state.cardsData) {
          return cardStore.state.cardsData
        }
        return []
    },
},
}