export default {
    amenities: true,
    data() {
        return {
            min: 1,
            max: 500,
            range: [1, 250],
            //draw: this.DrawBar,
            //prueba: this.DrawBar,
            tab: null,
        }
    },
}
