import AppNotification from "@/domain/appNotification";

export default {
  data() {
    return {
      showNotification: false,
      deniedPermission: false,
      notificaction: new AppNotification(),
    }
  },

  mounted(){
    if(this.notificaction.isPermissionReseted()){
      this.showNotification = true;
    }
  },

  methods: {
    async showNotificationRequest(){
      this.showNotification = false;

      const permission = await this.notificaction.requestPermission();
      
      if(this.notificaction.canNotify() && this.notificaction.isPermissionReseted()){
        this.$emit('onRequestAccepted');
      } 
      else if(permission === 'denied' && this.notificaction.isPermissionReseted())
        this.deniedPermission = true;
      }
  }
}