import api from '@/services/Api'

class HomeData {

    async getHomeData(coords){
        try {
            const info = await api.get(`/offer/home?lat=${coords[0]}&lon=${coords[1]}`)
            return info.data.data
        } catch (error) {
            if (error.response.data.statusCode == '404') return false
            return null
        }
    }  
}
const homeData = new HomeData()
export default homeData
