import { render, staticRenderFns } from "./reportAproblem.vue?vue&type=template&id=086ec1ac&scoped=true"
import script from "./reportAproblem.js?vue&type=script&lang=js&external"
export * from "./reportAproblem.js?vue&type=script&lang=js&external"
import style0 from "./reportAproblem.css?vue&type=style&index=0&id=086ec1ac&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "086ec1ac",
  null
  
)

export default component.exports