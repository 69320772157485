import headerStore from '@/store/header'
import cardSelection from '@/ui/mixins/cardSelection'
import MainButton from '@/ui/components/mainButton'
import Carousel from '@/ui/components/carousel'
import MapLeaflet from '@/ui/components/mapLeaflet'
import FoodMenu from '@/ui/components/foodMenu'
import CardSelectGroup from '@/ui/components/cardSelectGroup'
import digitalTimePicker from "@/ui/components/digitalTimePicker";
import gooutDates from '@/domain/dates/dateClass';
import schedule from '../schedule/schedule'

export default {
    name: 'BookRestaurant',

    components: {
        MainButton,
        Carousel,
        MapLeaflet,
        FoodMenu,
        CardSelectGroup,
        digitalTimePicker
    },

    mixins: [cardSelection], //loadsCards, has the props methodPaymentSelectedId and stripeUser

    data() {
        return {
            diners: 1,
            hourToBook: false,
            price: this.$route.params.appointment.price,
            dateDay:this.$route.params.dateDay,
            stateButton: true,

            hours: ['11', '12', '13'],
            isValidHour: false,
            appointment: this.$route.params.appointment
        }
    },
    async created() {
        if (!this.$route.params.dateDay) {
            this.$router.push('/')
            return;
          } else {
            await this.loadUserStripeData();
        }

        this.price = this.$route.params.appointment.price
        this.dateDay = this.$route.params.dateDay
        this.appointment = this.$route.params.appointment

        headerStore.setHeaderText(
            'Reservas - ' + this.upper(this.$route.params.restName),
        )
    },

    methods: {
        upper(value) {
            return value.trim().replace(/^\w/, (c) => c.toUpperCase())
        },
        amount(op) {
            if (op == '-') this.diners -= 1
            else if (op == '+') this.diners += 1
        },
        goToPayment() {
            const dinersList = [];
            
            dinersList.push({ qty: this.diners, price: (this.price * this.diners), serviceId: this.appointment.id });
            const date = gooutDates.buildIsoDate(this.dateDay.year, this.dateDay.monthNumber, this.dateDay.dateNumberDay, this.hourToBook);
            const total = this.diners * this.price;

            const options = { appointmentDate: date, appointment: 'CREATED' }
            this.continueToHoldOnPayment('appointment', total, dinersList, options)

          },
    },

    computed: {
        restName() {
            return this.upper(this.$route.params.restName)
        },
    },
    watch:{
        isValidHour(newValue){
            if(newValue){
                this.stateButton = false

            } else{
                this.stateButton = true
            }
        }   
    }
}
