export default {
    name: 'EventView',
    props: ['infoEvent'],
    data() {
        return {
            ofertas: [
                {
                    name: 'Entrada Plan B',
                    price: '12,00 €',
                    descripcion: 'Backstage - 1 consumición',
                    cantidad: 'x1',
                },
                {
                    name: 'Combinado',
                    price: '8,00 €',
                    descripcion: 'Vaso de tubo',
                    cantidad: 'x1',
                },
            ],
            isMobile: false,
        }
    },
    created() {
        this.isMobile = this.$vuetify.breakpoint.mobile
    },
}
