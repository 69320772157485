import Vue from 'vue'

const state = Vue.observable({
    notificationCount: 0,
});

export default {

   get state(){ return state },
   get count(){ return state.notificationCount },

  loadNotificationCount(){ 
    const countSession = getCountFromSessionStorage();
    sessionStorage.setItem('notificationCount', countSession);
    
    state.notificationCount = countSession;
  },

  increaseCount(){ 
    state.notificationCount += 1;
    sessionStorage.setItem('notificationCount', state.notificationCount);
  },

  decreaseCount(){
    if(state.notificationCount > 0)
      state.notificationCount -= 1;
      sessionStorage.setItem('notificationCount', state.notificationCount);
  }

}

const getCountFromSessionStorage = () => {
  try {
    const countSession = sessionStorage.getItem('notificationCount') || 0; //notifications gotten with de app oppened
    return parseInt(countSession);
  } catch (error) {
    return 0;
  }
}