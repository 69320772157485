import headerStore from '@/store/header'
import cardStore from '@/store/creditCard'
import { StripeElementCard } from '@vue-stripe/vue-stripe'

export default {
    name: 'addCard',

    components: {
        StripeElementCard,
    },

    data() {
        this.publishableKey =
            'pk_test_51N46j7JDRhwKDTCHACgt4VlneKwAc2N0HYnNlKKnRkm4v8474pcq435nlm1uUuhH9FY0Iy97XCFzlpLQJLHdJNFw00EPetOJqM'
        return {
            succesCard: false,
            token: null,
            style: {
                invalid: {
                    color: 'green',
                },
            },
        }
    },
    methods: {
        createCard() {
            this.$refs.elementRef.submit()
        },
        async tokenCreated(token) {
            console.log(token)

            try {
                await cardStore.newCard(token, this.userID)
                this.succesCard = true
                this.$refs.elementRef.clear()
            } catch (error) {}
        },
    },

    computed: {
        userID(){
            return this.$route.params.userID
        }
    },
    created() {
        headerStore.setHeaderText('Nueva Tarjeta de Crédito')
    },
}
