import Vue from 'vue'
import category from '@/services/cartegoriesService'
import dateClass from '@/domain/dates/dateClass'

const state = Vue.observable({
    categories: [],
    filterCateogries: [],
    searchData: new Array(),
    nearbyData: new Object(),
    noData: false,
    notResults: false,
})

export default {
    // make state a getter here to provide access to the private state variable
    // only allow getting so that it cannot be modified without calling a mutation method
    get state() {
        return state
    },

    async setCategories(searchTerm = null) {
        try {
            if (!searchTerm || searchTerm === '')
                state.filterCateogries = await category.getCategories()
            else
                state.filterCateogries = await category.findCategories(
                    searchTerm,
                )
        } catch (error) {
            throw error
        }
    },
    async setNearbyPlaces(data) {
        const responsePlace = await category.getNearbyPlacesSearch(data)
        const responseEstablishment =
            await category.getNearbyEstablishmentsSearch(data)
        if (responsePlace == 'history') {
            //no cambia la variable nearbyData
        } else if (!responsePlace && !responseEstablishment) {
            state.nearbyData = new Object()
            state.noData = true
        } else if (responsePlace && responseEstablishment) {
            state.nearbyData = responsePlace.concat(responseEstablishment)
            state.noData = false
        } else if (!responsePlace && responseEstablishment) {
            state.nearbyData = responseEstablishment
            state.noData = false
        } else if (responsePlace && !responseEstablishment) {
            state.nearbyData = responsePlace
            state.noData = false
        }
    },
    async setNearbyAllPlaces(data, dateRange = false) {
        state.searchData = data
        let response = false
        if (dateRange) {
            if (dateRange.length == 1) {
                dateRange[1] = dateRange[0]
            }
            if (dateRange[1] == dateRange[0]) {
                dateRange[0] = dateClass.toIsoDate(dateRange[0])
                dateRange[1] = dateClass.toIsoDate(dateRange[1], {hour: 19})
            }
            response = await category.getNearbyAllPlaces(
                data,
                false,
                dateRange,
                true,
            )
        } else {
            response = await category.getNearbyAllPlaces(data)
        }
        if (response == 'history') {
            //no cambia la variable nearbyData
        } else if (!response) {
            state.nearbyData = new Object()
            state.noData = true
        } else {
            state.nearbyData = response
            state.noData = false
        }
    },
}
