import L from 'leaflet'
import { LPopup, LMap, LTileLayer, LMarker, LIcon } from 'vue2-leaflet'
import nearbyPlacesStore from '@/store/place'
import MainButton from '@/ui/components/mainButton'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CategoryList from '@/ui/components/categoryList'
import placeTypes from '@/domain/placeTypes/placesClass'
import { Teleport } from 'vue'

export default {
    name: 'mapLeaflet',

    props: {
        coordinates: {
            type: Array,
            required: true,
        },
        nearbyMarkers: {
            type: Array,
            default: () => [],
        },
        width: { default: false },
        height: { default: false },
        fullScreen: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LIcon,
        LPopup,
        MainButton,
        FontAwesomeIcon,
        Teleport,
        CategoryList,
    },
    data() {
        return {
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:'',
            overlay: false,
            absolute: true,
            show: false,
            placeName: '',
            placeCategories: [],
            placeDesc: '',
            placeImages: [],
            placeSlug: '',
            placeType: [],
            placeCoords: this.coordinates,
            actualDay: 0,
            selected: ['noData', ''],
            isEstablishment: false,
            disableButton: false,
            establishmentSchedule: [
                ['Lunes: ', ''],
                ['Martes: ', ''],
                ['Miércoles: ', ''],
                ['Jueves: ', ''],
                ['Viernes: ', ''],
                ['Sábado: ', ''],
                ['Domingo: ', ''],
            ],
            expand: false,
        }
    },
    methods: {
        goToNearby(slug, type) {
            this.updateMarkers('selected')
            let place = type.view.toLowerCase()
            this.$router.push({
                name: place,
                params: { id: slug },
            })
        },
        loseFocus() {
            this.updateMarkers('selected')
            this.show = !this.show
        },
        showMarkerInfo(item, index) {
            this.updateMarkers(item, index)
            this.actualDay = 0
            this.$refs.descriptionCard.focus()
            if (!this.show) this.show = !this.show
            this.placeName = item.name
            this.placeCategories = item.categories
            this.placeDesc = item.description.slice(0, 150) + '...'
            this.placeImages = item.images
            this.placeSlug = item.slug
            this.placeType = item.type
            this.placeCoords = [item.lat, item.lon]
            if (
                item.type.view == 'Restaurant' ||
                item.type.view == 'Discotech'
            ) {
                this.isEstablishment = true
                for (let i in item.options.schedule) {
                    if (item.options.schedule[i].part1.start == '') {
                        this.establishmentSchedule[i][1] = 'Cerrado'
                    } else {
                        this.establishmentSchedule[i][1] =
                            item.options.schedule[i].part1.start +
                            ' - ' +
                            item.options.schedule[i].part1.end
                    }
                }
            } else this.isEstablishment = false

            item.type.view = 'selected'
        },
        updateMarkers(item, index) {
            if (item == 'selected') {
                this.nearbyPoints[this.selected[0]].type.view = this.selected[1]
                return
            }
            if (this.selected[0] != 'noData') {
                this.nearbyPoints[this.selected[0]].type.view = this.selected[1]
                this.selected[0] = index
                this.selected[1] = item.type.view
            } else {
                this.selected[0] = index
                this.selected[1] = item.type.view
            }
        },
        changueDay(direction) {
            this.show = true
            this.$refs.descriptionCard.focus()
            if (direction == 0) {
                if (!this.actualDay) this.actualDay = 6
                else this.actualDay -= 1
            } else if (direction) {
                if (this.actualDay == 6) this.actualDay = 0
                else this.actualDay += 1
            }
        },
        setplaceType(type) {
            const name = type.view.toLowerCase()
            return Object.keys(placeTypes.listOfTypes).includes(name) ? name : 'otherPlace'
        },
    },
    computed: {
        dynamicSize() {
            return [25, 41]
        },
        dynamicAnchor() {
            return [12, 41]
        },
        nearbyPoints() {
            if (this.showNearby && nearbyPlacesStore.state.nearbyMapMarkers) {
                return nearbyPlacesStore.state.nearbyMapMarkers
            }
            return this.nearbyMarkers
        },
        markersCoords() {
            return this.coordinates
        },
        showNearby() {
            if (this.nearbyMarkers.length <= 0) return false
            else return true
        },
        iconType() {
            return function (type) {
                let placeType = this.setplaceType(type)
                return placeTypes.listOfTypes[placeType].type
            }
        },
        iconColorType() {
            return function (type) {
                let placeType = this.setplaceType(type)
                return placeTypes.listOfTypes[placeType].color
            }
        },
    },
    filters: {
        upper: function (value) {
            return value.trim().replace(/^\w/, (c) => c.toUpperCase())
        },
    },
    created() {
        if (!this.nearbyMarkers) this.disableButton = true
        if (this.showNearby && !nearbyPlacesStore.state.nearbyMapMarkers) {
            nearbyPlacesStore.state.nearbyMapMarkers = this.nearbyPoints
        }
        delete L.Icon.Default.prototype._getIconUrl
        L.Icon.Default.mergeOptions({
            iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
            iconUrl: require('leaflet/dist/images/marker-icon.png'),
            shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
        })
    },
}
