export default {
    name: 'Event',
    data() {
        return {
            Eventos: [
                {
                    title: 'Plan B - Ladies Night3',
                    fecha: 'Jueves 56 de Julio',
                    ubicacion: 'Backstage',
                    descripcion:
                        'Lorem ipsum dolor sit amet consectetur adipisicing elit  Deleniti ad voluptate, total  doloremque illum officiis soluta',
                },
                {
                    title: 'Shakira - Ladies Night5',
                    fecha: 'Jueves 6 de Julio',
                    ubicacion: 'Backstage',
                    descripcion:
                        'Lorem ipsum dolor sit amet consectetur adipisicing elit  Deleniti ad voluptate, total  doloremque illum officiis soluta',
                },
                {
                    title: 'Shakira - Ladies Night2',
                    fecha: 'Jueves 6 de Julio',
                    ubicacion: 'Backstage',
                    descripcion:
                        'Lorem ipsum dolor sit amet consectetur adipisicing elit  Deleniti ad voluptate, total  doloremque illum officiis soluta',
                },
                {
                    title: 'Shakira - Ladies Night1',
                    fecha: 'Jueves 6 de Julio',
                    ubicacion: 'Backstage',
                    descripcion:
                        'Lorem ipsum dolor sit amet consectetur adipisicing elit  Deleniti ad voluptate, total  doloremque illum officiis soluta',
                },
            ],
        }
    },
}
