import ImageCard from '@/ui/components/imageCard'
import headerStore from '@/store/header'
import nearbyPlacesStore from '@/store/place'
import nearbyPlaces from '@/ui/components/nearbyPlaces'
import MapLeaflet from '@/ui/components/mapLeaflet'
import MainButton from '@/ui/components/mainButton'
export default {
    name: 'otherPlaceServices',

    components: {
        ImageCard,
        nearbyPlaces,
        MapLeaflet,
        MainButton,
    },
    data() {
        return {
            coordinates: new Array(),
            arraryNearby: false,
            loading: false,
            absolute: true,
            overlay: false,
        }
    },
    methods: {
        upper(value) {
            return value.trim().replace(/^\w/, (c) => c.toUpperCase())
        },
        getData() {
            return this.$route.params.nearbyInfo
        },
    },
    async created() {
        this.loading = true
        if (
            this.$route.params.nearbyInfo == undefined &&
            !nearbyPlacesStore.state.coordinatesServicesMap
        ) {
            if (this.$router.referer) this.$router.go(-1)
            else this.$router.push('/')
        } else {
            if (!nearbyPlacesStore.state.coordinatesServicesMap) {
                this.coordinates = [
                    this.$route.params.nearbyInfo[3],
                    this.$route.params.nearbyInfo[4],
                ]
                nearbyPlacesStore.state.coordinatesServicesMap =
                    this.coordinates
            } else
                this.coordinates =
                    nearbyPlacesStore.state.coordinatesServicesMap
            try {
                await nearbyPlacesStore
                    .setNearbyPlaces(this.getData())
                    .then(
                        headerStore.setHeaderText(
                            this.upper(nearbyPlacesStore.state.nearbyName),
                        ),
                    )
                this.arraryNearby = nearbyPlacesStore.state.nearbyData
            } catch (error) {}
            this.loading = false
        }
    },
    computed: {
        nearbyObj() {
            return nearbyPlacesStore.state.nearbyData
        },
        placeName() {
            return this.upper(nearbyPlacesStore.state.nearbyName)
        },
        noData() {
            if (Object.keys(this.nearbyObj).length === 0) {
                return true
            } else return nearbyPlacesStore.state.noData
        },
    },
}
