import { DateTime } from 'luxon'

export default {
    name: 'Image-card',

    props: {
        image: {
            type: String,
            required: true,
        },

        title: {
            type: String,
        },

        subtitle: null,

        addinfo: null,

        width: {
            type: String,
            default: '100%',
        },

        height: {
            type: String,
            default: '100%',
        },

        aspectRatio: {
            type: Number,
            default: null,
        },

        expandValue: {
            type: String,
            default: '1.05', //1.05 || 105%
        },

        justify: {
            type: String,
            default: 'center',
        },
        align: {
            type: String,
            default: 'center',
        },
        justifyTitle: {
            type: String,
            default: 'center',
        },
        noHover: Boolean,
        noExpand: Boolean,
        backButton: Boolean,
        subtitleDate: Boolean
    },
    methods:{
        goBack(){
            if (this.$router.referer) this.$router.go(-1)
            else this.$router.push('/')
        }
    },
    computed: {
        cssVars() {
            if (this.noExpand) {
                return { '--expand': 1 }
            } else {
                return { '--expand': this.expandValue }
            }
        },
        customText() {
            const customDate = DateTime.fromISO(this.subtitle, {
                zone: 'utc',
            })
            if (this.subtitleDate)
                return customDate.setLocale('es').toFormat("dd 'de' LLLL HH:mm")
            else return this.subtitle
        },
        addInfoText() {
            if (typeof this.addinfo == 'object') {
                var arrayUpper = this.addinfo.map(function (element) {
                    return element.trim().replace(/^\w/, (c) => c.toUpperCase())
                })
                return arrayUpper.join(' ')
            } else return this.addinfo
        },
    },
}
