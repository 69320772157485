export default function install(Vue, options) {
    Vue.mixin({
        beforeRouteEnter(to, from, next) {
            window.scrollTo(0, 0)
            next((vm) => {
                if (vm.$router) {
                    vm.$router.referer = from
                }
            })
        },

        beforeRouteUpdate(to, from, next) {
            if (this.$router) {
                this.$router.referer = from
            }
            next()
        },
    })
}
