import MainButton from '@/ui/components/mainButton'
import Carousel from '@/ui/components/carousel'
import MapLeaflet from '@/ui/components/mapLeaflet'
import CategoryList from '@/ui/components/categoryList'
import discothequeStore from '@/store/establishments'
import discoEvents from '@/store/discoEvents'

export default {
    name: 'EventView',

    components: {
        MainButton,
        Carousel,
        MapLeaflet,
        CategoryList,
    },

    data() {
        return {
            loading: false,
            descriptionLength: 250,
            showFullDescription: false,
        }
    },
    async created() {
        try {
            this.loading = true
            if (!discothequeStore.state.establishmentData)
                await discothequeStore.setEstablishmentData(
                    this.$route.params.id,
                    true,
                )
            await discoEvents.setEvenViewData(this.getId())
            discothequeStore.setEventName(discoEvents.state.dataAux?.name || '')

        } catch (error) {}
        this.loading = false
    },

    methods: {
        goToTickets() {
            this.$router.push({
                name: 'eventTickets',
                params: {
                    eventData: this.eventInfo,
                },
            })
        },
        getId() {
            return this.$route.params.slug
        },
        fullText() {
            this.showFullDescription = !this.showFullDescription
        },
    },
    filters: {
        upper: function (value) {
            return value.trim().replace(/^\w/, (c) => c.toUpperCase())
        },
    },

    computed: {
        eventInfo() {
            return discoEvents.state.eventViewData
        },
        discoInfo() {
            return discothequeStore.state.establishmentData.name
        },

        eventDescription() {
            if (!this.descriptionExceeds) return this.eventInfo.description

            if (this.showFullDescription) return this.eventInfo.description
            else
                return (
                    this.eventInfo.description.slice(
                        0,
                        this.descriptionLength,
                    ) + '...'
                )
        },

        descriptionExceeds() {
            return this.eventInfo.description.length > this.descriptionLength
        },

        descriptionButtonText() {
            return this.showFullDescription ? 'VER MENOS' : 'VER MÁS'
        },
        coordinates() {
            return new Array(
                this.eventInfo.stablishment.lat,
                this.eventInfo.stablishment.lon,
            )
        },
        googleCoordinates() {
            return `https://www.google.com/maps/search/${this.eventInfo.stablishment.lat},${this.eventInfo.stablishment.lon}`
        },
    },
}
