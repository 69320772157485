export default {
    name: 'preloader',
    data() {
        return {
            show: true,
        }
    },
    mounted() {
        if (this.show) this.showToggle()
    },
    methods: {
        showToggle() {
            setTimeout(() => {
                this.show = false
            }, 1000)
        },
    },
}
