export default {
    name: 'Gender',
    props:{
        value: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            users: [],            
        }
    },
   
    computed:{
        genero: {
            get(){
                return this.value
            },
            set(newValue){
                this.$emit('input', newValue )              
            }
        }

    }
}
