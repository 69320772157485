import userStore from '@/store/user'
import gooutDates from '@/domain/dates/dateClass';
import dateFilter from '@/ui/components/dateFilter';

export default {
    name: 'schedule',
    
    components: {
        dateFilter
    },
    
    data(){
        return {
            appoinments: [
                {
                    "invoice": {
                      "_id": "6508e076229979d4590f159a",
                      "paymentIntent": "pi_3Nrr5VPLs4AOIjXN01RMO2aO",
                      "price": 3,
                      "kind": "appointment",
                      "options": {
                        "appointmentDate": "2023-08-19T20:30:00.000Z",
                        "appointment": "CREATED"
                      },
                      "reference": [
                        {
                          "qty": 1,
                          "price": 3,
                          "id": "e81ea48a-07f0-40dd-8bf7-38150d6b7dbb"
                        }
                      ],
                      "createdAt": "2023-09-18T23:42:46.631Z",
                      "__v": 0,
                      "id": "6508e076229979d4590f159a"
                    },
                    "status": "requires_confirmation",
                    "stablishment": {
                    
                      "name": "Restaurant Antic Grill",
                    
                    }
                }
            ]
        }
    },

    async mounted() {
        try {
          //  userStore.setPlans();
        } catch (error) {
            console.log(error)
        }
    },

    methods: {
         getAppointments(date){
             userStore.getPlans(date);
        },

        getDate(date){
            const newDate = gooutDates.getDateReadableDate(date);
            return `${newDate.date} - ${newDate.hour}`;
        },

        getTitle(item){
            if(item.service){
                if(item.kind === 'event' && item.service.event) return item.service.event.name
                const doc = new DOMParser().parseFromString(item.service.description || '', 'text/html');
               return doc.body.textContent || "INVALID";
            }

            return ''
        },

        getServiceDate(item){
            if(item.options) return this.getDate(item.options.appointmentDate);
            if (item.service && item.service.event) return this.getDate(item.service.event.date);
            return 'INVALID'
        },

        getServiceDetailString(type, quantity){
            const singularOrPlural = quantity > 1 ? 's' : '';
            
            switch (type) {
                case 'appointment': return `Reservación para ${quantity}`
                case 'event': return `${quantity} entrada${singularOrPlural}`
                default: return 'Desconocido'
            }
        },

        getServiceIcon(type){
            switch (type) {
                case 'appointment': return `mdi-room-service`
                case 'event': return 'mdi-party-popper'
                default: return 'Desconocido'
            }
        },

        goToConfirmationView(id, planKind){
            switch (planKind) {
                case 'appointment': this.$router.push({ name: 'appointmentConfirm', params: { id }})
                    break;
                case 'event': this.$router.push({ name: 'ScheduleTicket', query: { invoiceID: id }})
                    break;
                default: console.log('type unknown')
                    break;
            }
        }
    },

    computed: {
        plans(){
            return userStore.state.plans
        },
        plansLoaded(){
            return userStore.state.plans.loading;
        }
    },
    watch:{
        plansLoaded(){
            userStore.populateServices();
        }
    }
}
