export default {
    
    name: 'Termsand',
    props:{
        value: {
            required: true,
            type: Boolean,
        },
        linkText: {
           required: true,
           type: String, 
        },
        title:{
            required: true,
            type: String,
        },
        body:{
            default:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus similique ea,',
            type: String,
        },
        },
    data() {
        return {
            users: [],
            checkbox1: false,
            checkbox:false,
            dialog: false,
        }
    },

    methods: {
        handleClick(e) {
            e.stopPropagation()
            if( this.checkbox1 == false){
                //activar ventana emergente
            this.dialog = true;         
            
            }
        },

    },
 
    computed:{
        accepted: {
            set(newValue){
                this.$emit('input', newValue)
            },
            get(){
                return this.value
            }
        }

    }
}