export default {
    name: 'ComponentHeader',

    props: {
        title: {
            type: String,
        },
        
        subtitle: {
            type: String,
        },
    }
}
