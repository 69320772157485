import { Settings, DateTime } from 'luxon'

export class gooutDates {
    constructor(){
        Settings.defaultLocale = 'es-ES'
    }

    toIsoDate(
        date,
        { hour = 0, minute = 0, second = 0, millisecond = 0 } = {},
    ) {
        return DateTime.fromISO(date)
            .set({ hour, minute, second, millisecond })
            .toUTC()
            .toISO()
    }
    dateFromIso(date) {
        return DateTime.fromISO(date).toISODate()
    }
    newLocalIsoDate(){
        return DateTime.local().toISODate()
    }

    buildIsoDate(year, monthNumber, day, time ){
        const [hour, minute] = time.split(':').map(n => parseInt(n));
        
        const date = DateTime.utc(year, monthNumber, day, hour, minute, 0, 0).toISO();

        return date;
    }

    getDateReadableDate(isoDate){
        const dateObj = DateTime.fromISO(isoDate)
        // DateTime.local = 'es';
        const date = dateObj.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
        const hour = dateObj.toUTC().toFormat('hh:mm a')
        // const hour = `${dateObj.toUTC().hour}:${dateObj.toUTC().minute} ${dateObj.toUTC().}`
        // const humanReadable = dateObj.toLocaleString(DateTime.DATE_FULL);

        // const [date, hour] = humanReadable.split(',');

        return { date, hour};
    }

    getMonthName(monthIndex, long = true, lang = 'es'){
        const date = new Date(2000, monthIndex - 1, 1);
        const month = date.toLocaleString(lang, { month: long ? 'long' : 'short' });
        return month;
    }

    today(format = "yyyy-MM-dd"){
        return DateTime.now().toFormat(format);
    }

}
export default new gooutDates()
