import ImageCard from '@/ui/components/imageCard'
import MainButton from '@/ui/components/mainButton'
import placeTypes from '@/domain/placeTypes/placesClass'

export default {
    name: 'nearbyPlaces',

    components: {
        ImageCard,
        MainButton,
    },
    props: {
        arrayNearby: null,
        placeNameView: {
            type: String,
            default: 'Soller',
        },
        title: {
            type: String,
        },

        subtitle: {
            type: String,
        },
    },
    data() {
        return {
            nearbyObjPlaces: [],
        }
    },
    mounted() {
        this.createNearbyObj()
    },

    methods: {
        showHide(id) {
            for (let i in this.NearbyObj) {
                if (this.NearbyObj[i].id == id)
                    this.NearbyObj[i].show = !this.NearbyObj[i].show
            }
            this.$forceUpdate()
        },
        goToNearby(slug, type) {
            let place = type.view.toLowerCase()
            this.$router.push({
                name: place,
                params: { id: slug },
            })
        },
        createNearbyObj() {
            const nearbyPlaces = {}
            let id = 0
            let catName = ''
            try {
                this.nearby.forEach(function (place) {
                    place.categories.forEach(function (category) {
                        catName = category.name.toLowerCase()
                        if (!Object.keys(nearbyPlaces).includes(catName)) {
                            nearbyPlaces[catName] = []
                            nearbyPlaces[catName]['show'] = false
                            nearbyPlaces[catName]['id'] = id
                            nearbyPlaces[catName]['image'] = category.image
                        }
                        nearbyPlaces[catName].push(place)
                        id = id + 1
                    })
                })
                this.nearbyObjPlaces = nearbyPlaces
            } catch (error) {
                console.log(error)
            }
        },
    },

    filters: {
        upper: function (value) {
            return value.trim().replace(/^\w/, (c) => c.toUpperCase())
        },
    },

    computed: {
        numberSites() {
            return function (category) {
                return category.length.toString()
            }
        },
        nearby() {
            return this.arrayNearby
        },
        NearbyObj() {
            return this.nearbyObjPlaces
        },
    },
}
