import SecurityPoliticsModal from '../securityPolitics/securityPolitics.vue'
export default {
    props: {
        data: {
            title: '',
            body: '',
        },
    },
    name: 'TermsAndConditionsModal',
    components: { SecurityPoliticsModal },
    data() {
        return {
            dialog: false,
        }
    },
}
