

export default {
    

    data() {
        return {
          messageCovids: [{
            id: '65165',  
            title: 'información',          
            message: 'La COVID-19 afecta de distintas maneras en función de cada persona. La mayoría de las personas que se contagian presentan síntomas de intensidad leve o moderada, y se recuperan sin necesidad de hospitalización, Los síntomas más habituales son los siguientes: fiebre, tos, cansancio, Pérdida del gusto o del olfato. Los sintomas menos habituales son los siguientes: dolor de garganta, dolor de cabeza, molestias y dolores, diarrea, erupcion cutanea o perdida de color de los dedos de las manos o los pies, Ojos rojos o irritados.',
            
          },
           
          ],
        }
    },
    methods: {
       
    },

    created() {
        
    },
}
