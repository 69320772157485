import { render, staticRenderFns } from "./index.vue?vue&type=template&id=de13ae50&scoped=true"
import script from "./addCard.js?vue&type=script&lang=js&external"
export * from "./addCard.js?vue&type=script&lang=js&external"
import style0 from "./addCard.scss?vue&type=style&index=0&id=de13ae50&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de13ae50",
  null
  
)

/* custom blocks */
import block0 from "./en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Ftmp%2Fsrc%2Fui%2Fviews%2FaddCard%2Findex.vue&locale=en&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./es.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Ftmp%2Fsrc%2Fui%2Fviews%2FaddCard%2Findex.vue&locale=es&external"
if (typeof block1 === 'function') block1(component)

export default component.exports