import { render, staticRenderFns } from "./index.vue?vue&type=template&id=579ca098"
import script from "./home.js?vue&type=script&lang=js&external"
export * from "./home.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./lang/en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Ftmp%2Fsrc%2Fui%2Fviews%2Fhome%2Findex.vue&locale=en&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./lang/es.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Ftmp%2Fsrc%2Fui%2Fviews%2Fhome%2Findex.vue&locale=es&external"
if (typeof block1 === 'function') block1(component)

export default component.exports