import { onNotification, messaging } from '@/plugins/firebase';
import notificationStore from '@/store/notification';
export default {
  data() {
    return {
      notification: {
        show: false,
        title: '',
        body: ''
      },

    }
  },

  async mounted(){
    this.listenNotification();
  },

  methods: {
    listenNotification(){
      onNotification(messaging, ({ notification }) => {
        this.notification.title = notification.title;
        this.notification.body = notification.body;
        this.notification.show = true;

        notificationStore.increaseCount();
      });
    }
  }
}