export default {
    data() {
        return {
            tickets: [
                {
                    name: 'Plan B - Ladies Night1',
                    date: 'Jueves 5 de Julio',
                },
                {
                    name: 'Plan B - Ladies Night2',
                    date: 'Jueves 6 de Julio',
                },
            ],
        }
    },
}
