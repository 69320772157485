import MainButton from '@/ui/components/mainButton'
import Carousel from '@/ui/components/carousel'
import MapLeaflet from '@/ui/components/mapLeaflet'
import CategoryList from '@/ui/components/categoryList'
import townStore from '@/store/place'

export default {
    name: 'Town',

    components: {
        MainButton,
        Carousel,
        MapLeaflet,
        CategoryList,
    },

    data() {
        return {
            descriptionLength: 250,
            loading: false,
            showFullDescription: false,
        }
    },
    async created() {
        try {
            this.loading = true
            await townStore.setPlaceData(this.getId())
        } catch (error) {}
        this.loading = false
    },

    methods: {
        goToServices() {
            if (townStore.state.nearbyMapMarkers) {
                townStore.state.coordinatesServicesMap = false
                townStore.state.nearbyMapMarkers = false
            }
            this.$router.push({
                name: 'townServices',
                params: { id: this.getId(), nearbyInfo: this.getNearbyData() },
            })
        },
        goToEvents() {
            this.$router.push({
                name: 'placeEvents',
                params: {
                    id: this.getId(),
                    queryId: this.townInfo._id
                },
            })
        },
        goToIcons(){
            this.$router.push({
                name: `whatsUp`,
                params: { id: this.getId(), icons: this.townInfo.icons.town.features },
            })
        },
        goToRecomendations() {
            this.$router.push({
                name: `placeRecomendations`,
                params: { id: this.getId(), recomendations: this.townInfo.recomendations},
            })
        },

        getId() {
            return this.$route.params.id
        },

        getNearbyData() {
            let distance = 0
            if (this.townInfo.options[0] == undefined)
                distance = parseInt(this.townInfo.options.distance)
            else distance = parseInt(this.townInfo.options[0]['value'])
            return new Array(
                distance,
                this.townInfo.id,
                this.townInfo.name,
                this.townInfo.lat,
                this.townInfo.lon,
            )
        },

        fullText() {
            this.showFullDescription = !this.showFullDescription
        },
    },
    filters: {
        upper: function (value) {
            return value.trim().replace(/^\w/, (c) => c.toUpperCase())
        },
    },

    computed: {
        townInfo() {
            return townStore.state.placeData
        },
        townDescription() {
            if (!this.descriptionExceeds) return this.townInfo.description

            if (this.showFullDescription) return this.townInfo.description
            else
                return (
                    this.townInfo.description.slice(0, this.descriptionLength) +
                    '...'
                )
        },

        descriptionExceeds() {
            return this.townInfo.description.length > this.descriptionLength
        },

        descriptionButtonText() {
            return this.showFullDescription ? 'VER MENOS' : 'VER MÁS'
        },
        coordinates() {
            return new Array(this.townInfo.lat, this.townInfo.lon)
        },
        googleCoordinates() {
            return `https://www.google.com/maps/search/${this.townInfo.lat},${this.townInfo.lon}`
        },
    },
}
