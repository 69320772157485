export default {
    name: 'chatList',
    
    data(){
        return {
            chats: [
                {
                    id: '3244223',
                    subject: 'Servicio - Victoria Gran Melia',
                    body: 'Esperamos que disfrute de su estancia en Mallorca.',
                    date: '12:34 PM',
                    unseenMessages: 1,
                },
                {
                    id: '1214323',
                    subject: 'Excursion Torrent de Pareis',
                    body: 'El bus de las 10 se retras a las 11 disculpe los inconvenientes.',
                    date: 'Ayer',
                    unseenMessages: 0,
                },
                {
                    id: '2388823',
                    subject: 'Tour Náutico, Sport Club',
                    body: 'Recuerde traer agua y crema solar, en caso de mareo haganolo saber.',
                    date: '10/10/2022',
                    unseenMessages: 0,
                }
            ]
        }
    },
}
