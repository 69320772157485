import homeStore from '@/store/storeHome'
import { Flicking } from '@egjs/vue-flicking'
import '@egjs/vue-flicking/dist/flicking.css'
import ImageCard from '@/ui/components/imageCard'
import ComponentHeader from '@/ui/components/componentHeader'
import { nextTick } from 'vue'

export default {
    name: 'Home',

    components: {
        ImageCard,
        ComponentHeader,
        Flicking,
    },
    data() {
        return {
            loading: false,
            noCoords: false,
            userCoords: new Array(),
            pagination: {
                topActivities: 2,
                favourites: 2,
                plans: 2,
                increments: 2,
                plansPagination: 2,
            },
            planActivities: new Array(),
        }
    },
    async created() {
        try {
            this.loading = true
            await this.getUserLocation()
            await homeStore.setHomeData(this.userCoords)
            if (!this.noCoords) {
                this.loading = false
            }
        } catch (error) {
            console.log(error)
        }
    },

    methods: {
        async getUserLocation() {
            try {
                const position = await new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject)
                })
                this.userCoords.push(position.coords.latitude)
                this.userCoords.push(position.coords.longitude)
            } catch (error) {
                console.error(error)
                this.noCoords = true
                this.testCoords()
            }
        },
        async testCoords() {
            this.userCoords[0] = 39.69678505358755
            this.userCoords[1] = 2.699869388626506
            await homeStore.setHomeData(this.userCoords)
            this.loading = false
        },
        applyTopActivitiesPagination() {
            if (this.canTopActivitiesPaginationLoadMore)
                this.pagination.topActivities += this.pagination.increments
            else this.pagination.topActivities = this.pagination.increments

            this.scroll(this.activities.slice(-1)[0].id)
        },

        applyPlansPagination() {
            if (this.canPlansPaginationLoadMore)
                this.pagination.plansPagination += this.pagination.increments
            else this.pagination.plansPagination = this.pagination.increments

            this.scroll(this.plans.slice(-1)[0].id)
        },

        scroll(id, position = 'end') {
            nextTick(() => {
                const el = this.$refs[id]

                if (el) {
                    el[0].scrollIntoView({
                        behavior: 'smooth',
                        block: position,
                    })
                }
            })
        },

        goToPlace(obj) {
            let place = obj.type.name.toLowerCase()
            if (place.includes('event')) {
                place = place === 'event' ? 'eventView' : 'eventPlaceView'
                this.$router.push({
                    name: place,
                    params: {
                        eventID: obj.id,
                        slug: obj.slug,
                        id: obj.stablishment
                            ? obj.stablishment.slug
                            : obj.place.slug,
                    },
                })
            } else {
                place = obj.type.view.toLowerCase()
                this.$router.push({
                    name: place,
                    params: { id: obj.slug },
                })
            }
        },

        shuffleArray(array) {
            return array.sort(function () {
                return 0.5 - Math.random()
            })
        },
        goToTrends() {
            this.$router.push({
                name: 'trends',
            })
        },
    },

    computed: {
        trends() {
            return homeStore.state.homeData.events.slice(0, 10)
        },

        activities() {
            const activities = homeStore.state.homeData.placeEvents.slice(0, 10)
            return activities.slice(0, this.pagination.topActivities)
        },
        canTopActivitiesPaginationLoadMore() {
            return (
                this.pagination.topActivities <
                homeStore.state.homeData.placeEvents.slice(0, 10).length
            )
        },
        activitiesPaginationButtonText() {
            return this.canTopActivitiesPaginationLoadMore ? this.$t('commonWords.more') : this.$t('commonWords.less')
        },

        selectPlans() {
            const ramdomPlaces = this.shuffleArray(
                homeStore.state.homeData.places,
            )
            const ramdomEstablishments = this.shuffleArray(
                homeStore.state.homeData.stablishments,
            )
            this.planActivities = ramdomPlaces
                .slice(0, 5)
                .concat(ramdomEstablishments.slice(0, 5))
            return this.planActivities
        },
        plans() {
            return this.selectPlans.slice(0, this.pagination.plansPagination)
        },
        canPlansPaginationLoadMore() {
            return this.pagination.plansPagination < this.planActivities.length
        },

        favouritesPaginationButtonText() {
            return this.canTopActivitiesPaginationLoadMore ? this.$t('commonWords.more') : this.$t('commonWords.less')
        },

        plansPaginationButtonText() {
            return this.canPlansPaginationLoadMore ? this.$t('commonWords.more') : this.$t('commonWords.less')
        },

        noData() {
            return homeStore.state.noData
        },
    },
}
