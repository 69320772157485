export default {
    data() {
        return {
            tipo: ['VIP', 'Item'],
            Name: '',
            nameRules: [(v) => !!v || 'Se requiere el nombre'],
            establisments: [],
            switch1: false,
            isMobile: false,
        }
    },

    created() {
        this.isMobile = this.$vuetify.breakpoint.mobile
    },
}
