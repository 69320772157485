import Bus from '@/plugins/bus'
import searchBarStore from '@/store/search'
import DrawerButton from '@/ui/components/drawerButton'
export default {
    name: 'SearchHead',

    components: {
        DrawerButton,
    },

    data: () => ({
        isLoading: false,
        items: [],
        model: null,
        search: null,
        firstChargeInfo: false,
    }),

    methods: {
        openFilter() {
            Bus.$emit('searchFilter')
        },
        updateSearch() {
            let val = ''
            setTimeout(function () {
                const chipText = document.querySelector('.pickerText')
                if (chipText) {
                    val = chipText.textContent
                }
                searchBarStore.setCategories(val)
            }, 100)
        },
        searchPromp(search) {
            const chipText = document.querySelector('.pickerText')
            if (chipText) search = chipText.textContent
            searchBarStore.setCategories(search)
        },
    },
    watch: {
        search() {
            let searchItems = Object.keys(searchBarStore.state.categories)

            if (!Object.keys(searchBarStore.state.nearbyData).length == 0) {
                searchBarStore.state.nearbyData.forEach(function (item) {
                    searchItems.push(item.name)
                })
            }
            if (!this.firstChargeInfo) {
                this.isLoading = true
                this.items = searchItems
                this.initialInfo = searchItems
                this.isLoading = false
                this.firstChargeInfo = true
            }
            if (this.search.length > 0) {
                this.items.shift()
                this.items.unshift(this.search)
            }else{
                this.items = searchItems
            }
        },
    },
}
