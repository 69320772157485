import api from '@/services/Api'
import searchBarStore from '@/store/search'

export default {
    async getCategories() {
        if (searchBarStore.state.notResults) searchBarStore.state.noData = true
        else return searchBarStore.state.categories
    },

    async findCategories(searchTerm) {
        const infoCategories = searchBarStore.state.categories
        const infoSeparatePlaces = searchBarStore.state.nearbyData
        let filterObj = new Object()
        for (const obj in infoCategories)
            if (obj.includes(searchTerm.toLowerCase())) {
                filterObj[obj] = infoCategories[obj]
            }
        for (const obj in infoSeparatePlaces) {
            let aux = infoSeparatePlaces[obj].name.toLowerCase()
            if (aux.includes(searchTerm.toLowerCase())) {
                filterObj[obj] = infoSeparatePlaces[obj]
            } else {
            }
        }
        if (searchBarStore.state.noData) {
            searchBarStore.state.noData = false
            searchBarStore.state.notResults = true
        }
        if (Object.keys(filterObj).length == 0) {
            filterObj = await this.getNearbyAllPlaces(searchBarStore.state.searchData, searchTerm, false ,true)
            if(!filterObj) filterObj = []
            if (Object.keys(filterObj).length == 0){
                searchBarStore.state.noData = true
            } else {
                searchBarStore.state.noData = false
            }
        }
        console.log(filterObj)
        return filterObj
    },

    async getPlaceData() {
        try {
            const info = await api.get(`/places?limit=-1`)
            return info.data.data.places
        } catch (error) {
            if (error.response.data.statusCode == '404') return false
            return null
        }
    },

    async getEstablishmentData() {
        try {
            const info = await api.get(`/stablishment?limit=-1`)
            return info.data.data.stablishments
        } catch (error) {
            if (error.response.data.statusCode == '404') return false
            return null
        }
    },

    async getNearbyPlacesSearch(data) {
        try {
            const nearbyData = {
                distance: data[0],
                lat: data[1],
                lon: data[2],
            }
            const nearbyPlaces = await api.post(
                '/places/nearbyPlaces',
                nearbyData,
            )
            if (nearbyPlaces.data.data.codeName == 'BadValue') return false
            return nearbyPlaces.data.data
        } catch (error) {
            if (data == undefined) return 'history'
            else if (error.response.data.statusCode == '400') return false
            return null
        }
    },

    async getNearbyEstablishmentsSearch(data) {
        try {
            const nearbyData = {
                distance: data[0],
                lat: data[1],
                lon: data[2],
            }
            const nearbyDataStablhisment = await api.post(
                '/stablishment/nearbyStablishments',
                nearbyData,
            )
            if (nearbyDataStablhisment.data.data.codeName == 'BadValue')
                return false
            return nearbyDataStablhisment.data.data
        } catch (error) {
            if (data == undefined) return 'history'
            else if (error.response.data.statusCode == '400') return false
            return null
        }
    },
    async getNearbyAllPlaces(data, searchText, dateRange, isSearch) {
        let nearbyDataStablhisment = false
        let urlParams = ''
        try {
            if (isSearch) {
                if(searchText){
                    urlParams = urlParams+`&text=${searchText}`
                }
                if(dateRange){
                    urlParams = urlParams+`&startDate=${dateRange[0]}&endDate=${dateRange[1]}`
                }
                nearbyDataStablhisment = await api.get(
                    `/offer?lat=${data[1]}&lon=${data[2]}&distance=${data[0]}`+urlParams,
                )
            } else {
                nearbyDataStablhisment = await api.get(
                    `/offer?lat=${data[1]}&lon=${data[2]}&distance=${data[0]}`,
                )
            }
            if (nearbyDataStablhisment.data.data == null) return false
            return nearbyDataStablhisment.data.data
        } catch (error) {
            if (data == undefined) return 'history'
            else if (error.response.data.statusCode == '400') return false
            return null
        }
    },
}
