import api from "./Api";


export default {
  async getDeviceData(deviceId){
    try {
      const response = await api.get(`/devices/${deviceId}`);
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async setDeviceData(userId, device, token){
    const response = await api.post('/devices', { userId, device, token });

    return response;
  }
}