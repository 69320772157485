import Vue from 'vue'
import transactionHistory from '@/services/transactionHistoryService'
const state = Vue.observable({
    transactionsData: [],
    noData: false,
    moreItems: false,
})

export default {
    get state() {
        return state
    },

    async setHistory(id = false) {
        //state.transactionsData = false
        let response
        state.moreItems = true
        setTimeout(async function () {
            response = await transactionHistory.getHistory(id)
            console.log(response)
            if (response.length == 0) {
                state.noData = true
            } else {
                state.noData = false
                state.transactionsData.push(...response)
            }
            state.moreItems = false
        }, 5000)
    },
}
