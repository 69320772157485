import Api from '@/services/Api'

export default {
    GetUser() {
        // return Api().get(
        //     'http://localhost:9292/v1/users/email/tratrapel@gmail.com',
        // )
    },
    CreateUser(_user) {
        return Api().post('http://localhost:9292/v1/users', _user).data
    },
    getUsers(){
        // lo agrege porque se usa en varios sitios pero no se hace nada con ella
    }
}
