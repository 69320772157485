export default {
    data() {
        return {
            Methods: [
                {
                    typeOfCard: 'fab fa-cc-visa',
                    number: 'xxxxxx4561',
                },
                {
                    typeOfCard: 'fab fa-cc-mastercard',
                    number: 'xxxxxx4563',
                },
            ],
            isMobile: false,
        }
    },
    created() {
        this.isMobile = this.$vuetify.breakpoint.mobile
    },
}
