import Api from '@/services/Api'

class FavouritesService {
    async getTrends() {
        return trends
    }

    async getFavourites(userId) {
        return favourites
    }

    async getRecommendedPlans(userId) {
        return plans
    }

    async modifyFavourites(userId, itemId, newState){
        //call to api
        return newState;
    }

}
const favouritesService = new FavouritesService()
export default favouritesService

const trends = [
    {
        id: '2342342',
        title: 'Excursiones',
        servicesNumber: '6',
        src: 'https://picsum.photos/id/1015/5000/3333',
    },
    {
        id: '12312342321',
        title: 'Cafes',
        servicesNumber: '20',
        src: 'https://picsum.photos/id/1060/5000/3333',
    },
    {
        id: '1232342121',
        title: 'Work friendly',
        servicesNumber: '16',
        src: 'https://picsum.photos/id/201/5000/3333',
    },
]

const favourites = [
    {
        id: '23423422342',
        title: 'Excursiones',
        servicesNumber: '6',
        favourite: false,
        src: 'https://picsum.photos/id/1015/5000/3333',
    },
    {
        id: '12312342342321',
        title: 'Cafes',
        servicesNumber: '20',
        favourite: false,
        src: 'https://picsum.photos/id/1060/5000/3333',
    },
    {
        id: '123234222121',
        title: 'Work friendly',
        servicesNumber: '16',
        favourite: true,
        src: 'https://picsum.photos/id/201/5000/3333',
    },
]

const plans = [
    {
        id: '23422345',
        title: 'Excursiones',
        servicesNumber: '6',
        src: 'https://picsum.photos/id/1015/5000/3333',
    },
    {
        id: '12234312342321',
        title: 'Cafes',
        servicesNumber: '20',
        src: 'https://picsum.photos/id/1060/5000/3333',
    },
    {
        id: '123232344212111',
        title: 'Work friendly',
        servicesNumber: '16',
        src: 'https://picsum.photos/id/201/5000/3333',
    },
]
