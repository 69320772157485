import FooterComponentVue from '../../footer/footer.vue'
import Header from '../../header/header.vue'
import Togglemenu from '../togglemenu/togglemenu.vue'


export default {
    name: 'LayoutComponent',
    components: {
        
        Togglemenu,
        FooterComponentVue,
        Header,
    },

    computed: {
         metaInfo() {
            const meta = this.$route.meta || null;
            if(meta){
                return meta.header === undefined || JSON.stringify(meta.header) === '{}' ? null : meta.header
            }
            return null
        },

        flatLayout() {
            return this.metaInfo?.headerFlat ? 0 : 1;
        }
    }
}
