export default {
  props: {
    weekDay: {
      type: String,
      required: true,
    },

    numberDay: {
      type: [String, Number],
      default: null,
    },

    month: {
      type: String,
      default: ''
    },

    selector: {
      type: Function,
    },

    hideDetails: Boolean,
    active: Boolean
  },

  methods: {
  
  },

  computed: {
    
  }
}
