import Ocio5 from '../../../../../assets/ocio/ocio6.jpeg'

export default {
    data() {
        return {
            events: [
                {
                    name: 'Plan B - Ladies Night',
                    date: 'Jueves 5 de Julio',
                    src: Ocio5,
                },
                {
                    name: 'Plan B - Ladies Night',
                    date: 'Jueves 5 de Julio',
                    src: Ocio5,
                },
            ],
        }
    },
}
