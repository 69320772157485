import regionStore from '@/store/region'
import Termsand from './termsand/termsand.vue'
import Gender from './gender/gender.vue'
import CountryFlag from 'vue-country-flag'

const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export default {
    components: {
        Termsand,
        Gender,
        CountryFlag,
    },

    props: {
        user: {
            type: Object,
            required: true,
        },

        hideNonEdit: Boolean,
    },

    data: () => ({
        date: null,
        menu: false,

        dialog: false,
        users: [],
        userAux: {},
        select: null,
        cities: [],
        valid: false,
        Name: '',
        nameRules: [
            (v) => !!v || 'Se requiere el nombre',
            (v) => v.length >= 3 || 'El nombre debe tener mas de 3 caracteres',
        ],
        
        emailRules: [
            (v) => !!v || 'Se requiere el correo electrónico',
            (v) => !v || emailRegex.test(v) || 'Debes ingresar un correo electronico valido',
            (v) => v.length >= 3 || 'El correo debe tener mas de 3 caracteres',
        ],

        surnameRules: [
            (v) => !!v || 'Se requiere el apellido',
            (v) =>
                v.length >= 3 || 'El apellido debe tener mas de 3 caracteres',
        ],

        Day: '',

        location: '',

        locationRules: [(v) => !!v || 'Se requiere la localidad'],

        address: '',
        addressRules: [
            (v) => !!v || 'Se requiere la dirección',
            (v) =>
                v.length <= 150 ||
                'la dirrección  debe tener menos de 150 caracteres',
        ],
        phone: '',
        phoneRules: [
            (v) => !!v || 'Se requiere el teléfono',
            (v) =>
                v.length <= 10 ||
                'el teléfono  debe tener menos de 10 caracteres',
        ],
        tax: '',
        taxRules: [(v) => !!v || 'Se requiere el código de país'],
        username: '',
        usernameRules: [
            (v) => !!v || 'Se requiere el apellido',
            (v) =>
                v.length >= 3 || 'El apellido debe tener mas de 3 caracteres',
        ],

        errorCounter: 0,

        loadingCities: false,
        loadingRegions: false,
    }),
    created() {
        this.userAux = { ...this.user }
    },
    async mounted() {
        this.loadingRegions = true
        await regionStore.setRegions()
        this.loadingRegions = false
    },

    methods: {
        saveForm() {
            this.$emit('onSaveForm', { ...this.userAux })
        },

        async getCities() {
            if (this.userAux.region != '' && this.userAux.region !== null) {
                this.loadingCities = true

                await regionStore.getCity(this.userAux.region)
                const cities = await regionStore.getCity(this.userAux.region)
                this.cities = cities

                this.loadingCities = false
            }
            return
        },

        emitError(error){
            this.errorCounter += error ? 1 : -1;
            this.$emit('onValidationError', this.errorCounter > 0);
        }
    },

    computed: {
        region() {
            return regionStore.state.regions
        },

        userRegion() {
            return this.userAux?.region || ''
        },

        cod() {
            if (this.region.length > 0) {
                return this.region.map(({ tax, iso2 }) => {
                    const taxClean = tax?.replace(' ', '') || ''
                    return {
                        tax: taxClean,
                        iso2,
                        text: `${iso2} ${tax}`,
                    }
                })
            }
            return []
        },
    },

    watch: {
        async userRegion() {
            await this.getCities()
        },
    },
}
