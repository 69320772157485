import api from '@/services/Api'

class Tickets {
  async getTicketsEventService(idEvent) {
    try {
      const info = await api.get(`/tickets?event=${idEvent}&active=true`);
      return info.data.data
    } catch (error) {
      if (error.response.data.statusCode == '404') return false
      return null
    }
  }
}
const ticketsData = new Tickets()
export default ticketsData
