import MyEstablishment from './myEstablishments/myEstablishments.vue'

export default {
    components: { MyEstablishment },

    data() {
        return {
            tab: null,
        }
    },
}
