import api from '@/services/Api'

export default {
    async getRegions() {
        try {
            const response = await api.get(`/regions`);
            const regions = response.data.data;

            return regions.map(({ name, phone_code, iso2 }) => ({ name, tax: phone_code, iso2 }))
        } catch (error) {
            return [];
        }
    },
    
    async getCities(iso2) {
        try {
            const response = await api.get(`/cities/${iso2}`);
            const cities = response.data.data;
            return cities.map(({ name, _id }) => ({ name, id:_id }))
        } catch (error) {
            return [];            
        }
    },
}
