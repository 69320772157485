import { render, staticRenderFns } from "./termsand.vue?vue&type=template&id=595f704d&scoped=true"
import script from "./termsand.js?vue&type=script&lang=js&external"
export * from "./termsand.js?vue&type=script&lang=js&external"
import style0 from "./termsand.scss?vue&type=style&index=0&id=595f704d&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "595f704d",
  null
  
)

export default component.exports