import MainButton from '@/ui/components/mainButton'
import CategoryList from '@/ui/components/categoryList'
import ImageCard from '@/ui/components/imageCard'
import ticketStore from '@/store/tickets'

export default {
  name: 'EventTickets',

  components: {
    MainButton,
    CategoryList,
    ImageCard,
  },

  data() {
    return {
      avaliableTickets: {},
      eventInfo: false,
    }
  },
  async mounted() {
    try {
      if (!Object.keys(this.$route.params).includes('eventData')) {
        if (this.$router.referer) this.$router.go(-1)
        else this.$router.push('/')
      } else {
        await this.generateTicketData()
      }
    } catch (error) {
      console.log(error)
    }
  },

  methods: {
    async generateTicketData() {
      this.eventInfo = this.$route.params.eventData
      await ticketStore.ticketsByEvent(this.eventInfo._id)
      this.avaliableTickets = ticketStore.state.ticketsEvent.tickets.filter(
        (ticket) => ticket.event._id === this.eventInfo._id,
      )
      this.avaliableTickets = this.avaliableTickets.map((ticket) => ({
        ...ticket,
        toBuyQty: 0,
      }))
      this.avaliableTickets = this.avaliableTickets.reduce(
        (obj, ticket) => {
          if (!ticket.transfer) {
            obj.noTransfer.push(ticket)
          } else {
            obj.transfer.push(ticket)
          }
          return obj
        },
        { noTransfer: [], transfer: [] },
      )
    },
    goToCheckout() {
      this.$router.push({
        name: 'checkoutTickets',
        params: { buyData: this.avaliableTickets },
      })
    },
    calculatePrice(indexTicket, operation, ticketType) {
      if (ticketType === 'transfer') {
        if (operation == '+')
          this.avaliableTickets.transfer[indexTicket].toBuyQty += 1
        else this.avaliableTickets.transfer[indexTicket].toBuyQty -= 1
      } else {
        if (operation == '+')
          this.avaliableTickets.noTransfer[indexTicket].toBuyQty += 1
        else this.avaliableTickets.noTransfer[indexTicket].toBuyQty -= 1
      }
    },
  },

 

  computed: {
    totalPurchase() {
      try {
        let totalTransfer = 0
        this.avaliableTickets.transfer.forEach((obj) => {
          totalTransfer += obj.toBuyQty * obj.price
        })

        let totalNoTransfer = 0
        this.avaliableTickets.noTransfer.forEach((obj) => {
          totalNoTransfer += obj.toBuyQty * obj.price
        })
        return totalNoTransfer + totalTransfer
      } catch {
        return 0
      }
    },
  },
}
