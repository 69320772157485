import Vue from 'vue'
import homeService from '@/services/homeService'

const state = Vue.observable({
    homeData: false,
    noData: true,
})

export default {
    // make state a getter here to provide access to the private state variable
    // only allow getting so that it cannot be modified without calling a mutation method
    get state() {
        return state
    },

    // mutations
    async setHomeData(coords) {
        const response = await homeService.getHomeData(coords)
        if (response) {
            state.homeData = response
            state.noData = false
        } else state.noData = true
    },
    //actions
}
