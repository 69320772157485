import Hotel5 from '../../../../../assets/hotels/hotel5.jpeg'

export default {
    data() {
        return {
            tab: null,

            favorites: [
                {
                    title: 'HOUSE AC1',
                    subtitle: 'Hotel',
                    src: Hotel5,
                },
            ],
        }
    },
}
