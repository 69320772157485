import Vue from 'vue'
import App from './App.vue'
import VueLazyload from 'vue-lazyload'
import vuetify from './plugins/vuetify'
import { keycloak } from './plugins/keycloak'
import { i18n } from './plugins/i18n'

import router from './ui/router/index'
import '@/assets/scss/styles.scss'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import Flicking from '@egjs/vue-flicking'
import '@egjs/vue-flicking/dist/flicking.css'
import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import './registerServiceWorker'
import sessionStore from './store/session'
import routerReferer from '@/plugins/routerReferer'
import {options, stripe} from '@/plugins/stripe'
import userStore from '@/store/user'
import deviceId from '@/domain/deviceId/DeviceId';
import UserRequestNotification from '@/domain/userRequestNotification'
import notificationStore from './store/notification'
const loadimage = '/images/relleno.png'

class main {
    async init() {
        Vue.use(routerReferer)
        Vue.use(stripe, options)
        Vue.use(VueLazyload, {
            preLoad: 1.3,
            loading: loadimage,
            attempt: 1,
        })
        Vue.use(Flicking)
        Vue.component(
            'l-map',
            LMap,
            'l-tile-layer',
            LTileLayer,
            'l-marker',
            LMarker,
        )
        Vue.config.productionTip = true
        
        await sessionStore.setSession()

        const { token, refreshToken } = sessionStore.state
        const keycloakInit = token
            ? {
                  enableLogging: true,
                  token,
                  refreshToken,
                  checkLoginIframe: false,
              }
            : { onLoad: 'login-required' }
        await keycloak.setConfig()
        await keycloak.init(keycloakInit)

        Vue.use(keycloak.Plugin)
        await Vue.$keycloak.loadUserProfile()

        const user = await userStore.setUser(Vue.$keycloak.profile.id);

        await userStore.saveDeviceId(Vue.$keycloak.profile.id);
        
        if(user){
            try {
                const notificaction = new UserRequestNotification(user.id, deviceId.getDeviceId());
                notificaction.validateNotificationsPermission();
            } catch (error) {
                console.log('error', error);
            }

            notificationStore.loadNotificationCount();
        }

        new Vue({
            vuetify,
            router,
            i18n,
            render: (h) => h(App),
        }).$mount('#app')
    }
}

//const token = localStorage.getItem('auth_token')
//const refreshToken = localStorage.getItem('auth_refresh_token')

// Vue.$keycloak
//     .init({ onLoad: 'login-required', token, refreshToken })
//     .then((response) => {
//         console.log('Authenticated')

//         if (!response) {
//             window.location.reload()
//         }
//         //if (response) {
//         //const vueEnv = getEnv()

//         //}
//         localStorage.setItem('auth_token', this.$keycloak.token)
//         localStorage.setItem('auth_refresh_token', this.$keycloak.refreshToken)
//     })
//     .catch((e) => {
//         console.log('authenticated Failed', e)
//     })
const app = new main()
app.init()

//console.log(keycloak)

//authRefreshToken()
