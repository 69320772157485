export default {
    name: 'helpButton',

    props: {
        color: {
            type: String,
            default: 'white',
        },
        width: {
            type: String,
            default: '100%',
        },
        height: {
            type: String,
            default: '40px',
        },
        href: String,
        target: String,
        small: Boolean,
        rounded: Boolean,
        disabled: {
            default: false,
        },
    },
}
