import Vue from 'vue'
import profileService from '@/services/ProfileService'
import favouritesService from '@/services/favouritesService'
import planService from '@/services/planService'
import deviceId from '@/domain/deviceId/DeviceId'

const state = Vue.observable({
    user: null,
    //plans: null,

    pagination: {
        topFavourites: 2,
        favourites: 2,
        plans: 2,
        increments: 2,
        plansPagination:2,
    },

    favourites: {
        trends: [],
        favouritesActivities: [],
        topActivities: [],
        recommendedPlans: []
    },
    plans:{
        loading:true,
        tickets:[],
        
    }
});


export default {
    // make state a getter here to provide access to the private state variable
    // only allow getting so that it cannot be modified without calling a mutation method
    get state(){ return state },

    get pagination() { return state.pagination },

    // mutations
    async setUser(sessionID) {
        try {
            const user = await profileService.getProfile(sessionID);
            state.user = user;
            return user?.id ? user : null;
        } catch (error) {
            return null
        }
    },

    async saveUser(data){
        try {
            if(state.user && state.user.id){    
                state.user = await profileService.updateProfile(data, state.user)
            } else {
               state.user = await profileService.createProfile(data)
               await this.getStripeCustomer()
            }
        } catch (error) {
            throw new Error("Error saving user: " + error)
        }
        
    },
    async getStripeCustomer(){
        return await profileService.getCustomer();
    },
    async setPlans() {
        state.plans = await planService.getPlans();
    },

    async setFavouritesData(id){
        state.favourites.trends = await favouritesService.getTrends();
        state.favourites.favouritesActivities = await favouritesService.getFavourites();
        state.favourites.topActivities = await favouritesService.getFavourites();
        state.favourites.recommendedPlans = await favouritesService.getRecommendedPlans();
    },

    async modifyFavourites(index){
        const favItem = state.favourites.favouritesActivities.at(index);
        
        if(favItem){
            favItem.favourite =  await favouritesService.modifyFavourites(state.user?.id, favItem.id, !favItem.favourite);
        }
    },

    async getPlans(date){
        state.plans = {
            loading:true,
            tickets:[],
        };
        
        const id = state.user?.id || '';
         const {data:{
            invoices:invoices
         }} = (await profileService.getUserApointments(id, date));
         invoices.forEach((invoice)=>{
            if(invoice.kind === 'appointment'){
                state.plans.tickets.push({...invoice, url:`services/${invoice.reference[0].serviceId}`, loading:true })
            }

            if(invoice.kind === 'event'){
                state.plans.tickets.push({...invoice, url:`tickets/${invoice.reference[0].serviceId}`, loading:true })
            }
         })
        state.plans.loading=false;
    },

    populateServices(){
    
       /* state.plans.appointments.forEach((appointment, i)=>{
            profileService.getDataByURl(appointment.url).then((data)=>{
                state.plans.appointments[i].service = data;
                state.plans.appointments[i].loading=false;
            })
        })*/
        state.plans.tickets.forEach((ticket, i)=>{
            profileService.getDataByURl(ticket.url).then((data)=>{
                state.plans.tickets[i].service = data;
                state.plans.tickets[i].loading=false;
            })
        })
    
    },



    async saveDeviceId(userId){ //for push notifications
        try {
            if(!deviceId.getDeviceId()){
                const did = deviceId.generateDeviceId();
                await profileService.saveUserDeviceId(userId, did);
            }
        } catch (error) {
            deviceId.removeDeviceID();
        }
    },

    //actions
    getUser() {
        return state.user
    },
}