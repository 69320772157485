export default {
    name: 'categoryList',

    props: {
        array: null,
        dressCode: Boolean,
    },
    data() {
        return {
            jsonCategory: new Array(),
            showFullCategory: false,
            categoryLength: 3,
        }
    },
    methods: {
        fullCateogry() {
            this.showFullCategory = !this.showFullCategory
        },
    },
    computed: {
        category() {
            this.jsonCategory = []
            if (this.dressCode) {
                for (let i in this.arrayCategory) {
                    var UpperInitial = this.arrayCategory[i]
                        .trim()
                        .replace(/^\w/, (c) => c.toUpperCase())
                    this.jsonCategory.push({ text: UpperInitial })
                }
            } else {
                for (let i in this.arrayCategory) {
                    var UpperInitial = this.arrayCategory[i].name
                        .trim()
                        .replace(/^\w/, (c) => c.toUpperCase())
                    this.jsonCategory.push({ text: UpperInitial })
                }
            }
            return this.jsonCategory
        },
        categoryExceeds() {
            return this.array.length > this.categoryLength
        },
        categoryButtonText() {
            return this.showFullCategory ? 'Menos' : 'Más'
        },
        arrayCategory() {
            if (!this.categoryExceeds) return this.array
            if (this.showFullCategory) return this.array
            else return this.array.slice(0, this.categoryLength)
        },
    },
}
