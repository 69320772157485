import Vue from 'vue'
import eventsService from '@/services/discoEventsService'
import dateClass from '@/domain/dates/dateClass'
import router from '@/ui/router'

const state = Vue.observable({
    eventsData: null,
    favoriteEventsData: null,
    dataAux: null,
    noData: false,
    eventViewData: null,
})

export default {
    // make state a getter here to provide access to the private state variable
    // only allow getting so that it cannot be modified without calling a mutation method
    get state() {
        return state
    },

    // mutations

    async setEvenViewData(idEvent) {
        state.eventViewData = null
        const response = await eventsService.getEventViewData(idEvent)
        if (!response) {
            router.push({
                name: 'Error404',
            })
        } else {
            state.eventViewData = response
            state.dataAux = response
        }
    },

    async setEventsData(id, datesBetween = false) {
        if (datesBetween) {
            if (datesBetween.length == 1) {
                datesBetween[1] = datesBetween[0]
            }
            if (datesBetween[1] == datesBetween[0]) {
                datesBetween[0] = dateClass.toIsoDate(datesBetween[0])
                datesBetween[1] = dateClass.toIsoDate(datesBetween[1], {hour: 19})
            }
        }
        const response = await eventsService.getEventsData(id[0], datesBetween)
        if (!response) {
            state.noData = true
        } else {
            state.eventsData = response
            state.noData = false
        }
    },
    async setFavoriteEventsData(id, datesBetween = false) {
        if (datesBetween) {
            if (datesBetween.length == 1) {
                datesBetween[1] = datesBetween[0]
            }
            if (datesBetween[1] == datesBetween[0]) {
                datesBetween[0] = dateClass.toIsoDate(datesBetween[0])
                datesBetween[1] = dateClass.toIsoDate(datesBetween[1], {hour: 19})
            }
        }
        const response = await eventsService.getEventsData(id[0], datesBetween, true)
        if (!response) {
            state.noData = true
        } else {
            state.favoriteEventsData = response
            state.noData = false
        }
    },
}
