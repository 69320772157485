import Vue from 'vue'
import regionService from '@/services/regionService'

const state = Vue.observable({
    regions: [],
});

export default {
    // make state a getter here to provide access to the private state variable
    // only allow getting so that it cannot be modified without calling a mutation method
    get state(){ return state },

    // mutations
    async setRegions() {
        if(state.regions.length <= 0){
            const regions = await regionService.getRegions();
            state.regions = regions;
        }
        return;
    },
    
    //actions
    async getCity(iso2) {
        return await regionService.getCities(iso2);
    },
}