import searchPlaces from '@/ui/components/searchPlaces'
import searchBarStore from '@/store/search'
import filterSearch from '@/ui/components/filterSearch'
import Bus from '@/plugins/bus'
//import eventStore from '@/store/discoEvents'
import filterStore from '@/store/filter'

export default {
    name: 'searchBar',

    components: {
        searchPlaces,
        filterSearch,
    },

    data() {
        return {
            userCoords: new Array(),
            loading: false,
            geolocationOn: true,
            searchDistance: 20000,
            dateRange: Boolean,
            datesBetween: null,
            restoreData: false,
            existDateRange: false,
        }
    },
    async created() {
        try {
            this.loading = true
            await this.getUserLocation()
            await this.getUserNearbyPlaces()
        } catch (e) {
            console.log(e)
        }
        this.loading = false
        Bus.$on('updateFilter', (data) => {
            this.searchDistance = data.newDistance * 1000
            if (data.changeDate) this.datesBetween = data.newDateRange
        })
        Bus.$on('removeDateFilter', (data) => {
            if (data.removeDateFilter && this.existDateRange)
                this.restoreData = true
            this.existDateRange = false
        })
    },

    methods: {
        getData() {
            return [this.searchDistance, this.userCoords[0], this.userCoords[1]]
        },
        async alcudiacoords() {
            searchBarStore.state.notResults = false
            this.userCoords[0] = 39.69678505358755
            this.userCoords[1] = 2.699869388626506
            await this.getUserNearbyPlaces()
        },
        async getUserNearbyPlaces() {
            try {
                await searchBarStore.setNearbyAllPlaces(this.getData())
            } catch (error) {
                console.log(error)
            }
        },
        async getUserLocation() {
            try {
                const position = await new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject)
                })
                this.userCoords.push(position.coords.latitude)
                this.userCoords.push(position.coords.longitude)
            } catch (error) {
                console.error(error)
                this.geolocationOn = false
            }
        },
    },
    computed: {
        nearbyObj() {
            return searchBarStore.state.nearbyData
        },

        noData() {
            return searchBarStore.state.noData
        },
        changueDistante() {
            return this.searchDistance
        },
        changueDatesBetween() {
            return this.datesBetween
        },
        changueRestoreData() {
            return this.restoreData
        },
    },
    watch: {
        async changueRestoreData() {
            this.restoreData = false
            try {
                this.loading = true
                await this.getUserNearbyPlaces()
            } catch (e) {
                console.log(e)
            }
            this.loading = false
        },
        async changueDistante() {
            this.loading = true
            await this.getUserNearbyPlaces()
            this.loading = false
        },
        async changueDatesBetween() {
            this.existDateRange = true
            this.loading = true
            filterStore.state.dateState = this.datesBetween
            try {
                await searchBarStore.setNearbyAllPlaces(
                    this.getData(),
                    this.datesBetween,
                )
            } catch (error) {
                console.log(error)
            }
            this.loading = false
        },
    },
}
