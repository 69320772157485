import { DateTime, Interval } from 'luxon'

export default {
    name: 'Image-card',

    props: {
        hourData: Array,
    },
    data() {
        return {
            hourPanel: [0, 1],
            hourPanelState: false,
            hourPart1: false,
            hourPart2: false,
            finalSchedule: [],
            noSchedule: false,
        }
    },
    created() {
        this.createSchedule()
    },
    methods: {
        createSchedule() {
            if (this.hourData == undefined) {
                this.noSchedule = true
                return false
            }
            const actualDate = DateTime.local().setLocale('es')
            let arrayHours = this.hourData
            let dayData = Object
            let dayFormat = false
            let sameDay = false
            for (let i in arrayHours) {
                if (actualDate.weekday == parseInt(i) + 1) {
                    sameDay = true
                } else sameDay = false
                dayFormat = actualDate
                    .set({ weekday: parseInt(i) + 1 })
                    .toFormat('EEEE')
                if (arrayHours[i].active) {
                    if (
                        !arrayHours[i].part1.start.length == 0 &&
                        !arrayHours[i].part1.end.length == 0
                    ) {
                        this.hourPart1 = arrayHours[i].part1
                    } else this.hourPart1 = false

                    if (
                        !arrayHours[i].part2.start.length == 0 &&
                        !arrayHours[i].part2.end.length == 0
                    ) {
                        this.hourPart2 = arrayHours[i].part2
                    } else this.hourPart2 = false
                } else {
                    this.hourPart1 = false
                    this.hourPart2 = false
                }
                if (!this.hourPart1 && this.hourPart2) {
                    this.hourPart1 = this.hourPart2
                    this.hourPart2 = false
                }
                dayData = {
                    day: dayFormat,
                    hour1: this.hourPart1,
                    hour2: this.hourPart2,
                    isToday: sameDay,
                }

                this.finalSchedule.push(dayData)
            }
        },
    },
    computed: {
        availability() {
            const today = DateTime.local().setLocale('es')
            const schedule = this.finalSchedule
            let start = false
            let end = false
            let interval = false
            let start2 = false
            let end2 = false
            let interval2 = false
            let start3 = false
            let end3 = false
            let interval3 = false
            let response = ''
            let aux = 0

            for (let i in schedule) {
                if (today.weekday == parseInt(i) + 1) {
                    if (schedule[i].hour1) {
                        start = DateTime.fromFormat(
                            schedule[i].hour1.start,
                            'HH:mm',
                        )
                        end = DateTime.fromFormat(
                            schedule[i].hour1.end,
                            'HH:mm',
                        )
                        if (end.hour < start.hour) {
                            end = end.plus({ days: 1 })
                        }
                        interval = Interval.fromDateTimes(start, end)
                    }
                    if (schedule[i].hour2) {
                        start2 = DateTime.fromFormat(
                            schedule[i].hour2.start,
                            'HH:mm',
                        )
                        end2 = DateTime.fromFormat(
                            schedule[i].hour2.end,
                            'HH:mm',
                        )
                        if (end2.hour < start2.hour) {
                            end2 = end2.plus({ days: 1 })
                        }
                        interval2 = Interval.fromDateTimes(start2, end2)
                    }
                    if (schedule[i].hour1 && schedule[i].hour2) {
                        start3 = DateTime.fromFormat(
                            schedule[i].hour1.end,
                            'HH:mm',
                        )
                        end3 = DateTime.fromFormat(
                            schedule[i].hour2.start,
                            'HH:mm',
                        )
                        if (end3.hour < start3.hour) {
                            end3 = end3.plus({ days: 1 })
                        }
                        interval3 = Interval.fromDateTimes(start3, end3)
                    }

                    if (interval) {
                        if (interval.contains(today)) {
                            if (this.hourPanel == 0)
                                response = ['Abierto ', 'ahora']
                            else if (interval.end.hour == 1)
                                response = [
                                    'Abierto ',
                                    '⋅ Cierra a la ' +
                                        interval.end.toFormat('HH:mm'),
                                ]
                            else
                                response = [
                                    'Abierto ',
                                    '⋅ Cierra a las ' +
                                        interval.end.toFormat('HH:mm'),
                                ]
                        } else {
                            if (interval.isAfter(today)) {
                                response = ['Cerrado '][
                                    ('Cerrado ',
                                    'abre a las ' +
                                        interval.start
                                            .setLocale('es')
                                            .toFormat('HH:mm'))
                                ]
                            } else if (interval.isBefore(today)) {
                                aux = parseInt(i)
                                do {
                                    aux += 1
                                    if (aux == 7) aux = 0
                                } while (!schedule[aux].hour1)
                                response = [
                                    'Cerrado ',
                                    'abre a las ' +
                                        schedule[aux].hour1.start +
                                        ' del ' +
                                        today
                                            .set({ weekday: aux + 1 })
                                            .setLocale('es')
                                            .toFormat('EEE'),
                                ]
                            }
                        }
                    } else {
                        aux = parseInt(i)
                        do {
                            aux += 1
                            if (aux == 7) aux = 0
                        } while (!schedule[aux].hour1)
                        response = [
                            'Cerrado ',
                            'abre a las ' +
                                schedule[aux].hour1.start +
                                ' del ' +
                                today
                                    .set({ weekday: aux + 1 })
                                    .setLocale('es')
                                    .toFormat('EEE'),
                        ]
                    }
                    if (interval3 && interval3.contains(today)) {
                        response = [
                            'Cerrado ',
                            'abre a las ' +
                                interval2.start
                                    .setLocale('es')
                                    .toFormat('HH:mm'),
                        ]
                    }
                    if (interval2) {
                        if (interval2.contains(today)) {
                            if (this.hourPanel == 0)
                                response = ['Abierto ', 'ahora']
                            else if (interval2.end.hour == 1)
                                response = [
                                    'Abierto ',
                                    '⋅ Cierra a la ' +
                                        interval2.end.toFormat('HH:mm'),
                                ]
                            else
                                response = [
                                    'Abierto ',
                                    '⋅ Cierra a las ' +
                                        interval2.end.toFormat('HH:mm'),
                                ]
                        }
                    }
                }
            }

            return response
        },
    },
    filters: {
        upper: function (value) {
            return value.trim().replace(/^\w/, (c) => c.toUpperCase())
        },
    },
}
