import Bus from '@/plugins/bus'
import filterStore from '@/store/filter'
import dateClass from '@/domain/dates/dateClass'

export default {
    props: {
        datePicker: Boolean,
        distance: Boolean,
        height: { default: '50%' },
        removeDateFilter: Boolean,
    },
    data: () => ({
        filterMenu: false,
        group: null,
        min: 1,
        max: 80,
        slider: 20,
        date: [],
        isSameDate: false,
        menu: false,
        modal: false,
        startDay: dateClass.newLocalIsoDate(),
    }),

    created() {
        Bus.$on('searchFilter', () => {
            this.filterMenu = !this.filterMenu
        })
        Bus.$on('removeDateFilter', () => {
            this.date = []
        })
    },
    computed: {
        executeFunctionOnChange() {
            return this.filterMenu
        },
    },
    watch: {
        executeFunctionOnChange() {
            this.updateSearch()
        },
        menu() {
            if (
                this.date.length == 0 &&
                !this.menu &&
                filterStore.state.dateState
            ) {
                this.date[0] = dateClass.dateFromIso(
                    filterStore.state.dateState[0],
                )
                this.date[1] = dateClass.dateFromIso(
                    filterStore.state.dateState[1],
                )
            }
        },
    },
    methods: {
        async updateSearch() {
            let changeStatus = false
            if (filterStore.state.dateState && !this.isSameDate) {
                this.date[0] = dateClass.dateFromIso(
                    filterStore.state.dateState[0],
                )
                this.date[1] = dateClass.dateFromIso(
                    filterStore.state.dateState[1],
                )
                filterStore.state.dateState = this.date
                this.isSameDate = true
            }
            if (!this.filterMenu) {
                this.isSameDate = false
                if (
                    JSON.stringify(filterStore.state.dateState) ===
                        JSON.stringify(this.date) ||
                    this.date.length == 0
                ) {
                    changeStatus = false
                } else {
                    changeStatus = true
                }
                Bus.$emit('updateFilter', {
                    newDistance: this.slider,
                    newDateRange: this.date,
                    changeDate: changeStatus,
                })
            }
        },
        async dateFilterOf() {
            Bus.$emit('removeDateFilter', {
                removeDateFilter: true,
            })
            this.date = []
            filterStore.state.dateState = false
        },
        cleanDate() {
            this.date = []
        },
        checkDates() {
            if (this.date[1]) {
                let aux = ''
                if (
                    dateClass.dateFromIso(this.date[0]) >
                    dateClass.dateFromIso(this.date[1])
                ) {
                    aux = this.date[0]
                    this.date[0] = this.date[1]
                    this.date[1] = aux
                }
            }
        },
    },
}
