import TicketService from '../../../../services/ticketService'
export default {
    data() {
        return {
            tickets: [],
        }
    },
    methods: {
        async TicketService() {
            const response = await TicketService.getTicketService()
            this.tickets = response.data
        },
    },

    created() {
        this.TicketService()
    },
}
