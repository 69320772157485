export default {
    name: 'backButton',

    props: {
        route: {
            type: String,
            default: null,
        },
    },

    methods: {
        goBack() {
            if (this.route) {
                this.$router.push({ name: this.route })
            } else {
                if (this.$router.referer) this.$router.go(-1)
                else this.$router.push('/')
            }
        },
    },
}
