import CreaTicket from './creaTicket/creaTicket.vue'
import ViewTicket from './viewTicket/viewTicket.vue'

export default {
    components: { CreaTicket, ViewTicket },
    data() {
        return {
            tipo: ['VIP', 'Item'],
            Icono: ['VIP', 'Item'],
            Name: '',
            nameRules: [(v) => !!v || 'Se requiere el nombre'],
            establisments: [],
            isMobile: false,
            dialog1: false,
        }
    },

    created() {
        this.isMobile = this.$vuetify.breakpoint.mobile
    },
}
