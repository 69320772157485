import FondoCard from '../../../assets/images/imagenGoOut.jpeg'
// import CaritaFeliz from '../../../assets/images/cara.JPG'
export default {
    data() {
        return {
            // testClass: 'red--text',
            paymentcompleteds: [
                {
                    src: FondoCard,
                },
            ],

            isMobile: false,
        }
    },
    created() {
        this.isMobile = this.$vuetify.breakpoint.mobile
    },
}
