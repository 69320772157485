import CodeInput from 'vue-verification-code-input'
export default {
    name: 'FormConfirmCode',
    components: {
        CodeInput,
    },
    methods: {
        onChange(v) {
            console.log('onChange ', v)
        },
        onComplete(v) {
            console.log('onComplete ', v)
        },
    },
}
