import authModalStore from "@/store/authModal"
import mainButton from "../mainButton";
import keycloakAuth from "@/ui/mixins/keycloakAuth";

export default {

  components: {
    mainButton
  },

  mixins: [keycloakAuth],

  methods: {
    goToLogin(){
      this.login()
    },

    goToRegister(){
      const registerRouteName = 'EditProfile';

      authModalStore.hideModal();
      if(this.$route.name !== registerRouteName)
        this.$router.push({ name: registerRouteName })
    },
    
    goBack(){
      authModalStore.hideModal();
      if(this.$route.name !== 'PrincipalView')
        this.$router.push('/');
    }
  },

  computed: {
    showModal: {
      get(){
        return authModalStore.show;
      },

      set(newValue) {
        newValue ? authModalStore.showModal() : authModalStore.hideModal()
      }
    }
  }
}