import Bus from "@/plugins/bus"
import keycloakAuth from "@/ui/mixins/keycloakAuth"
import notificationStore from "@/store/notification"

export default { 

  mixins: [keycloakAuth],

  data: () => ({
    smallmenu: false,
    group: null,     
  }), 

  async created() {
    await this.$keycloak.loadUserProfile()

    Bus.$on('smallme', () => {
      this.smallmenu = !this.smallmenu
  })
  
  
  },

  methods:{
    goToWallet(){
      this.$router.push({
        name: 'wallet',
        params: { userID: this.id},
    })
    }
  },

  computed:{
    name() {
      if(this.$keycloak.profile){
        return this.$keycloak.profile.firstName+' '+this.$keycloak.profile.lastName
      }
      
      return ''
    },
    email() {
      if(this.$keycloak.profile){
        return this.$keycloak.profile.username
      }
        return 'email'
    },

    id() {
      if(this.$keycloak.profile){
        return this.$keycloak.profile.id
      }
        return 'id'
    },

    emailVerified(){
      return this.$keycloak.profile.emailVerified
    },

    guest(){
      return this.$keycloak.hasResourceRole('guest') === true;
    },

    notificationCount(){
      return notificationStore.count;
    }
  }
}
