export default {
    name: 'FormRecoverPass',
    data() {
        return {
            email: '',
        }
    },

    methods: {
        submit() {
            //  this.$v.$touch()
        },
    },
}
