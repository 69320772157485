import ImageCard from '@/ui/components/imageCard'
import headerStore from '@/store/header'
import nearbyPlacesStore from '@/store/place'
import nearbyPlaces from '@/ui/components/nearbyPlaces'
import ComponentHeader from '@/ui/components/componentHeader'
import MapLeaflet from '@/ui/components/mapLeaflet'

export default {
    name: 'townServices',

    components: {
        ImageCard,
        nearbyPlaces,
        ComponentHeader,
        MapLeaflet,
    },

    data() {
        return {
            townData: {
                name: 'Sóller',
                services: [
                    {
                        name: 'PLAYAS',
                        image: 'https://picsum.photos/id/501/3891/2585.jpg?hmac=JFwrGu7x8k-SIBG5NJUandJSUzT5090CaZianEh4FuM',
                    },
                    {
                        name: 'TOURS',
                        image: 'https://picsum.photos/id/45/4592/2576.jpg?hmac=Vc7_kMYufvy96FxocZ1Zx6DR1PNsNQXF4XUw1mZ2dlc',
                    },
                    {
                        name: 'RESTAURANTES',
                        image: 'https://picsum.photos/id/42/3456/2304.jpg?hmac=dhQvd1Qp19zg26MEwYMnfz34eLnGv8meGk_lFNAJR3g',
                    },
                    {
                        name: 'PLAYAS',
                        image: 'https://picsum.photos/id/501/3891/2585.jpg?hmac=JFwrGu7x8k-SIBG5NJUandJSUzT5090CaZianEh4FuM',
                    },
                    {
                        name: 'TOURS',
                        image: 'https://picsum.photos/id/45/4592/2576.jpg?hmac=Vc7_kMYufvy96FxocZ1Zx6DR1PNsNQXF4XUw1mZ2dlc',
                    },
                    {
                        name: 'RESTAURANTES',
                        image: 'https://picsum.photos/id/42/3456/2304.jpg?hmac=dhQvd1Qp19zg26MEwYMnfz34eLnGv8meGk_lFNAJR3g',
                    },
                ],
            },
            upScreen: 1,
            loading: false,
            coordinates: new Array(),
            arraryNearby: {
                type: Array,
                default: () => [],
            },
            size: '36%',
        }
    },
    async created() {
        this.loading = true
        if (
            this.$route.params.nearbyInfo == undefined &&
            !nearbyPlacesStore.state.coordinatesServicesMap
        ) {
            if (this.$router.referer) this.$router.go(-1)
            else this.$router.push('/')
        } else {
            if (!nearbyPlacesStore.state.coordinatesServicesMap) {
                this.coordinates = [
                    this.$route.params.nearbyInfo[3],
                    this.$route.params.nearbyInfo[4],
                ]
                nearbyPlacesStore.state.coordinatesServicesMap =
                    this.coordinates
            } else
                this.coordinates =
                    nearbyPlacesStore.state.coordinatesServicesMap
            try {
                await nearbyPlacesStore
                    .setNearbyPlaces(this.getData())
                    .then(
                        headerStore.setHeaderText(
                            this.upper(nearbyPlacesStore.state.nearbyName),
                        ),
                    )
                this.arraryNearby = nearbyPlacesStore.state.nearbyData
            } catch (error) {}
            this.loading = false
        }
    },
    methods: {
        getData() {
            return this.$route.params.nearbyInfo
        },
        upper(value) {
            return value.trim().replace(/^\w/, (c) => c.toUpperCase())
        },
        swipe(direction) {
            if (direction == 'Up' && this.upScreen < 2) {
                this.upScreen += 1
            } else if (direction == 'Down' && this.upScreen > 0) {
                this.upScreen -= 1
            }
            switch (this.upScreen) {
                case 0:
                    this.size = '13%'
                    return
                case 1:
                    this.size = '36%'
                    return
                case 2:
                    this.size = '100%'
                    return
            }
        },
    },
    computed: {
        nearbyObj() {
            return nearbyPlacesStore.state.nearbyData
        },
        placeName() {
            return this.upper(nearbyPlacesStore.state.nearbyName)
        },
        noData() {
            if (Object.keys(this.nearbyObj).length === 0) {
                return true
            } else return nearbyPlacesStore.state.noData
        },
    },
}
