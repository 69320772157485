import OfferService from '../../../../services/offerService'

export default {
    data() {
        return {
            offers: [],
        }
    },
    methods: {
        async OfferService() {
            const response = await OfferService.getOfferService()
            this.offers = response.data
        },
    },

    created() {
        this.OfferService()
    },
}
