import Menu from './menu/menu.vue'
import Default from '../../../../public/images/dafault.svg'
//import UsersService from '../../../services/UsersService'
import Shopping from '../profile/shopping/shopping.vue'
import Favorite from '../profile/favorite/favorite.vue'
import Establishment from '../profile/establishment/establishment.vue'

export default {
    components: { Shopping, Favorite, Menu, Establishment },
    data() {
        return {
            users: [],
            texto: [],
            avatars: [
                {
                    src: Default,
                },
            ],
            tab: null,
            items: ['Appetizers', 'Entrees'],
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',

            isMobile: false,
        }
    },

    methods: {
        UsersService() {
            //const response = await UsersService.getUsers()
            this.users = {} //response.data
        },
    },
    created() {
        this.isMobile = this.$vuetify.breakpoint.mobile
        this.UsersService()
    },
}
