import HotelService from '../../../../services/hotelService'

export default {
    name: 'Hotel',

    drawer: null,
    data() {
        return {
            cardsHotel: [],
        }
    },
    methods: {
        async HotelService() {
            const response = await HotelService.getHotelService()
            this.cardsHotel = response.data
        },
    },
    created() {
        this.HotelService()
    },
}
