import MainButton from '@/ui/components/mainButton'
import Carousel from '@/ui/components/carousel'
import MapLeaflet from '@/ui/components/mapLeaflet'
import CategoryList from '@/ui/components/categoryList'
import discothequeStore from '@/store/establishments'
import stablishmentHour from '@/ui/components/stablishmentHour'

export default {
    name: 'Discotheque',

    components: {
        MainButton,
        Carousel,
        MapLeaflet,
        CategoryList,
        stablishmentHour
    },

    data() {
        return {
            descriptionLength: 250,
            showFullDescription: false,
            loading: false,
        }
    },
    async created() {
        try {
            this.loading = true
            await discothequeStore.setEstablishmentData(this.getId())
        } catch (error) {}
        this.loading = false
    },

    methods: {
        goToEvents() {
            this.$router.push({
                name: 'establishmentEvents',
                params: {
                    id: this.getId(),
                    eventInfo: this.getDiscothequeData(),
                },
            })
        },

        getId() {
            return this.$route.params.id
        },

        getDiscothequeData() {
            return new Array(this.discothequeInfo._id)
        },

        fullText() {
            this.showFullDescription = !this.showFullDescription
        },
    },
    filters: {
        upper: function (value) {
            return value.trim().replace(/^\w/, (c) => c.toUpperCase())
        },
    },

    computed: {
        discothequeInfo() {
            return discothequeStore.state.establishmentData
        },
        discothequeDescription() {
            if (!this.descriptionExceeds)
                return this.discothequeInfo.description

            if (this.showFullDescription)
                return this.discothequeInfo.description
            else
                return (
                    this.discothequeInfo.description.slice(
                        0,
                        this.descriptionLength,
                    ) + '...'
                )
        },

        descriptionExceeds() {
            return (
                this.discothequeInfo.description.length > this.descriptionLength
            )
        },

        descriptionButtonText() {
            return this.showFullDescription ? 'VER MENOS' : 'VER MÁS'
        },
        coordinates() {
            return new Array(this.discothequeInfo.lat, this.discothequeInfo.lon)
        },
        googleCoordinates() {
            return `https://www.google.com/maps/search/${this.discothequeInfo.lat},${this.discothequeInfo.lon}`
        },
    },
}
