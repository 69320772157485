import User from '../../../../domain/user/UserClass.js'
export default {
    name: 'FormularioLogin',
    data() {
        return {
            email: '',
            password: '',
            userModel: User.GetModel(),
            formRules: User.GetUserRules(),
        }
    },

    methods: {
        submit() {
            //  this.$v.$touch()
        },
    },
}
