import api from '@/services/Api'
import user from '@/store/user';
import { DeviceUUID } from "device-uuid";
import { DateTime } from 'luxon';

//import dotenv from 'dotenv'
//dotenv.config()
const url = process.env.VUE_APP_API_URL

const millisToDate = (millis, format = 'yyyy-MM-dd') => {
    return DateTime.fromMillis(millis).toFormat(format);
}

const dateToMillis = (millis, format = 'yyyy-MM-dd') => {
    return DateTime.fromFormat(millis, format).toMillis();
}

const todayDate = (format = 'yyyy-MM-dd') => {
    return DateTime.now().toFormat(format);
}

export default {

    async getProfile(sessionID) {
        
        try {
            const response = await api.get(`/profile/userid/${sessionID}`);
            const profile = response.data.data;
            profile.birthday = profile.birthday?millisToDate(profile.birthday):'';
            profile.tax = profile.tax.toString();
            sessionStorage.setItem('userId', profile.id);
            return profile;       
        } catch (error) {
            return  {
                name: '',
                surname: '',
                email: '',
                phone: '',
                tax: '',
                city: '',
                region: '',
                address: '',
                acceptedTerms: false,
                ipTerms: false,
                dateTerms: false,
                privacyTerms: false,
                ipPrivacyTerm: false,
                picture: '',
                birthday: '',
                genre: '',
                datePrivacy: false,
            };                
        }
    },

    async createProfile(profile) {
        const device = new DeviceUUID().get();
        const birthday = dateToMillis(profile.birthday);

        const profileUser = {
            id: device,
            name: profile.name,
            surname: profile.surname,
            email: profile.email,
            phone: parseInt(profile.phone),
            tax: parseInt(profile.tax || 0),
            city: profile.city,
            region: profile.region,
            address: profile.address,
            acceptedTerms: profile.acceptedTerms,
            privacyTerms: profile.privacyTerms,
            picture: profile.picture,
            birthday: birthday,
            genre: profile.genre,
            datePrivacy: dateToMillis(todayDate()),
            userId: profile.userId
        };
        
        const response = await api.post('/profile', profileUser);
        const data = response.data.data;
        
        data.birthday = millisToDate(data.birthday);
        data.tax = data.tax.toString();
        sessionStorage.setItem('userId', data.id);
        return data;
    },

    async updateProfile(profile, oldData) {
        const id = profile.id;
        const newProfileUser = {};
        
        const profileProps = Object.keys(profile);
        profileProps.forEach(prop => {
            if(profile[prop] !== oldData[prop]){
                newProfileUser[prop] = profile[prop]
            }
        });

        if(newProfileUser.tax)
            newProfileUser.tax = parseInt(newProfileUser.tax || 0);

        if(newProfileUser.phone)
            newProfileUser.phone = parseInt(newProfileUser.phone || 0);

        if(newProfileUser.birthday && newProfileUser.birthday.length > 0)
            newProfileUser.birthday = dateToMillis(newProfileUser.birthday);
        const response = await api.patch(`/profile/${id}`, newProfileUser);
        const profileUpdated = response.data.data;
        profileUpdated.tax = profileUpdated.tax.toString();
        profileUpdated.birthday = millisToDate(profileUpdated.birthday);

        return profileUpdated;
    },

    async deleteProfile(profile) {
        return api().delete(`/v1/profile/${id}`).data;
    },

    async getCustomer() {
        return api.get('/payments/get-customer');
    },

    async getUserApointments(id, date){
        const query = date ? `?dateinit=${date}` : '';

        const response = await api.get(`payments/invoice/user/${id}${query}`)
        return response.data;
    },

    async getDataByURl(url){
        let rs={};
        try{
        const {data:data} = await api.get(`${url}`)
        rs=data.data;
        }catch(error){
            rs.error="error getting service";
        }
        return rs
    },

    async saveUserDeviceId(userId, identifier){
        return await api.post('/profile/devices', { userId, identifier: identifier.toString() });
    },
}
