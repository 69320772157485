<template>
  <v-app>
    <div>
      <Preloader />
    </div>
    <!-- <div>
      <Header v-if="!$route.meta.hideNavbar" />
    </div> -->
    <!-- <div>
      <FooterComponentVue v-if="!$route.meta.hideNavbar" />
    </div> -->
    <v-main class="body mt-main-top my-md-0">
      <LayoutComponent>
        <router-view />
      </LayoutComponent>
    </v-main>
    <AuthModal />
    <NotificationHandler />
    <NotificationRequest 
      v-if="userLoggedIn"
      @onRequestAccepted="registerUserNotificationToken()"
    />
  </v-app>
</template>
<script>
import userStore from './store/user'
import UsersService from '@/services/UsersService'
import deviceId from '@/domain/deviceId/DeviceId';
import UserRequestNotification from '@/domain/userRequestNotification'

import Preloader from './ui/preload/preload.vue'
import LayoutComponent from './ui/views/layout/layout-component.vue'
import AuthModal from './ui/components/authModal'
import NotificationHandler from './ui/components/notificationHandler'
import NotificationRequest from './ui/components/notificationRequest'

export default {
    name: 'App',
    components: {
        // FooterComponentVue,
        Preloader,
        //   Header,
        LayoutComponent,
        AuthModal,
        NotificationHandler,
        NotificationRequest
    },

    data: () => ({
        profiles: [],
        userLoggedIn: false,
        //
    }),
    async created() {
        await this.$keycloak.loadUserProfile()
        this.userLoggedIn = userStore.getUser().id ? true : false;
    },

    methods: {
      registerUserNotificationToken(){
        if(this.userLoggedIn){
            const userId = userStore.getUser().id
            const notificaction = new UserRequestNotification(userId, deviceId.getDeviceId());
            notificaction.validateNotificationsPermission();
        }
      },

      async ProfileService() {
            let user = {}
            let sessionUser = {}
            try {
                 sessionUser = await this.$keycloak.loadUserProfile()
                // const response = await ProfileService.getProfile(sessionUser.id)
                // this.profiles = response.data
                // user = await UsersService.GetUser()
            } catch (error) {
                //  this.$router.replace({ path: '/editprofile', replace: true })
                return Promise.resolve()
            } finally {
                user = await UsersService.GetUser()
                user = user.data
            }
            if (!user.email) {
                const data = await UsersService.CreateUser({
                    email: sessionUser.username,
                    username: sessionUser.username,
                    id: sessionUser.id,
                })
                user = data.body
            }
            // if (!this.profiles.id) {
            //   this.profiles = await ProfileService.CreateProfile({
            //     UserID: "user_id",
            //     Name:'name"',
            //     Surname:'surname',
            //     Phone:'phone"',
            //     Tax:'tax"',
            //     City:'city"',
            //     Address:'address"',
            //     Region:'region"',
            //     AcceptedTerms:'accepted_terms"',
            //     IPTerms:'ip_terms"',
            //     PrivacyTerms:'privacy_terms"',
            //     IPPrivacyTerms:'ip_privacy_terms"',
            //     Birthday:'birthday"',
            //     Genre:'gender'
            //   });
            // }

            //localStorage.setItem('Profile', this.profiles[0].complete)
            //console.log(this.profiles)
        },
    },
}
</script>
