import IntervalCalendar from '@/domain/intervalCalendar';

export default {
  data() {
    return {
      intervals: [],
      selectedDate: '',
    }
  },

  mounted(){
    const intervalCalendar = new IntervalCalendar();
    this.intervals = intervalCalendar.getFullInterval();
    
    this.selectedDate = this.intervals[0].value;
    this.emitFilter(this.selectedDate);
  },

  methods: {
    emitFilter(value){
      this.$emit('onSelect', value);
    }
  }
}