import Vue from 'vue'
import establishmentService from '@/services/establishmentService'
import discoEvents from '@/store/discoEvents'
import router from '@/ui/router'

const state = Vue.observable({
    establishmentData: null,
    eventChoosenName: '',
    menuData: null,
})

export default {
    // make state a getter here to provide access to the private state variable
    // only allow getting so that it cannot be modified without calling a mutation method
    get state() {
        return state
    },

    // mutations

    async setEstablishmentData(id, notGo=false) {
        state.establishmentData = null
        discoEvents.state.eventsData = false
        const response = await establishmentService.getEstablishmentData(id)
        let establishmentType = response.type.view.toLowerCase()
        if (!response) {
            router.push({
                name: 'Error404',
            })
        } else if (router.currentRoute.name != establishmentType && !notGo) {
            router.push({
                name: establishmentType,
                params: { id: router.currentRoute.params.id },
            })
        } else {
            state.establishmentData = response
        }
    },

    setEventName(name) {
        state.eventChoosenName = name;
    },

    async setMenuData(id) {
        state.menuData = null
        const response = await establishmentService.getMenuData(id)
        if (!response) {
            state.menuData = false
        } else {
            state.menuData = response
        }
    },

    async getEstablishmentAppointment(id){
        return establishmentService.getAppointment(id);
    }
}
