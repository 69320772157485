import headerStore from '@/store/header'
import userStore from '@/store/user'
import DrawerButton from '@/ui/components/drawerButton'
import NotificationsButton from '@/ui/components/notificationsButton'
import BackButton from '@/ui/components/backButton'
import HelpButton from '@/ui/components/helpButton'

export default {
    name: 'ClientHead',

    components: {
        DrawerButton,
        NotificationsButton,
        BackButton,
        HelpButton
    },

    methods: {
        async refreshPlans(){
            try {
                await userStore.setPlans(); 
            } catch (error) {
              console.log(error);  
            }
        }
    },

    computed: {
        metaInfo() {
            const meta = this.$route.meta || null;
            if(meta){
                return meta.header === undefined || JSON.stringify(meta.header) === '{}' ? null : meta.header
            }
            return null
        },

        defaultHeader() {
            return this.metaInfo === null;
        },

        showBackButton() {
            return this.metaInfo?.backBtn === true;
        },

        showMenuButton() {
            return this.defaultHeader === true || this.metaInfo.menuBtn === true;
        },

        showNotificationsButton() {
            return this.defaultHeader === true || this.metaInfo.notiBtn === true;
        },
        
        showHelpButton() {
            return this.metaInfo?.helpBtn === true;
        },

        showReloadButton() {
            return this.metaInfo?.reloadBtn === true;
        },

        showCallButton() {
            return this.metaInfo?.callBtn === true;
        },

        headerText() {
            if(this.defaultHeader){
                return 'Enjoy Mallorca'
            } else if(this.metaInfo?.headerText !== undefined) {
                return this.metaInfo.headerText;
            } else {
                return this.headerState.text;
            }
        },

        headerState(){
            return headerStore.state;
        }
    }
}
