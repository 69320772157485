import TicketService from '../../../../../../../../services/ticketService'
import EditeTickets from './editeTicket/editeTicket.vue'
export default {
    components: { EditeTickets },
    data() {
        return {
            tickets: [],
            dialog: false,
        }
    },
    methods: {
        async TicketService() {
            const response = await TicketService.getTicketService()
            this.tickets = response.data
        },
    },

    created() {
        this.TicketService()
    },
}
