export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    
    text: {
      type: String,
      default: 'Estamos teniendo problemas para procesar los pagos, estamos trabajando para solucionarlo.'
    },

    timeout: {
      type: Number,
      default: 6000,
    },

    redirect: {
      type: String,
      default: null,
    }
  },

  computed: {
    showSnackbar: {
      get(){
        return this.value;
      },

      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  },

  watch:{
    showSnackbar(newValue){
      if(newValue && this.redirect){
        setTimeout(() => {
          this.$router.push(this.redirect)
        }, this.timeout)
      }
    }
  }
}