export default {
  methods:{
    login() {
      //window.sessionStorage.removeItem('DEV_SESSION')
      window.sessionStorage.removeItem('vue-token')
      window.sessionStorage.removeItem('vue-refresh-token')
      window.sessionStorage.removeItem('userid')
      window.sessionStorage.setItem('login-required', true)
      this.$keycloak.logout()
    },

    logout() {
    //  window.sessionStorage.removeItem('DEV_SESSION')
      window.sessionStorage.removeItem('vue-token')
      window.sessionStorage.removeItem('vue-refresh-token')
      window.sessionStorage.removeItem('userid')
      window.sessionStorage.setItem('login-required', true)
      this.$keycloak.logout()
    },
  }
}