import NextEventsService from '../../../../../services/nexteventService'
import EditEvent from './editEvents/editEvents.vue'

export default {
    components: { EditEvent },
    data() {
        return {
            nextsEvents: [],
            dialog: false,
        }
    },
    methods: {
        async NextEventsService() {
            const response = await NextEventsService.getNextEventsService()
            this.nextsEvents = response.data
        },
    },

    created() {
        this.NextEventsService()
    },
}
