import Events from './event/event.vue'
import Search from './search/search.vue'
import Navbar from './navbar/navbar.vue'

export default {
    components: { Events, Search, Navbar },

    data() {
        return {
            isMobile: false,
        }
    },
    created() {
        this.isMobile = this.$vuetify.breakpoint.mobile
    },
}
