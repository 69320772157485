

export default {
    components: {
        
    },
    data() {
        return {
            frequentquestions: [
                {
                    tickets: 'Mis Tickets ',
                },
                {
                    tickets: 'Mi perfil ',
                },
                {
                    tickets: 'Planes y Eventos',
                },
                {
                    tickets: 'Sobre Enyoy',
                },
                
               
               
            ],
            isMobile: false,
        }
    },
    created() {
        this.isMobile = this.$vuetify.breakpoint.mobile
    },
}
