import dateClass from "@/domain/dates/dateClass";

export default {
  props: {
    openDays: {
      type: Array,
      default: () => [0, 1, 2, 3, 4, 5, 6]
    }
  },

  data: () => ({
    currentDate: {
      day: null,
      month: null,
      year: null,
    },

    selectedDayText: '',

    currentMonth: null,
    currentYear: null,
    events: [],

    today: dateClass.today(),

    yearsLimit: 5,
  }),

  methods: {
    setDay(selection){
      const today = Date.parse(dateClass.today());
      const choosingDate = Date.parse(selection.date);
      
      const setEvent = (name, color) => {
        this.events = [
          { name, color, start: selection.date}
        ]
      }

      if(choosingDate >= today){
        setEvent('Seleccionado', 'black');
        this.selectedDayText = `Tu selección ${selection.day} de ${dateClass.getMonthName(selection.month)}`;

        const date = {
          dateNumberDay: selection.day,
          monthLong: dateClass.getMonthName(selection.month),
          monthNumber: selection.month,
          month: dateClass.getMonthName(selection.month, false),
          showDay: selection.day,
          weekday: selection.weekday,
          year: selection.year
        }
        this.$emit('onSelect', date)
      } else {
        this.$emit('onClear')
        setEvent('No disponible', 'red');
        this.selectedDayText = '';
      }


    },

    setDate ({ start }) {
      const { day, year, month} = this.currentDate;

      if(!day || !year || !month)
        this.setCurrentDate(start.day, start.month, start.year);

    },

    getDateSeparated(date){
      const [year, month, day] = date.split('-', 3).map(n => parseInt(n));

      return { year, month, day };
    },

    setCurrentDate(day, month, year){
      const date = {day, month, year};
      this.currentDate = date;
    }
  },

  computed: {
    currentMonthText(){
      if(this.currentDate.month)
        return dateClass.getMonthName(this.currentDate.month);

      return '';
    },

    canDecreaseMonth(){
      const today = this.getDateSeparated(dateClass.today())
      const actualDate = this.getDateSeparated(this.startDate)

      return today.year < actualDate.year || today.month < actualDate.month;
    },

    startDate:{
      set(newValue){
        const { year, month, day } = this.getDateSeparated(newValue);
        this.setCurrentDate(day, month, year);
      },

      get(){
        const { day, year, month} = this.currentDate;
        if(!day || !year || !month)
          return dateClass.today();
        else
          return `${year}-${month}-${day}`
      }
    },

    nextYears(){
      const { year } = this.getDateSeparated(dateClass.today());
      return [...Array(this.yearsLimit).keys()].map(k => year + k)
    }
  }
}
