import transactionHistory from '@/store/transactionHistory'
import headerStore from '@/store/header'

export default {
    name: 'history',

    data() {
        return {
            offsetTop: 0,
            clientHeight: 0,
            scrollHeight: 0,
            page: 1,
            loading: false,
            //moreItems: false,
        }
    },
    methods: {
        onScroll(e) {
            this.offsetTop = e.target.scrollTop
            this.scrollHeight = e.target.scrollHeight
            this.clientHeight = e.target.clientHeight
            if (this.offsetTop == this.scrollHeight - this.clientHeight && !this.moreItems) {
                console.log('este es el final')
                this.page += 10
                this.getUserHistory(this.page)
        }
        },

       
        async getUserHistory(id) {
            //this.moreItems = true
            //setTimeout(async function () {
                await transactionHistory.setHistory(id)
         //   }, 5000)
        },
    },
    computed: {
        history() {
            return transactionHistory.state.transactionsData
        },
        moreItems(){
            return transactionHistory.state.moreItems
        }
    },
    /*    watch: {
        offsetTop() {
            if (this.offsetTop == this.scrollHeight - this.clientHeight) {
                    console.log('este es el final chamo')
                    this.page += 10
                    this.getUserHistory(this.page)
            }
        },
    }, */
    async created() {
        this.loading = true
        try {
            await this.getUserHistory(this.page)
        } catch (error) {}
        this.loading = false
        this.noData = transactionHistory.state.noData
        headerStore.setHeaderText('Movimientos')
    },
}
