import pushNotification from "@/services/pushNotification";
import AppNotification from "../appNotification";
import { token } from "@/plugins/firebase";

class UserRequestNotification {
  constructor(userId, deviceId){
    this.userId = userId;
    this.deviceId = deviceId;
  }

  async validateNotificationsPermission(){
    const notification = new AppNotification();
    const canNotify = notification.canNotify();
    
    if(canNotify){
      const deviceData = await pushNotification.getDeviceData(this.deviceId);

      const firebaseToken = await token();
      if(deviceData === null || deviceData.token != firebaseToken){
        await pushNotification.setDeviceData(this.userId, this.deviceId, firebaseToken)
      }
    }
  }
}

export default UserRequestNotification;