import headerStore from '@/store/header';

export default {
    name: 'chatDisplay',
    
    data(){
        return {
            chat: {
                sender: 'Victoria Gran Melia',
                messages: [
                    {
                        senderId: 12233,
                        content: 'El bus de las 10 se retrasa hasta las 11:00, disculpe las molestias, consectetur adipiscing elit, sed do.',
                        date: '12:30'
                    },
                    {
                        senderId: 12233,
                        content: 'Lorem ipsum dolor sit amet adipiscing elit.',
                        date: '12:30'
                    },
                    {
                        senderId: 1111,
                        content: 'Consectetur adipiscing elit, labore et dolore magna aliqua.',
                        date: '12:30'
                    },
                    {
                        senderId: 1111,
                        content: 'Lorem ipsum dolor sit amet.',
                        date: '12:30'
                    },
                    {
                        senderId: 12233,
                        content: 'ok ',
                        date: '12:30'
                    },
                    
                ]
            },
            newMessage: '',
        }

    },

    mounted() {
        headerStore.setCallHeaderData({ text: this.chat.sender, number: '33323332222' })
    },

    methods: {
        sendMessage(){
            const newMessage = {
                senderId: 1111,
                content: this.newMessage,
                date: '12:30'    
            }

            this.chat.messages.push(newMessage);
            this.newMessage = '';
        },

        itsMyMessage(senderId){
            const myUserId = 1111;
            return senderId === myUserId;
        }
    },

    computed: {
        messageButtonDisabled(){
           return this.newMessage === ''
        }
    }

}
