import headerStore from "@/store/header"
import paymentStore from "@/store/payments"
import purchase from "@/store/purchase"
import Carousel from '@/ui/components/carousel'
import MainButton from '@/ui/components/mainButton'
import gooutDates from "@/domain/dates/dateClass"


export default {
  name: 'appointmentConfirm',

  components: { Carousel, MainButton },

  data(){
    return {
      loading: false,
      invoiceData: null,
      ticket: null,
      ticketRefreshIntervalId: null,
    }
  },

  async created() {
    headerStore.setHeaderText('Cargando...');
    
    this.loading = true;
    
    try {
      this.invoiceData = await paymentStore.getInvoice(this.$route.params.id);
      headerStore.setHeaderText(this.invoiceData?.stablishment?.name || 'Reserva');
      await this.fetchQr(this.$route.params.id);
    } catch (error) {
      this.$router.push({ name: 'Error404' })
    }

    this.loading = false;
  },

  methods: {
    async confirmPayment(){
      const clientSecret = this.invoiceData.secret;

      const { error } = await this.$stripe.confirmPayment({
        clientSecret,
        redirect: 'if_required'
      });
  
      if( error ){
        alert('error')
      } else {
        await paymentStore.updateInvoiceStatus(this.$route.params.id, 'PAID');
        this.$router.go(this.$router.currentRoute); //updating the page
      }
    },

    async fetchQr(invoiceId){
      if(this.paymentDone){
        const [qr] = await purchase.getQrs(invoiceId);
        this.ticket = { image: qr.qrCode, id: qr._id };
        this.refreshTicket();
      }
    },

    refreshTicket(){
      this.ticketRefreshIntervalId = setInterval(async() => {
        const { qrCode: image, _id: id } = await purchase.refreshQr(this.ticket.id);
        this.ticket = { image, id };
      }, 30000);
    }
  },

  computed: {
    appointmentStatus(){
      return this.invoiceData?.invoice?.options?.appointment || 'ERROR'
    },

    appointmentMessage(){
      const status = this.appointmentStatus;
      switch (status) {
        case 'CREATED':
            return 'Solicitud creada, te notificaremos cuando la acepte el establecimiento.'
        case 'CANCELED':
            return 'Solicitud cancelada, no se hará ningun cobro a tu tarjeta.'
        case 'APPROVED':
            return 'Solicitud aceptada, por el restaurante'
        case 'PAID':
            return 'Solicitud pagada, te esperamos'
        case 'CLOSED':
            return 'Yas has asistido a esta reservación'
        default:
            return 'Hubo un error con tu solicitud'
      }
    },

    paymentStatus(){
      return this.invoiceData?.status || 'requires_payment_method';
    },

    paymentDone(){
      return this.paymentStatus === 'succeeded';
    },

    renderPayment(){
      return this.appointmentStatus === 'APPROVED' && (this.paymentStatus === 'requires_confirmation' || this.paymentStatus === 'requires_action') 
    },

    paymentError(){
      return this.paymentStatus === 'requires_payment_method'
    },

    images(){
      return this.invoiceData?.stablishment?.images || [];
    },

    invoiceTotal(){
      return this.invoiceData?.invoice?.price || 0;
    },

    appointmentDate() {
      return gooutDates.getDateReadableDate(this.invoiceData.invoice.options.appointmentDate);
    }
  },
  
  beforeDestroy(){
    this.ticketRefreshIntervalId = clearInterval(this.ticketRefreshIntervalId);
  }
}
