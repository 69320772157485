
export default {

 
  data: () => ({ 
    isTourOperatorInBlack : false,
    isHotelTitleInBlack: false,
    isProductTitleInBlack: false,

    items: [
      {
        text: 'Touroperador',
        disabled: false,
        href: 'breadcrumbs_dashboard',
      },
      {
        text: 'Hotel',
        disabled: false,
        href: 'breadcrumbs_link_1',
      },
      {
        text: 'Producto',
        disabled: true,
        href: 'breadcrumbs_link_2',
      },
    ],
  }),

  methods:{
    changeTourOperatorTitleBackGroundColor(){
      this.isTourOperatorInBlack = !this.isTourOperatorInBlack
    },    
    changeHotelTitleBackGroundColor(){
      this.isHotelTitleInBlack = !this.isHotelTitleInBlack
    },
    changeProductTitleBackGroundColor(){
      this.isProductTitleInBlack = !this.isProductTitleInBlack
    }  
  }
}