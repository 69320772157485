import { computed } from "vue"

export default {
    name: 'PlaceDescription',
    data() {
        return {
            loading: false,
        }
    },
    async created() {
        try {
            this.loading = true
        } catch (error) {}
        this.loading = false
    },
    methods: {
        getId() {
            return this.$route.params.id
        },
    },
    computed:{
        recomendations(){
            return this.$route.params.recomendations ?? ""
        }
    }
}