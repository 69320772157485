import MainButton from '@/ui/components/mainButton'
export default {
    name: 'foodMenu',
    components: {
        MainButton,
    },
    props: {
        menuInfo: null,
        establishmentName: '',
    },
    data() {
        return {
            dialog: false,
            tabs: null,
            allergenConversion: [
                ['GLUTEN', 'gluten.svg'],
                ['CRUSTACEOS', 'crustaceos.svg'],
                ['HUEVOS', 'huevos.svg'],
                ['PESCADO', 'pescado.svg'],
                ['CACAHUATES', 'cacahuetes.svg'],
                ['SOJA', 'soja.svg'],
                ['LACTEOS', 'lacteos.svg'],
                ['FRUTOS CON CASCARA', 'frutos_cascara.svg'],
                ['APIO', 'apio.svg'],
                ['MOSTAZA', 'mostaza.svg'],
                ['SESAMO', 'sesamo.svg'],
                ['SULFITOS', 'sulfitos.svg'],
                ['ALTRAMUCES', 'altramuces.svg'],
                ['MOLUSCOS', "moluscos.svg"],
            ],
        }
    },
    methods: {},
    computed: {
        allergenToUrl() {
            const arrayAux = this.allergenConversion
            let arrayData = this.menuInfo

            for (let m in arrayData) {
                for (let i in arrayData[m].alergen) {
                    for (let j in arrayAux) {
                        if (arrayData[m].alergen[i] == arrayAux[j][0])
                            arrayData[m].alergen[i] = arrayAux[j][1]
                    }
                }
            }
            return arrayData
        },
        dataMenu() {
            const menuCard = {}
            let categoryNames = []
            this.allergenToUrl.forEach(function (item, index) {
                if (categoryNames.includes(item.category.toLowerCase())) {
                    for (let i in menuCard) {
                        if (menuCard[i].name == item.category.toLowerCase())
                            menuCard[i].items.push(item)
                    }
                } else {
                    menuCard[index] = {
                        name: item.category.toLowerCase(),
                        items: [item],
                    }
                    categoryNames.push(item.category.toLowerCase())
                }
            })
            if (Object.keys(menuCard) == 0) {
                return false
            }
            return menuCard
        },
    },
    filters: {
        upper: function (value) {
            return value.trim().replace(/^\w/, (c) => c.toUpperCase())
        },
    },
}
