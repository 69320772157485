import ImageCard from '@/ui/components/imageCard';
import MainButton from  '@/ui/components/mainButton';
import headerStore from '@/store/header';
import ComponentHeader from '@/ui/components/componentHeader';
import { nextTick } from 'vue';


export default {
    name: 'TourOperador',

    components: {
        ImageCard,
        MainButton,
        ComponentHeader
    },

    data(){
        return {
            tourOperadorData: {
                name: 'Tour Operador',
                services: [
                    {   
                        id: '31231',
                        name: 'House of Son Amar',
                        image: 'https://picsum.photos/id/27/3264/1836.jpg?hmac=p3BVIgKKQpHhfGRRCbsi2MCAzw8mWBCayBsKxxtWO8g',
                    },
                    {   
                        id: '31111',
                        name: 'Cuevas del Drach',
                        image:'https://picsum.photos/id/29/4000/2670.jpg?hmac=rCbRAl24FzrSzwlR5tL-Aqzyu5tX_PA95VJtnUXegGU',
                    },
                    {   
                        id: '9999',
                        name: 'Cuevas del Drach',
                        image:'https://picsum.photos/id/29/4000/2670.jpg?hmac=rCbRAl24FzrSzwlR5tL-Aqzyu5tX_PA95VJtnUXegGU',
                    }
                ],
            },
            actividadPagination: 2,
            paginationIncrements: 2,
        }
    },
    methods: {
        getId(){
            return this.$route.params.id;
        },
        goToReservas(){
            this.$router.push({name:'tourReservas', params: {id: this.getId()}})
        },
        goToTransfer(){
            this.$router.push({name:'tourTransfer', params: {id: this.getId()}})
        },
        applyActividadPagination() {
            if(this.canActividadPaginationLoadMore)
                this.actividadPagination += this.paginationIncrements;
            else 
                this.actividadPagination = this.paginationIncrements;
            this.scroll(this.actividad.slice(-1)[0].id);
        },
        scroll(id, position="center") {
            nextTick(() => {
                const el = this.$refs[id];
                if(el){
                    el[0].scrollIntoView({behavior: 'smooth', block: position});
                }
            })
        }
    },
    computed: {
        canActividadPaginationLoadMore() {
            return this.actividadPagination < this.tourOperadorData.services.length;
        },
        actividadPaginationButtonText() {
            return this.canActividadPaginationLoadMore ? 'Ver Más' : 'Ocultar'
        },
        actividad() {
            return this.tourOperadorData.services.slice(0, this.actividadPagination);
        },
    },
    created(){
        headerStore.setHeaderText(this.tourOperadorData.name)
    }
}
