export default {
    data() {
        return {
            termsandConditions: [
                {
                    title: 'Condiciones de uso',
                    description:
                        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam est tortor, dapibus sed vehicula in, semper in quam. Mauris congue nec urna vel imperdiet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam est tortor, dapibus sed vehicula in, semper in quam. Mauris congue nec urna vel imperdiet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam est tortor, dapibus sed vehicula in, semper in quam. Mauris congue nec urna vel imperdiet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam est tortor, dapibus sed vehicula in, semper in quam. Mauris congue nec urna vel imperdiet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam est tortor, dapibus sed vehicula in, semper in quam. Mauris congue nec urna vel imperdiet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam est tortor, dapibus sed vehicula in, semper in quam. Mauris congue nec urna vel imperdiet. ',
                },
            ],
            isMobile: false,
        }
    },
    created() {
        this.isMobile = this.$vuetify.breakpoint.mobile
    },
}
