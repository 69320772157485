import Keycloak from 'keycloak-js'
import Vue from 'vue'
// todo ennv auth route

class KeyCloakAuth {
    Plugin = {}
    _keycloak = {}
    config = {
        url: process.env.AUTH_URL,
        realm: 'enjoy',
        clientId: 'webapp',
    }
    async init(init) {
        // this._keycloak = new Keycloak()
        try {
            const auth = await this._keycloak.init(init)
            if (!auth) {
                this.removeTokens()
                window.reload()
            } else {
                this._keycloak.onTokenExpired = async () => {
                    console.log('ttl session')
                    await this._keycloak.updateToken(60)
                    this.saveTokens()
                }
                await this._keycloak.loadUserInfo()
                this.saveTokens()
            }
        } catch (e) {
            this.removeTokens()
            location.reload()
        }
    }

    async setConfig() {
        this._keycloak = new Keycloak(this.config)
        this.setPlugin(this._keycloak)
    }

    setPlugin(keycloak) {
        this.Plugin = {
            install: (Vue) => {
                Vue.$keycloak = keycloak
            },
        }
        this.Plugin.install = (Vue) => {
            Vue.$keycloak = keycloak
            Object.defineProperties(Vue.prototype, {
                $keycloak: {
                    get() {
                        return keycloak
                    },
                },
            })
        }
    }

    authRefreshToken() {
        return setTimeout(() => {
            Vue.$keycloak.updateToken(60).then((refreshed) => {
                if (!refreshed) {
                    console.log(
                        'Token not refreshed, valid for ' +
                            Math.round(
                                Vue.$keycloak.tokenParsed.exp +
                                    Vue.$keycloak.timeSkew -
                                    new Date().getTime() / 1000,
                            ) +
                            ' seconds',
                    )
                }
            })
        })
    }

    removeTokens() {
        console.log('remove tokens')
        window.sessionStorage.removeItem('vue-token')
        window.sessionStorage.removeItem('vue-realm')
        window.sessionStorage.removeItem('vue-refresh-token')
    }

    saveTokens() {
        window.sessionStorage.setItem('vue-realm', this._keycloak.realm)
        window.sessionStorage.setItem('vue-token', this._keycloak.token)
        window.sessionStorage.setItem(
            'vue-refresh-token',
            this._keycloak.refreshToken,
        )
    }

    isTokenExpired() {
        return this._keycloak.isTokenExpired(60)
    }

    async updateTokenIfExpired() {
        try {
            const tokenExpired = this.isTokenExpired()
    
            if(tokenExpired){
                await this._keycloak.updateToken(60)
                this.saveTokens()
            }
        } catch { //is session expired an error is thrown
            //this.removeTokens()
            //location.reload()
        }
    }
}

export const keycloak = new KeyCloakAuth()
