import Vue from 'vue'
import paymentService from '@/services/paymentService'

const state = Vue.observable({
});

export default {
    // make state a getter here to provide access to the private state variable
    // only allow getting so that it cannot be modified without calling a mutation method
    get state(){ return state },

    // mutations
    async getTicketsByInvoiceID(id) {
        return await paymentService.checkPaymentStatusService(id)

    },
    
    async getTicketsByPurchaseId(id) {
        return paymentAux
    },

    async checkPaymentStatus(invoiceID) {
        return await paymentService.checkPaymentStatusService(invoiceID)
    },

    async makeQr(referenceId, serviceId, invoiceId){
        return await paymentService.makeQR(referenceId, serviceId, invoiceId)
    },

    async getQrs(invoiceId){
        return await paymentService.getQrs(invoiceId)
    },

    async refreshQr(ticketId){
        return await paymentService.refreshQr(ticketId)
    }
}