export default {
    data() {
        return {
            isMobile: false,
        }
    },
    created() {
        this.isMobile = this.$vuetify.breakpoint.mobile
    },
}
