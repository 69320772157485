import { render, staticRenderFns } from "./index.vue?vue&type=template&id=24ee8f22&scoped=true"
import script from "./townServices.js?vue&type=script&lang=js&external"
export * from "./townServices.js?vue&type=script&lang=js&external"
import style0 from "./townServices.scss?vue&type=style&index=0&id=24ee8f22&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24ee8f22",
  null
  
)

export default component.exports