export default {
    name: 'carouselPlaces',

    props: {
        images: {
            required: true,
        },

        startHeight: {
            type: Number,
            default: 230,
        },

        heightIncrements: {
            type: Number,
            default: 100,
        },
        showArrows: Boolean,
        hideDelimiters: Boolean,
        backButton: Boolean,
    },
    methods: {
        goBack() {
            if (this.$router.referer) this.$router.go(-1)
            else this.$router.push('/')
        },
    },
    computed: {
        imageArray() {
            if (typeof this.images == 'string') {
                return [this.images]
            } else return this.images
        },
        height() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return this.startHeight
                case 'sm':
                    return this.startHeight + this.heightIncrements
                case 'md':
                    return this.startHeight + this.heightIncrements * 2
                case 'lg':
                    return this.startHeight + this.heightIncrements * 3
                case 'xl':
                    return this.startHeight + this.heightIncrements * 4
            }
        },
    },
}
