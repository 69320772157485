export default {
  name: 'cardSelectGroup',

  props: {
    value: {
      type: String,
      required: true,
    },

    cardList: {
      type: Array,
      required: true,
    },

    hideNewCard: Boolean,
    loading: Boolean,
  },

  data(){
    return {
      radioGroup: 0,
    }
  },

  mounted(){
    this.autoSelectCard()
  },

  methods: {
    autoSelectCard(){
      if(this.cardList.length > 0){
        this.selectedCard = this.cardList[0].id;
      } else {
        this.selectedCard = 'newCard';
      }
    }
  },

  computed: {
    selectedCard: {
      set(newValue) {
        this.$emit('input', newValue);
      },

      get(){
        return this.value;
      }
    }
  },

  watch: {
    cardList(){
      this.autoSelectCard();
    }
  }
}