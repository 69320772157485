import api from '@/services/Api'

class PaymentData {
  async initCardPaymentItentService(customer, amount, idCard, confirm = true, adquireOnly = false) {
    const payload = {
      currency: 'EUR',
      customer: customer,
      amount: amount,
      offSession: true,
      paymentMethod: idCard,
      confirm,
      adquireOnly,
    }

    if (idCard.length == 0) {
      delete payload.paymentMethod
      payload.confirm = false
      payload.offSession = true
      payload.setupFutureUsage = 'off_session'
    }
   
    const info = await api.post(`/payments/init-payment`, payload)
    return info.data.data
  }

  async initPaymentIntentForApointments(customer, amount, paymentMethodId) {
    const payload = {
      currency: 'EUR',
      customer: customer,
      amount: amount,
      paymentMethod: paymentMethodId,
      confirm: false,
      offSession: true,
      setupFutureUsage: 'off_session',
    }

    const info = await api.post(`/payments/init-payment`, payload)
    return info.data.data
  }

  async paymentInvoiceService(amount, type, idPaymentIntent, referenceObj, options, noModifyReference = false) {
    const payload = {
      paymentIntent: idPaymentIntent, //'id del payment intent',
      price: amount, //'precio comprado',
      kind: type, //'tipo de evento, event o service o appoinment',
      reference: noModifyReference ? referenceObj : newReferenceObj(referenceObj), // "array de objetos con {id: 'id de ticket',qty:'cantidad de tickets',price: 'precio unitario de tickets'    }",
      //options //{ appoinment: ano:mes:dia:hour: hh:mm:00 } timezone: utc, only for appoinment
    }
    if(options){
      payload.options = options
    }
    
    const info = await api.post(`/payments/invoice`, payload)
    return info.data.data.invoice
  }

  async checkPaymentStatusService(invoiceID) {
    try {
      const info = await api.get(`/payments/invoice/${invoiceID}`)
      return info.data.data
    } catch (error) {
      if (error.response.data.statusCode == '404') return false
      return null
    }
  }

  async getInvoice(invoiceId){
    return (await api.get(`/payments/invoice/${invoiceId}`)).data.data;
  }

  async getIntent(paymentIntentId){
    return (await api.get(`/payments/intent/${paymentIntentId}`)).data;
  }

  async makeQR(referenceId, serviceId, invoiceId){
    return ( await api.post('qr-codes', { id: referenceId, serviceId, invoiceId, "@type": 'service' } )).data;
  }

  async getQrs(invoiceId){
    return (await api.get(`/qr-codes/invoice/${invoiceId}`)).data.data.qrcodes;
  }

  async refreshQr(ticketId){
    return (await api.get(`/qr-codes/${ticketId}`)).data;
  }

  async updateInvoiceStatus(invoiceId, status){
    return await api.get(`/payments/invoice/${invoiceId}/status/${status}`)
  }

  async confirmAction(intentId) {
    try {
      const { data } = await api.get(`payments/confirm-payment/${intentId}`);
      return data.data;
    } catch ({ response }) {
      return { error: true, message: response.data?.error }
    }
  }
}

const newReferenceObj = (references) => {
  let ref = [];
  references.forEach( async (reference) => {
    for(let i = 0; i < parseInt(reference.qty); i++){
      ref.push({...reference, qty: 1 });
    }
  });

  return ref;
}

const paymentData = new PaymentData()

export default paymentData
