import CreaDrinks from './creatDrinks/creatDrinks.vue'
import ViewDrinks from './viewDrinks/viewDrinks.vue'

export default {
    components: { CreaDrinks, ViewDrinks },
    data() {
        return {
            tipo: ['VIP', 'Item'],
            Icono: ['VIP', 'Item'],
            Name: '',
            nameRules: [(v) => !!v || 'Se requiere el nombre'],
            establisments: [],
            isMobile: false,
            dialog1: false,
        }
    },

    created() {
        this.isMobile = this.$vuetify.breakpoint.mobile
    },
}
