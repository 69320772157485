import Vue from 'vue'

const state = Vue.observable({
    text: '',
    callNumber: ''
});

export default {
    get state(){ return state },

    //mutuations
    setHeaderText(newText){
        state.text = newText;
    },

    setCallHeaderData({ text, number }) {
        state.text = text;
        state.callNumber = number;
    },

    //actions
    getHeaderText(){
        return state.text;
    },

    getCallNumber(){
        return state.callNumber;
    },
}