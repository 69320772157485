import { render, staticRenderFns } from "./promotions.vue?vue&type=template&id=04629f1b&scoped=true"
import script from "./promotions.js?vue&type=script&lang=js&external"
export * from "./promotions.js?vue&type=script&lang=js&external"
import style0 from "./promotions.scss?vue&type=style&index=0&id=04629f1b&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04629f1b",
  null
  
)

export default component.exports