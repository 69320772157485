export class User {
    id = ''
    username = ''
    password = ''
    name = ''
    phone = ''
    address = ''
    email = ''

    constructor(id, username, password, name, phone, address) {
        this.id = id
        this.username = username
        this.password = password
        this.name = name
        this.phone = phone
        this.address = address
    }
    GetModel() {
        return {
            id: this.id,
            username: this.username,
            password: this.password,
            name: this.name,
            phone: this.phone,
            address: this.address,
        }
    }
    GetUserRules() {
        return {
            username: [(v) => !!v || 'Username is required'],
            Password: [(v) => !!v || 'Password is required'],
        }
    }
}
export default new User()
