import userStore from '@/store/user'
import { Flicking } from '@egjs/vue-flicking';
import "@egjs/vue-flicking/dist/flicking.css"
import ImageCard from '@/ui/components/imageCard';
import ComponentHeader from '@/ui/components/componentHeader';
import { nextTick } from 'vue';

export default {
    name: 'Favourites',
    
    components: {
        ImageCard,
        ComponentHeader,
        Flicking,
    },

    async created() {
        try {
            await userStore.setFavouritesData();
        } catch (error) {
            console.log(error);            
        }
    },

    methods: {
        modifyFavourites(index){
            try {
                userStore.modifyFavourites(index);
            } catch (error) {
                console.log(error);
            }
        },

        applyFavouritesPagination() {
            if(this.canFavouritesPaginationLoadMore)
                this.pagination.favourites += this.pagination.increments;
            else 
                this.pagination.favourites = this.pagination.increments;
            
            this.scroll(this.favouritesActivities.slice(-1)[0].id);
        },

        applyPlansPagination() {
            if(this.canPlansPaginationLoadMore)
                this.pagination.plans += this.pagination.increments;
            else 
                this.pagination.plans = this.pagination.increments;
            
            this.scroll(this.plans.slice(-1)[0].id);
        },

        scroll(id, position="center") {
            nextTick(() => {
                const el = this.$refs[id];
                
                if(el){
                    el[0].scrollIntoView({behavior: 'smooth', block: position});
                }
            })
        }
    },

    computed: {
        pagination(){
            return userStore.state.pagination;
        },
        
        favoutires(){
            return userStore.state.favourites;
        },

        trends() {
            return this.favoutires.trends;
        },

        favouritesActivities() {
            return this.favoutires.favouritesActivities.slice(0, this.pagination.favourites);
        },

        plans() {
            return this.favoutires.recommendedPlans.slice(0, this.pagination.plans);
        },

        canFavouritesPaginationLoadMore() {
            return this.pagination.favourites < this.favoutires.favouritesActivities.length;
        },

        canPlansPaginationLoadMore() {
            return this.pagination.plans < this.favoutires.recommendedPlans.length;
        },

        favouritesPaginationButtonText() {
            return this.canFavouritesPaginationLoadMore ? 'Ver Más' : 'Ocultar'
        },

        plansPaginationButtonText() {
            return this.canPlansPaginationLoadMore ? 'Ver Más' : 'Ocultar'
        }
    },
}
