var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"goout-container"},[_c('v-row',{staticClass:"square-view",attrs:{"dense":""}},_vm._l((_vm.NearbyObj),function(nearbyPlace,index){return _c('v-col',{key:nearbyPlace.id,attrs:{"cols":"4","sm":"3","md":"3","lg":"2"}},[_c('image-card',{attrs:{"image":nearbyPlace.haveChilds
                        ? nearbyPlace.image
                        : nearbyPlace.images[0],"title":nearbyPlace.haveChilds
                        ? index.toUpperCase()
                        : nearbyPlace.name.toUpperCase(),"aspect-ratio":1 / 1,"expand-value":"1.01","subtitle":nearbyPlace.haveChilds
                        ? _vm.numberSites(nearbyPlace)
                        : false},on:{"onClick":function($event){nearbyPlace.haveChilds
                        ? _vm.showHide(nearbyPlace.id)
                        : _vm.goToNearby(nearbyPlace)}}}),(nearbyPlace.haveChilds)?_c('v-dialog',{attrs:{"fullscreen":"","scrim":false,"transition":"slide-fade"},model:{value:(nearbyPlace.show),callback:function ($$v) {_vm.$set(nearbyPlace, "show", $$v)},expression:"nearbyPlace.show"}},[_c('v-card',{staticClass:"py-14"},[_c('v-row',{staticClass:"d-flex"},[_vm._l((nearbyPlace),function(place,index){return _c('v-col',{key:index,staticClass:"pa-1",attrs:{"cols":"12","md":"4","sm":"6"}},[_c('image-card',{staticClass:"child-items",attrs:{"image":nearbyPlace.isEvent ? place.image : place.images[0],"title":place.name.toUpperCase(),"aspect-ratio":16 / 7,"expand-value":"1.01","width":"100%"},on:{"onClick":function($event){return _vm.goToNearby(place, true)}}})],1)}),_c('div',{staticClass:"d-flex justify-center pa-1",staticStyle:{"position":"fixed","bottom":"56px"},attrs:{"height":"40px"}},[_c('main-button',{staticStyle:{"position":"fixed","bottom":"56px","left":"15px"},attrs:{"width":"90%"},on:{"click":function($event){return _vm.showHide(nearbyPlace.id)}}},[_vm._v(" Regresar ")])],1)],2)],1)],1):_vm._e()],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }