import { Settings, DateTime } from 'luxon'

class IntervalCalendar {
  constructor(monthInterval = 3, yearsInterval = 1, monthMessagePrefix = "Últimos", monthsLimit = 9, yearsLimit = 5){
    this.monthInterval = monthInterval;
    this.yearsInterval = yearsInterval;
    this.monthMessagePrefix = monthMessagePrefix;
    this.monthsLimit = monthsLimit;
    this.yearsLimit = yearsLimit;
    this.today = DateTime.now();
  }

  getMonthsInterval(){
    const calendar = [];

    for(let i = this.monthInterval; i <= this.monthsLimit; i += this.monthInterval){
      const currentDate = this.today.minus( { month: i });

      const formatedDate = DateTime.utc(currentDate.year, currentDate.month, currentDate.day, 0, 0, 0, 0).toISO();
      const stringDate = `${this.monthMessagePrefix} ${ i } meses`;

      calendar.push({
        value: formatedDate,
        text: stringDate,
      })
    }

    return calendar;
  }

  getYearsInterval(){
    const calendar = [];
    const startDate = this.today.plus({ year: 1 });

    for(let i = this.yearsInterval; i <= this.yearsLimit; i += this.yearsInterval){
      const currentDate = startDate.minus( { years: i });

      const formatedDate = DateTime.utc(currentDate.year, currentDate.years, currentDate.day, 0, 0, 0, 0).toISO();
      const stringDate = `${currentDate.year}`;

      calendar.push({
        value: formatedDate,
        text: stringDate,
      })
    }
    return calendar;
  }

  getFullInterval(){
    return [...this.getMonthsInterval(), ...this.getYearsInterval()]
  }
}

export default IntervalCalendar;