import DrinksService from '../../../../services/drinkService'
export default {
    name: 'Consumptions',
    data() {
        return {
            drinks: [],
        }
    },
    methods: {
        async DrinksService() {
            const response = await DrinksService.getDrinksService()
            this.drinks = response.data
        },
    },

    created() {
        this.DrinksService()
    },
}
