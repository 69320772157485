import Vue from 'vue'
import ticketService from '@/services/ticketService'

const state = Vue.observable({
  ticketsEvent: {},
})

export default {
  get state() {
    return state
  },

  async ticketsByEvent(idEvent) {
    const response = await ticketService.getTicketsEventService(idEvent)
    console.log(response)
    state.ticketsEvent = response
  },
}
