import MainButton from '@/ui/components/mainButton'

import userStore from '@/store/user'
import cardStore from '@/store/creditCard'
import headerStore from '@/store/header'

export default {
    name: 'wallet',

    components: {
        MainButton,
    },

    data() {
        return {
            noData: false,
            loading: false,
            valid: true,
            amounFunds: '0',
            amounFundsRules: [
                (v) => /^[0-9]+$/.test(v) || 'Cantidad no válida',
            ],
            deleteDialog: false,
            IdtoDelete: null,
            addFunds: false,
            totalFunds:0,
            skeletonItems:[
                {
                    height: '70px',
                    class: 'mt-6',
                    type: 'image'
                },
                {
                    height: '50px',
                    class: 'mt-4',
                    type: 'image'
                },
                {
                    height: '50px',
                    class: 'mt-8',
                    type: 'heading'
                },
                {
                    height: '50px',
                    class: 'mt-4',
                    type: 'image'
                },
                {
                    height: '50px',
                    class: 'mt-4',
                    type: 'image'
                },
                {
                    height: '50px',
                    class: 'mt-4',
                    type: 'image'
                },
            ]
        }
    },
    async created() {
        this.loading = true
        try {
            await cardStore.setCards(this.IdUser)
        } catch (error) {}
        this.loading = false
        this.noData = cardStore.state.noData
        headerStore.setHeaderText('Mi Wallet')
    },
    methods: {
        goToHistory(){
            this.$router.push({
                name: 'history',
                //params: { userID: this.getId()},
            })
        },

        openDeleteDialog(index) {
            this.IdtoDelete = index
            this.deleteDialog = true
        },
        async deleteCard() {
            await cardStore.deleteCard(this.IdUser, this.IdtoDelete)
            await cardStore.setCards(false)
            this.noData = cardStore.state.noData
            this.deleteDialog = false
        },
        calculateFunds(){
            this.totalFunds += Number(this.amounFunds)
            this.amounFunds = '0'
            this.addFunds = false
        }
    },
    filters: {
        upper: function (value) {
            return value.trim().replace(/^\w/, (c) => c.toUpperCase())
        },
    },
    computed: {
        cardsData() {
            return cardStore.state.cardsData
        },
    },
}
