import MainButton from '@/ui/components/mainButton'
import Carousel from '@/ui/components/carousel'
import CategoryList from '@/ui/components/categoryList'
import beachStore from '@/store/place'
import MapLeaflet from '@/ui/components/mapLeaflet'

export default {
    name: 'Beach',

    components: {
        Carousel,
        CategoryList,
        MainButton,
        MapLeaflet
    },

    data() {
        return {
            descriptionLength: 250,
            showFullDescription: false,
            loading: false,
        }
    },
    filters: {
        upper: function (value) {
            return value.trim().replace(/^\w/, (c) => c.toUpperCase())
        },
    },
    methods: {
        goToServices() {
            if (beachStore.state.nearbyMapMarkers){
                beachStore.state.coordinatesServicesMap = false
                beachStore.state.nearbyMapMarkers = false
            }
            this.$router.push({
                name: 'beachServices',
                params: { id: this.getId(), nearbyInfo: this.getNearbyData() },
            })
        },
        goToEvents() {
            this.$router.push({
                name: 'placeEvents',
                params: {
                    id: this.getId(),
                    queryId: this.beachData._id
                },
            })
        },
        goToIcons(){
            this.$router.push({
                name: `whatsUp`,
                params: { id: this.getId(), icons: this.beachData.icons.beach.features },
            })
        },
        goToRecomendations() {
            this.$router.push({
                name: `placeRecomendations`,
                params: { id: this.getId(), recomendations: this.beachData.recomendations},
            })
        },
        getNearbyData() {
            let distance = 0
            if (this.beachData.options[0] == undefined)
                distance = parseInt(this.beachData.options.distance)
            else distance = parseInt(this.beachData.options[0]['value'])
            return new Array(
                distance,
                this.beachData.id,
                this.beachData.name,
                this.beachData.lat,
                this.beachData.lon,
            )
        },
        getId() {
            return this.$route.params.id
        },
        fullText() {
            this.showFullDescription = !this.showFullDescription
        },
    },
    async created() {
        try {
            this.loading = true
            await beachStore.setPlaceData(this.getId())
        } catch (error) {}
        this.loading = false
    },
    computed: {
        beachData() {
            return beachStore.state.placeData
        },
        coordinates() {
            return new Array(this.beachData.lat, this.beachData.lon)
        },
        beachDescription() {
            if (!this.descriptionExceeds) return this.beachData.description

            if (this.showFullDescription) return this.beachData.description
            else
                return (
                    this.beachData.description.slice(
                        0,
                        this.descriptionLength,
                    ) + '...'
                )
        },

        descriptionExceeds() {
            return this.beachData.description.length > this.descriptionLength
        },

        descriptionButtonText() {
            return this.showFullDescription ? 'VER MENOS' : 'VER MÁS'
        },
        coordinates() {
            return new Array(this.beachData.lat, this.beachData.lon)
        },
        googleCoordinates() {
            return `https://www.google.com/maps/search/${this.beachData.lat},${this.beachData.lon}`
        },
    },
}
