import MainButton from '@/ui/components/mainButton'
import CardSelectGroup from '@/ui/components/cardSelectGroup'
import discothequeStore from '@/store/establishments'
import headerStore from '@/store/header'
import cardStore from '@/store/creditCard'
import userStore from '@/store/user'

export default {
  name: 'checkoutTickets',

  components: {
    MainButton,
    CardSelectGroup,
  },

  data() {
    return {
      hours: new Array(),
      buyData: {},
      stripeUser: null,
      methodPaymentSelectedId: '',
    }
  },
  async created() {
    headerStore.setHeaderText(this.upper(this.eventName))
    if (!this.$route.params.buyData) {
      this.$router.push('/')
    } else {
      try {
        this.getBuyData()
        await cardStore.setCards()
      } catch (error) {}
      this.stripeUser = await userStore.getStripeCustomer()
    }
  },

  methods: {
    upper(value) {
      return value.trim().replace(/^\w/, (c) => c.toUpperCase())
    },
    getBuyData() {
      this.buyData.noTransfer = this.$route.params.buyData.noTransfer.filter(
        (ticket) => ticket.toBuyQty > 0,
      )
      this.buyData.transfer = this.$route.params.buyData.transfer.filter(
        (ticket) => ticket.toBuyQty > 0,
      )
    },
    calculatePrice(indexTicket, operation, ticketType) {
      if (ticketType === 'transfer') {
        if (operation == '+') this.buyData.transfer[indexTicket].toBuyQty += 1
        else this.buyData.transfer[indexTicket].toBuyQty -= 1
      } else {
        if (operation == '+') this.buyData.noTransfer[indexTicket].toBuyQty += 1
        else this.buyData.noTransfer[indexTicket].toBuyQty -= 1
      }
    },
    goToPayment() {
      const idCard =
        this.methodPaymentSelectedId === 'newCard'
          ? ''
          : this.methodPaymentSelectedId

      const ticketDataObj = [
        ...this.buyData.noTransfer,
        ...this.buyData.transfer,
      ].map((obj) => ({
        serviceId: obj.id,
        qty: obj.toBuyQty,
        price: obj.price,
      }))

      this.$router.push({
        name: 'Payment',
        params: {
          customer: this.stripeUser.data.data.customer,
          amount: this.billTotal,
          idCard,
          type: 'event',
          referenceObj: ticketDataObj,
          options: false
        },
      })
    },
  },

  computed: {
    discoName() {
      return discothequeStore.state.establishmentData.name
    },

    eventName() {
      return discothequeStore.state.eventChoosenName
    },

    billResume() {
      try {
        let totalTransfer = 0
        this.buyData.transfer.forEach((obj) => {
          totalTransfer += obj.toBuyQty * obj.price
        })

        let totalNoTransfer = 0
        this.buyData.noTransfer.forEach((obj) => {
          totalNoTransfer += obj.toBuyQty * obj.price
        })
        return { totalNoTransfer, totalTransfer }
      } catch {
        return 0
      }
    },
    totalTickets() {
      try {
        let totalTransfer = 0
        this.buyData.transfer.forEach((obj) => {
          totalTransfer += obj.toBuyQty
        })

        let totalNoTransfer = 0
        this.buyData.noTransfer.forEach((obj) => {
          totalNoTransfer += obj.toBuyQty
        })
        return { totalNoTransfer, totalTransfer }
      } catch {
        return 0
      }
    },
    userCards() {
      if (cardStore.state.cardsData) {
        return cardStore.state.cardsData
      }
      return []
    },
    noCardsData() {
      return cardStore.state.noData
    },
    billTotal() {
      try {
        return this.billResume.totalNoTransfer + this.billResume.totalTransfer
      } catch {
        return 0
      }
    },
  },
}
