export default {
    name: 'notificationsButton',
    
    methods: {
        goToNotifications(){
            if(this.$route.name !== 'Notifications')
                this.$router.push({ name: 'Notifications' })
        }
    }
}
