import Vue from 'vue'
import placeDataService from '@/services/placeDataService'
import placeDataEstablishment from '@/services/establishmentService'
import placeTypes from '@/domain/placeTypes/placesClass'
import placeEvents from '@/store/storePlaceEvents'
import router from '@/ui/router'

const state = Vue.observable({
    placeData: null,
    nearbyData: [],
    nearbyName: '',
    noData: false,
    nearbyMapMarkers: false,
    coordinatesServicesMap: false,
})

export default {
    // make state a getter here to provide access to the private state variable
    // only allow getting so that it cannot be modified without calling a mutation method
    get state() {
        return state
    },

    // mutations
    async setPlaceData(id, notGo = false) {
        state.placeData = null
        placeEvents.state.eventsData = false
        const response = await placeDataService.getPlaceData(id)
        let placeType = response.type.view.toLowerCase()
        if (!response) {
            router.push({
                name: 'Error404',
            })
        } else if (router.currentRoute.name != placeType && !notGo) {
            router.push({
                name: placeType,
                params: { id: router.currentRoute.params.id },
            })
        } else {
            state.placeData = response
        }
    },
    async setNearbyPlaces(data, userNearby = false) {
        try {
            state.nearbyName = data[2]
        } catch (e) {}
        const responsePlace = await placeDataService.getNearbyPlaces(
            data,
            userNearby,
        )
        const responseEstablishment =
            await placeDataEstablishment.getNearbyEstablishments(data)
        if (responsePlace == 'history') {
            //no cambia la variable nearbyData
        } else if (!responsePlace && !responseEstablishment) {
            state.nearbyData = []
            state.noData = true
        } else if (responsePlace && responseEstablishment) {
            state.nearbyData = responsePlace.concat(responseEstablishment)
            state.noData = false
        } else if (!responsePlace && responseEstablishment) {
            state.nearbyData = responseEstablishment
            state.noData = false
        } else if (responsePlace && !responseEstablishment) {
            state.nearbyData = responsePlace
            state.noData = false
        }
    },
    //actions
}
