import FormularioLogin from './loginForm/formLogin.vue'
import InstantiatedUser from '../../../domain/user/UserClass.js'
import UserRepository from '../../../infrastructure/userRepository/UserRepository.js'
export default {
    name: 'loginView',
    components: { FormularioLogin },
    data() {
        return {
            UserModel: InstantiatedUser.GetModel,
        }
    },
    methods: {
        login() {
            const login = UserRepository.GetUserLogin(
                this.UserModel.username,
                this.UserModel.password,
            )
            if (login.auth) {
                this.UserModel = login.User
            }
        },
    },
}
