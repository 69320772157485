import EventService from '../../../services/eventService'
import Consumptions from './Consumptions/consumptions.vue'
import Tickets from './Tickets/tickets.vue'
import Offers from './Offers/offers.vue'

export default {
    components: { Consumptions, Tickets, Offers },
    name: 'EventView',
    props: ['infoEvent'],
    data() {
        return {
            events: [],

            isMobile: false,
        }
    },
    methods: {
        async EventService() {
            const response = await EventService.getEventService()
            this.events = response.data
        },
    },

    created() {
        this.isMobile = this.$vuetify.breakpoint.mobile
        this.EventService()
    },
}
