var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{staticClass:"pa-0 white",class:_vm.isMobile ? 'cardMobile' : 'cardDesktop'},[_c('v-container',[_c('v-row',{},[_c('v-col',{staticClass:"border-fields"},[_c('v-col',{staticClass:"py-0 mt-1 mt-md-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nombre de la entrada","placeholder":"Nombre de la entrada","hint":"Se requiere el nombre","persistent-hint":"","filled":"","dense":"","rules":_vm.nameRules,"required":"","color":"purple","outlined":""}})],1),_c('v-col',{staticClass:"py-0"},[_c('v-autocomplete',{ref:"tipo",staticClass:"ma-0",attrs:{"rules":[
                  () =>
                    !!_vm.tipo || 'Este Campo Es Requerido',
                ],"items":_vm.tipo,"label":"Tipo","placeholder":"Select...","required":"","hint":"Se requiere el tipo","persistent-hint":"","chips":""}})],1),_c('v-col',{staticClass:"py-0"},[_c('v-autocomplete',{ref:"Icono",staticClass:"ma-0",attrs:{"rules":[
                  () =>
                    !!_vm.Icono ||
                    'Este Campo Es Requerido',
                ],"items":_vm.Icono,"label":"Icono","placeholder":"Select...","required":"","hint":"Se requiere el Icono","persistent-hint":"","chips":""}})],1)],1),_c('v-container',[_c('v-row',{staticClass:"mx-3"},[_c('v-col',{staticClass:"py-0 mt-1 mt-md-0 border-fields",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Precio","rules":_vm.nameRules,"placeholder":"Precio","prepend-inner-icon":"fas fa-euro-sign","hint":"Se requiere el precio","persistent-hint":"","filled":"","dense":"","required":"","outlined":""}})],1)],1),_c('v-row',{staticClass:"mx-3"},[_c('v-col',{staticClass:"py-0 mt-1 mt-md-0 border-fields",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Campo Nuevo","placeholder":"Campo Nuevo","prepend-inner-icon":"fas fa-euro-sign","hint":"Se requiere el campo","persistent-hint":"","filled":"","dense":"","required":"","outlined":""}})],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }