import MainButton from '@/ui/components/mainButton'
import Carousel from '@/ui/components/carousel'
import CategoryList from '@/ui/components/categoryList'
import otherPlaceStore from '@/store/place'

export default {
    name: 'otherPlace',

    components: {
        MainButton,
        Carousel,
        CategoryList,
    },

    data() {
        return {
            descriptionLength: 250,
            showFullDescription: false,
            loading: false,
        }
    },
    filters: {
        upper: function (value) {
            return value.trim().replace(/^\w/, (c) => c.toUpperCase())
        },
    },
    methods: {
        goToServices() {
            if (otherPlaceStore.state.nearbyMapMarkers){
                otherPlaceStore.state.coordinatesServicesMap = false
                otherPlaceStore.state.nearbyMapMarkers = false
            }
            this.$router.push({
                name: 'otherPlaceServices',
                params: { id: this.getId(), nearbyInfo: this.getNearbyData() },
            })
        },
        goToEvents() {
            this.$router.push({
                name: 'placeEvents',
                params: {
                    id: this.getId(),
                    queryId: this.otherPlaceData._id
                },
            })
        },
        getNearbyData() {
            let distance = 0
            if (this.otherPlaceData.options[0] == undefined)
                distance = parseInt(this.otherPlaceData.options.distance)
            else distance = parseInt(this.otherPlaceData.options[0]['value'])
            return new Array(
                distance,
                this.otherPlaceData.id,
                this.otherPlaceData.name,
                this.otherPlaceData.lat,
                this.otherPlaceData.lon,
            )
        },
        getId() {
            return this.$route.params.id
        },
        fullText() {
            this.showFullDescription = !this.showFullDescription
        },
    },
    async created() {
        try {
            this.loading = true
            await otherPlaceStore.setPlaceData(this.getId())
        } catch (error) {}
        this.loading = false
    },
    computed: {
        otherPlaceData() {
            return otherPlaceStore.state.placeData
        },
        coordinates() {
            return new Array(this.otherPlaceData.lat, this.otherPlaceData.lon)
        },
        otherPlaceDescription() {
            if (!this.descriptionExceeds) return this.otherPlaceData.description

            if (this.showFullDescription) return this.otherPlaceData.description
            else
                return (
                    this.otherPlaceData.description.slice(
                        0,
                        this.descriptionLength,
                    ) + '...'
                )
        },

        descriptionExceeds() {
            return this.otherPlaceData.description.length > this.descriptionLength
        },

        descriptionButtonText() {
            return this.showFullDescription ? 'VER MENOS' : 'VER MÁS'
        },
        googleCoordinates() {
            return `https://www.google.com/maps/search/${this.otherPlaceData.lat},${this.otherPlaceData.lon}`
        },
    },
}
