import Bus from '../../../plugins/bus.js'

export default {
    name: 'DrawerButton',

    methods: {
        openDrawer(){
            Bus.$emit('smallme')
        }
    }
}
