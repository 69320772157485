import headerStore from "@/store/header"

export default {
  data() {
      
      return {
        messageNotifys: [{
          id: '1',   
          title:'Ecotasa',       
          message: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit, at tenetur, mollitia quisquam consequuntur deleniti iste ratione obcaecati doloremque quaerat ea modi quidem odio odit aliquid soluta harum ducimus sit? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Soluta, quasi! Illum excepturi repellat atque amet blanditiis, sit in minima veritatis quisquam quaerat. Accusamus laudantium sequi praesentium quas dignissimos corrupti iusto',
        },        
            
        ],
      }
  },
  methods: {
     
  },

  mounted() {
    headerStore.setHeaderText('Govern Balear');
 },
}
