import ImageCard from '@/ui/components/imageCard'
import headerStore from '@/store/header'
import homeStore from '@/store/storeHome'
import ComponentHeader from '@/ui/components/componentHeader'

export default {
    name: 'trends',

    components: {
        ImageCard,
        ComponentHeader,
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        upper(value) {
            return value.trim().replace(/^\w/, (c) => c.toUpperCase())
        },
        goToPlace(obj) {
            let place = obj.type.name.toLowerCase()
            if (place.includes('event')) {
                place = place === 'event' ? 'eventView' : 'eventPlaceView'
                this.$router.push({
                    name: place,
                    params: {
                        eventID: obj.id,
                        slug: obj.slug,
                        id: obj.stablishment
                            ? obj.stablishment.slug
                            : obj.place.slug,
                    },
                })
            } else {
                place = obj.type.view.toLowerCase()
                this.$router.push({
                    name: place,
                    params: { id: obj.slug },
                })
            }
        },
    },
    async created() {
        this.loading = true
        if (!homeStore.state.homeData.events) {
            if (this.$router.referer) this.$router.go(-1)
            else this.$router.push('/')
        } else {
            headerStore.setHeaderText('Tendencias')
            this.loading = false
        }
    },
    computed: {
        trends() {
            return homeStore.state.homeData.events
        },
        noData() {
            if (Object.keys(this.trends).length === 0) {
                return true
            } else return homeStore.state.noData
        },
    },
}
