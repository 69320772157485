import api from '@/services/Api'

class EstablishmentData {
    async getEstablishmentData(id) {
        try {
            const info = await api.get(`/stablishment/slug/${id}`)
            return info.data.data
        } catch (error) {
            if (error.response.data.statusCode == '404') return false
            return null
        }
    }
    async getMenuData(id) {
        try {
            const info = await api.get(`/menus/stablishment/${id}`)
            return info.data.data
        } catch (error) {
            if (error.response.data.statusCode == '404') return false
            return null
        }
    }
    async getNearbyEstablishments(data) {
        try {
            const nearbyData = {
                distance: data[0],
                lat: data[3],
                lon: data[4],
            }

            const nearbyDataStablhisment = await api.post(
                '/stablishment/nearbyStablishments',
                nearbyData,
            )
            return nearbyDataStablhisment.data.data
        } catch (error) {
            if (data == undefined) return 'history'
            else if (error.response.data.statusCode == '400') return false
            return null
        }
    }
    async getAppointment(id){
        const response = await api.get('/services/stablishment/' + id);
        const services = response.data.data;

        return services.find(service => service.type === 'appointment') || null;
    }
}
const establishmentData = new EstablishmentData()
export default establishmentData
