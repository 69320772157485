import Vue from 'vue'

const state = Vue.observable({
    show: false
})

export default {
    get state() {
        return state
    },
    get show() {
        return state.show
    },

    showModal() {
      state.show = true;
    },

    hideModal() {
      state.show = false;
    },
}
