export default {
    data() {
        return {
            isMobile: false,
            paymentmethods: [
                {
                    typeOfCard: 'fab fa-cc-visa',
                    number: 'xxxxxx4561',
                },
                {
                    typeOfCard: 'fab fa-cc-mastercard',
                    number: 'xxxxxx4563',
                },
                {
                    typeOfCard: 'fab fa-cc-visa',
                    number: 'xxxxxx4565',
                },
            ],
        }
    },
    created() {
        this.isMobile = this.$vuetify.breakpoint.mobile
    },
}
