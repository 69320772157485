import Bus from '@/plugins/bus'
import Smallmenu from './smallmenu/smallmenu.vue'

export default {
    components: {
        Smallmenu,
    },
    data: () => ({
        bigmenu: false,
        group: null,
    }),

    async created() {
        await this.$keycloak.loadUserProfile()
        
        Bus.$on('bigme', () => {
            this.bigmenu = !this.bigmenu
        })
    },
    computed: {
        name() {
          if(this.$keycloak.profile){
            return this.$keycloak.profile.firstName+' '+this.$keycloak.profile.lastName
          }
            return 'Nombre'
        },
        email() {
          if(this.$keycloak.profile){
            return this.$keycloak.profile.email
          }
          return ''
        },
    },
}
