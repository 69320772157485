import NightClubService from '../../../../services/nightClubService'

export default {
    name: 'Nightclub',

    drawer: null,
    data() {
        return {
            cardsNightClubs: [],
        }
    },
    methods: {
        async NightClubService() {
            const response = await NightClubService.getNightClubService()
            this.cardsNightClubs = response.data
        },
    },
    created() {
        this.NightClubService()
    },
}
