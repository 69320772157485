import Local from './local/local.vue'
import Event from './evento/event.vue'

export default {
    components: { Local, Event },

    data() {
        return {
            tab: null,
        }
    },
}
